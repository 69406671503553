import i18n from "i18n";
import axios from "axios";
import moment from "moment";
import Swal from "sweetalert2";
import React, { Component } from "react";
import Selector from "ops/common/selector";
import Validator from "ops/common/validator";
import { withRouter } from "react-router-dom";
import { authAxios } from "axios_interceptors";
import LoadingIcon from "ops/common/loading_icon";
import CardTwoButton from "ops/common/card_two_buttons";
import InputTypeSelect from "ops/common/input_type_select";
import { AlertNotification } from "ops/common/alert_notification";
import { Col, Form, FormGroup, Input, Label, Row } from "reactstrap";

class NewRFR extends Component {
	constructor(props) {
		super(props);
		this.state = {
			rfr: "",
			news: [],
			weight: 0,
			assets: [],
			timer: null,
			location: "",
			currentDate: "",
			currentTime: "",
			validate: false,
			selectedAsset: [0],
			hideAssets: [true],
			selectedAsseType: [0],
			assetStatus: "Working",
			showSweetNotify: false,
			loadingIconStatus: false,
			assetStatusChoices: ["Working", "Not Working"],
			selectedAssets: [{ id: 0, code: "Select Asset" }],
			asseTypes: [{ id: 0, name: "Select Asset Type" }],
			issues: [{ note: "", description: "", asset: "" }],
		};
	}

	validate = () => {
		this.setState({ validate: !this.state.validate });
	};

	addIssueButton = () => {
		let issues = this.state.issues,
			asset = "",
			selectedAssetValue = 0,
			selectedAsset = this.state.selectedAsset;
		if (this.state.selectedAssets.length === 2) {
			selectedAssetValue = 1;
			asset = this.state.selectedAssets[1].id;
		}
		issues.push({ note: "", description: "", asset: asset });
		selectedAsset.push(selectedAssetValue);
		this.setState({ issues: issues, selectedAsset: selectedAsset });
	};

	deleteIssueButton = (e) => {
		let issues = this.state.issues,
			selectedAsseType = this.state.selectedAsseType,
			selectedAsset = this.state.selectedAsset;
		issues.splice(e.target.attributes.rowindex.value, 1);
		selectedAsseType.splice(e.target.attributes.rowindex.value, 1);
		selectedAsset.splice(e.target.attributes.rowindex.value, 1);
		this.setState({ issues: issues, selectedAsseType: selectedAsseType });
	};

	addAssetFunction = (e) => {
		let assetIndex = e.target.attributes.index.value,
			assets = this.state.assets,
			issues = this.state.issues,
			selectedAsset = this.state.selectedAsset,
			selectedAssets = this.state.selectedAssets,
			asset = assets[assetIndex];
		asset.index = assetIndex;
		assets.splice(assetIndex, 1);
		selectedAssets.push(asset);
		if (selectedAssets.length === 2) {
			selectedAsset[0] = 1;
			issues[0].asset = selectedAssets[1].id;
		}
		this.setState({ assets: assets, selectedAssets: selectedAssets, selectedAsset: selectedAsset, issues: issues });
	};

	addAssetButton = (e) => {
		let assets = this.state.assets,
			names = ["Truck", "Trailer"],
			index = e.target.attributes.index.value,
			length = this.state.selectedAssets.length,
			selectedAssets = this.state.selectedAssets;
		if (length === 1) {
			this.addAssetFunction(e);
		} else if (length === 2 && names.includes(selectedAssets[1].assetType.name)) {
			if (assets[index].assetType.name !== selectedAssets[1].assetType.name && names.includes(assets[index].assetType.name)) {
				this.addAssetFunction(e);
			} else AlertNotification("You can select only one truck and one trailer for each RFR", "error");
		} else AlertNotification("You can not add more assets", "error");
	};

	removeAssetButton = (e) => {
		let assetIndex = e.target.attributes.index.value,
			assets = this.props.assets,
			newIssues = [],
			newSelectedAsset = [],
			selectedAssets = this.state.selectedAssets,
			asset = selectedAssets[assetIndex];

		selectedAssets.splice(assetIndex, 1);

		this.state.issues.forEach((issue, ind) => {
			if (issue.asset !== asset.id && issue.asset) {
				newIssues.push(issue);
				newSelectedAsset.push(selectedAssets.length - 1);
			}
		});

		if (newIssues.length === 0) {
			newIssues.push({ note: "", description: "", asset: "" });
			newSelectedAsset = [0];
		}

		assets.splice(asset.index, 0, asset);
		this.setState({
			assets: assets,
			selectedAssets: selectedAssets,
			selectedAsset: newSelectedAsset,
			issues: newIssues,
		});
	};

	getAssets = (e) => {
		this.setState({ assets: [] });
		let selectInputIndex = 0,
			hideAssets = this.state.hideAssets,
			selectedAssetValue = 0,
			selectedAsset = this.state.selectedAsset;
		if (this.state.selectedAssets.length >= 2) selectedAssetValue = 1;
		selectedAsset[selectInputIndex] = selectedAssetValue;
		hideAssets[0] = false;
		let assets = this.props.assets.map((asset) => {
			asset.originalGaugeReading = asset.lastGaugeReading;
			return asset;
		});
		this.setState({
			assets: assets,
			hideAssets: hideAssets,
			selectedAsset: selectedAsset,
		});
	};

	getAsset = (e) => {
		let issues = this.state.issues,
			assetIndex = e.target.value,
			selectInputIndex = e.target.attributes.index.value,
			selectedAsset = this.state.selectedAsset;
		issues[selectInputIndex].asset = this.state.selectedAssets[assetIndex].id;
		selectedAsset[selectInputIndex] = e.target.value;
		this.setState({ issues: issues, selectedAsset: selectedAsset });
	};

	getIssueDescription = (e) => {
		let issues = this.state.issues,
			selectInputIndex = e.target.attributes.rowIndex.value;
		issues[selectInputIndex].description = e.target.value;
		this.setState({ issues: issues });
	};

	getIssueNote = (e) => {
		let issues = this.state.issues,
			selectInputIndex = e.target.attributes.rowIndex.value;
		issues[selectInputIndex].note = e.target.value;
		this.setState({ issues: issues });
	};

	getLastRead = (e) => {
		let index = e.target.attributes.index.value,
			selectedAssets = this.state.selectedAssets,
			asset = selectedAssets[index];
		let oldValue = parseInt(asset.lastGaugeReading);
		asset.oldLastGaugeReading = oldValue;
		let newValue = e.target.value;
		asset.lastGaugeReading = newValue;
		asset.invalidLastGaugeReading = false;
		this.setState({ selectedAssets: selectedAssets });
	};

	validateLastRead = (e) => {
		let index = e.target.attributes.index.value,
			selectedAssets = this.state.selectedAssets,
			asset = selectedAssets[index];

		let lastRead = parseInt(asset.lastGaugeReading);
		let oldLastRead = asset.oldLastGaugeReading;
		if (lastRead >= asset.originalGaugeReading) {
			asset.invalidLastGaugeReading = false;
		} else {
			asset.lastGaugeReading = oldLastRead;
			asset.invalidLastGaugeReading = true;
			AlertNotification(i18n.t("invalidLastGaugeRead"), "error");
		}
		this.setState({ selectedAssets: selectedAssets });
	};

	showAlert = () => {
		Swal.fire({
			icon: "success",
			buttonsStyling: false,
			showCancelButton: false,
			allowOutsideClick: false,
			allowEscapeKey: false,
			allowEnterKey: false,
			cancelButtonText: i18n.t("no"),
			title: `${i18n.t("rfrNumber")}:\n ${this.state.rfr.code}`,
			confirmButtonText: i18n.t("ok"),
			customClass: {
				confirmButton: "btn btn-dark",
			},
		}).then((result) => {
			if (result.isConfirmed) {
				this.props.cancelRFR();
			}
		});
	};

	getServerTime = () => {
		authAxios.get("time").then((res) => {
			this.setState({
				currentTime: moment(res.data, "YYYY-MM-DD hh:mm a").format("hh:mm a"),
				currentDate: moment(res.data, "YYYY-MM-DD hh:mm a").format("DD-MM-YYYY"),
			});
		});
	};

	setServerTimeGetterTimer = () => {
		let timer = setInterval(() => {
			this.getServerTime();
		}, 60000);
		this.setState({ timer: timer });
	};

	sendEmailOnNewRFRAPI = (rfrID) => {
		axios
			.get(`rfr/` + rfrID + `/sendEmailForNewRFR`)
			.then()
			.catch((err) => {
				AlertNotification("Could not send email.", "error");
				console.error(err.response);
			});
	};

	getRFRValues = (e, v) => {
		let target, value;
		if (v) {
			target = e.target.id.split("-")[0];
			value = v;
		} else {
			target = e.target.name;
			value = e.target.value;
		}
		this.setState({ [target]: value });
	};

	getRFRLocation = (e) => {
		this.setState({ location: e.target.value });
	};

	CreateRFR = async () => {
		this.setState({ loadingIconStatus: true });
		let lastGaugeReadings = [];
		let selectedAssets = this.state.selectedAssets.map((a) => {
			lastGaugeReadings.push(a.lastGaugeReading);
			return a.id;
		});

		let data = {
			code: "RFR",
			issues: this.state.issues,
			weight: this.state.weight,
			workOrder: this.props.wo.id,
			location: this.state.location,
			assetStatus: this.state.assetStatus,
			asset: selectedAssets.slice(1, selectedAssets.length),
			lastGaugeReadings: lastGaugeReadings.slice(1, lastGaugeReadings.length),
			instance: this.props.wo.instance.id,
		};

		await axios
			.post(`rfr/?serializer=`, data)
			.then((res) => {
				if (res) {
					this.setState({ loadingIconStatus: false });
					this.setState({ rfr: res.data }, () => {
						this.showAlert();
						this.props.getWO(this.props.wo.id);
					});
				}
			})
			.catch((err) => {
				this.setState({ loadingIconStatus: false });
				if (err)
					AlertNotification("Something went wrong, the RFR was not created. Please contact the technical support team.", "error");
			});
	};

	componentDidMount() {
		this.getAssets();
		this.getServerTime();
		this.setServerTimeGetterTimer();
	}

	componentWillUnmount() {
		clearInterval(this.state.timer);
	}

	render() {
		const t = i18n.t;
		return (
			<Row>
				<LoadingIcon type="ball-triangle-path" status={this.state.loadingIconStatus}>
					<Col xs="12" sm="12" md="12" lg="12" xl="12">
						<CardTwoButton
							color2="info"
							color1="dark "
							title2={t("submit")}
							title1={t("addIssue")}
							hideheader={true}
							perm2="mntc.add_rfr"
							perm1="mntc.add_rfr"
							function2={this.validate}
							function1={this.addIssueButton}
							title={t("newRequestForRepair")}
							disablebtn2={this.state.selectedAssets.length > 1 ? false : true}>
							<Row>
								<Col xs="12" sm="12" md="12" lg="12" xl="12">
									<div id="time" className="mb-2 mr-2 badge badge-dark ">
										{i18n.language === "en"
											? `${t("time")}: ${this.state.currentTime}`
											: `${this.state.currentTime} :${t("time")}`}
									</div>
									<div className="mb-2 mr-2 badge badge-dark ">
										{i18n.language === "en"
											? `${t("date")}: ${this.state.currentDate}`
											: `${this.state.currentDate} :${t("date")}`}
									</div>
								</Col>
								<Validator validate={this.state.validate} submit={this.CreateRFR}>
									<Col xs="12" sm="12" md="12" lg="12" xl="12">
										<Form inline className="my-2">
											<Col className="d-flex">
												<Label className="mr-2">{t("weight")}</Label>
												<Input
													required
													type="number"
													step={0.01}
													value={this.state.weight}
													name="weight"
													onChange={this.getRFRValues}
												/>
											</Col>
											<Col className="d-flex">
												<Label className="mr-2">{t("status")}</Label>
												<Selector
													required
													width="50%"
													options={this.state.assetStatusChoices}
													id="assetStatus"
													onChange={this.getRFRValues}
													value={this.state.assetStatus}
												/>
											</Col>
										</Form>
									</Col>
									<Col xs="12" sm="12" md="4" lg="4" xl="4" className="mb-1" hidden={this.state.hideAssets[0]}>
										<div
											style={{
												width: "auto",
												height: "100px",
												overflowX: "auto",
												overflowY: "auto",
												borderWidth: "1px",
												borderColor: "black",
												borderStyle: "solid",
											}}>
											{this.state.assets.map((a, i) => {
												return (
													<button
														key={i}
														index={i}
														id={`asset${i}`}
														onClick={this.addAssetButton}
														className="mb-2 mr-2 ml-2 mt-2 btn-icon btn-shadow btn-outline-2x btn btn-outline-info">
														{a.code}
													</button>
												);
											})}
										</div>
									</Col>
									<Col
										xs="12"
										sm="12"
										md="2"
										lg="2"
										xl="2"
										className="mb-1"
										hidden={this.state.selectedAssets.length > 1 ? false : true}>
										<div
											style={{
												borderStyle: "solid",
												borderColor: "black",
												borderWidth: "1px",
												height: "100px",
												width: "150px",
											}}>
											{this.state.selectedAssets.map((a, i) => {
												return (
													<button
														key={i}
														index={i}
														hidden={i === 0 ? true : false}
														onClick={this.removeAssetButton}
														className="mb-2 mr-2 ml-2 mt-2 btn-icon btn-shadow btn-outline-2x btn btn-outline-danger">
														{a.code}
													</button>
												);
											})}
										</div>
									</Col>
									<Col xs="12" sm="12" md="3" lg="3" xl="3" hidden={this.state.selectedAssets.length > 1 ? false : true}>
										<FormGroup>
											<Label>
												{this.state.selectedAssets[1]
													? this.state.selectedAssets[1].assetType.name +
													  "-" +
													  this.state.selectedAssets[1].code +
													  " " +
													  t("lastGaugeReading")
													: ""}
											</Label>
											<Input
												index={1}
												type="number"
												step={0.01}
												id="lastGaugeReading"
												name="lastGaugeReading"
												onChange={this.getLastRead}
												onBlurCapture={this.validateLastRead}
												invalid={this.state.selectedAssets[1] ? this.state.selectedAssets[1].invalidLastGaugeReading : false}
												value={this.state.selectedAssets[1] ? this.state.selectedAssets[1].lastGaugeReading : 0}
											/>
										</FormGroup>
									</Col>
									<Col xs="12" sm="12" md="3" lg="3" xl="3" hidden={this.state.selectedAssets.length === 3 ? false : true}>
										<FormGroup>
											<Label>
												{this.state.selectedAssets[2]
													? this.state.selectedAssets[2].assetType.name +
													  "-" +
													  this.state.selectedAssets[2].code +
													  " " +
													  t("lastGaugeReading")
													: ""}
											</Label>
											<Input
												index={2}
												type="number"
												step={0.01}
												id="lastGaugeReading"
												name="lastGaugeReading"
												onChange={this.getLastRead}
												onBlurCapture={this.validateLastRead}
												invalid={this.state.selectedAssets[2] ? this.state.selectedAssets[2].invalidLastGaugeReading : false}
												value={this.state.selectedAssets[2] ? this.state.selectedAssets[2].lastGaugeReading : 0}
											/>
										</FormGroup>
									</Col>{" "}
									<Col xs="12" sm="12" md="12" lg="12" xl="12" className="mb-3 mt-2">
										<FormGroup className="mt-1">
											<Label>{i18n.t("location")}</Label>
											<Input
												id="location"
												type="text"
												name="location"
												className="mr-2"
												placeholder={i18n.t("location")}
												style={{ width: "250px" }}
												onChange={this.getRFRLocation}
											/>
										</FormGroup>
									</Col>
									<Col xs="12" sm="12" md="12" lg="12" xl="12" className="mb-3 mt-2">
										<Form inline>
											<FormGroup className="mt-1">
												<InputTypeSelect
													index={0}
													id="asset"
													row={false}
													classes="mr-1"
													required={true}
													accessname="code"
													onchange={this.getAsset}
													data={this.state.selectedAssets}
													selected={this.state.selectedAsset[0]}
												/>
											</FormGroup>
											<FormGroup className="mt-1">
												<Input
													required
													id="desc"
													type="text"
													rowindex={0}
													className="mr-2"
													name="description"
													style={{ width: "750px" }}
													placeholder={t("issueDescription")}
													onChange={this.getIssueDescription}
													value={this.state.issues[0].description}
												/>
											</FormGroup>
											<FormGroup className="mt-1">
												<Input
													id="note"
													type="text"
													name="note"
													rowindex={0}
													className="mr-2"
													placeholder={t("notes")}
													style={{ width: "250px" }}
													onChange={this.getIssueNote}
													value={this.state.issues[0].note}
												/>
											</FormGroup>
										</Form>
									</Col>
									{this.state.issues.map((isuue, index) => {
										if (index < this.state.issues.length - 1) {
											return (
												<Col xs="12" sm="12" md="12" lg="12" xl="12" key={index + 1} className="mb-2">
													<Form inline>
														<FormGroup className="mt-1">
															<InputTypeSelect
																row={false}
																classes="mr-1"
																required={true}
																index={index + 1}
																accessname="code"
																onchange={this.getAsset}
																data={this.state.selectedAssets}
																selected={this.state.selectedAsset[index + 1]}
															/>
														</FormGroup>
														<FormGroup className="mt-1">
															<Input
																required
																type="text"
																className="mr-2"
																name="description"
																rowindex={index + 1}
																style={{ width: "750px" }}
																placeholder={t("issueDescription")}
																onChange={this.getIssueDescription}
																value={this.state.issues[index + 1].description}
															/>
														</FormGroup>
														<FormGroup className="mt-1">
															<Input
																id="note"
																type="text"
																name="note"
																className="mr-2"
																placeholder={t("notes")}
																rowindex={index + 1}
																style={{ width: "250px" }}
																onChange={this.getIssueNote}
																value={this.state.issues[index + 1].note}
															/>
														</FormGroup>
														<FormGroup className="mt-3">
															<div className="font-icon-wrapper-custom">
																<i rowindex={index + 1} className="pe-7s-trash" onClick={this.deleteIssueButton} />
															</div>
														</FormGroup>
													</Form>
												</Col>
											);
										}
										return <div key={index + 2} hidden={true}></div>;
									})}
								</Validator>
							</Row>
						</CardTwoButton>
					</Col>
				</LoadingIcon>
			</Row>
		);
	}
}

export default withRouter(NewRFR);
