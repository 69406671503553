import i18n from "i18n";
import axios from "axios";
import { odooAxios } from "axios_interceptors";
import moment from "moment";
import LegForm from "./leg_form";
import { Loader } from "react-loaders";
import React, { Fragment } from "react";
import ProjectCard from "./project_card";
import { withRouter } from "react-router";
import Selector from "ops/common/selector";
import LegAccordion from "./leg_accordion";
import ViewIcon from "ops/common/view_icon";
import DeleteIcon from "ops/common/delete_icon";
import DeletePopup from "ops/common/delete_popup";
import BillingAccordion from "./billing_accordion";
import LoadingOverlay from "react-loading-overlay";
import PageTitleAlt2 from "ops/common/PageTitleAlt2";
import GetPermission from "ops/common/get_permission";
import BootstrapTable from "react-bootstrap-table-next";
import { AlertNotification } from "ops/common/alert_notification";
import NonPlannedWOForm from "../../non_planned_wo/components/wo_form";
import TableWithPaginationSearch from "ops/common/table_with_pagination_search";
import { Form, Label, Row, Col, Card, CardBody, Button, CardFooter, CardHeader } from "reactstrap";
import { encryptData } from "ops/common/encryption_service";

class ViewProject extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      wos: [],
      assets: [],
      drivers: [],
      instances: [],
      standards: {},
      project: null,
      validation: {},
      serviceType: [],
      assisstants: [],
      selectedLegs: [],
      attachedAssets: [],
      commodityTypes: [],
      newCommodities: [],
      formLoading: false,
      projectWorkOrders: [],
      hideCommodityCard: true,
      selectedCommodities: [],
      legs: [{ id: 0, name: "" }],
      uoms: [{ id: 0, fullName: "" }],
      commodities: [{ id: 0, name: "" }],
    };
  }

  getCommodities = (e, v) => {
    if (v) {
      axios.get(`commodity/?commodityType_id__name=${v.name}`).then((res) => {
        this.setState({ commodities: res.data });
      });
    } else this.setState({ commodities: [] });
  };

  getCommodityTypes = () => {
    let commodityTypes = [];
    commodityTypes =
      this.state.project.commodity.length !== 0 &&
      this.state.project.commodity.map((commodity) => {
        return commodity.commodityType;
      });
    axios.get(`commoditytype/?id__in=${commodityTypes}`).then((res) => {
      this.setState({ commodityTypes: res.data });
    });
  };

  getSelectedCommodities = (e, v) => {
    if (v) {
      let updatedValidation = { ...this.state.validation };
      let targetID = e.target.id.split("-")[0];
      updatedValidation[`invalid${targetID.charAt(0).toUpperCase()}${targetID.slice(1, targetID.length)}`] = false;
      this.setState({
        validation: updatedValidation,
        selectedCommodities: this.state.selectedCommodities.concat(v),
        newCommodities: v,
      });
    }
  };

  addLeg = () => {
    this.setState({
      selectedLegs: this.state.selectedLegs.concat({
        project: this.state.project.id,
      }),
    });
  };

  removeLeg = (e) => {
    let projectLegs = [...this.state.selectedLegs];
    projectLegs.splice(e.target.getAttribute("index"), 1);
    this.setState({ selectedLegs: projectLegs });
  };

  getLegValues = (e) => {
    let value = e.target.value;
    if (e.target.id === "isDead" || e.target.id === "isStart" || e.target.id === "isEnd") value = e.target.checked;
    let projectLegs = this.state.selectedLegs;
    projectLegs[e.target.getAttribute("index")][e.target.id] = value;
    this.setState({ selectedLegs: projectLegs });
  };

  getLegSelectedValues = (e, v, index) => {
    if (v) {
      let targetID = e.target.id.split("-")[0];
      let projectLegs = this.state.selectedLegs;
      projectLegs[index][targetID] = v.id;
      projectLegs[index][`${targetID}Object`] = v;
      this.setState({ selectedLegs: projectLegs });
    }
  };

  showHideCommodityCard = () => {
    this.setState({ hideCommodityCard: !this.state.hideCommodityCard });
  };

  validate = (e) => {
    let updatedValidation = this.state.validation;
    let targetID = e.target.id.split("-")[0];
    updatedValidation[`invalid${targetID.charAt(0).toUpperCase()}${targetID.slice(1, targetID.length)}`] = true;
    this.setState({ validation: updatedValidation });
  };

  updateProjectCommodities = () => {
    let commoditiesIDs = this.state.selectedCommodities.map((c) => {
      return c.id;
    });
    axios
      .patch(`project/${this.state.project.id}/`, { commodity: commoditiesIDs })
      .then((res) => {
        if (res) {
          let updatedProject = this.state.project;
          updatedProject["commodity"] = this.state.selectedCommodities;
          this.setState({ hideCommodityCard: true, project: updatedProject });
          AlertNotification("Added Successfully", "success");
        }
      })
      .catch((err) => {
        if (err.response) {
          AlertNotification(err.response, "error", err.response.status);
          console.error(err.response);
        } else AlertNotification(null, "error");
      });
  };

  updateProjectLegs = () => {
    let pass = true;
    for (let leg of this.state.selectedLegs) {
      if (!leg.leg || !leg.standard) pass = false;
    }

    if (!pass) return;
    axios
      .post(`projectleg/bulkcreate/`, this.state.selectedLegs)
      .then((res) => {
        if (res) this.getProject();
      })
      .catch((err) => {
        if (err.response) {
          AlertNotification(err.response, "error", err.response.status);
          console.error(err.response);
        } else AlertNotification(null, "error");
      });
  };

  getWOInitialData = () => {
    axios
      .get(
        `profile/?serializer=driver&user__is_active=true&license__driverStatus=Available&departments__name__in=${[
          "Drivers",
        ]}`
      )
      .then((res) => {
        this.setState({ drivers: res.data });
      });
    axios
      .get(
        `profile/?serializer=driver&user__is_active=true&license__driverStatus=Available&departments__name__in=${[
          "Driver Assistants",
        ]}`
      )
      .then((res) => {
        this.setState({ assisstants: res.data });
      });

    this.getAssets();

    axios.get(`servicetype`).then((res) => {
      this.setState({ serviceType: res.data });
    });
  };

  getAssets = () => {
    let commodityTypes = [];
    commodityTypes =
      this.state.project.commodity.length !== 0 &&
      this.state.project.commodity.map((commodity) => {
        return commodity.commodityType;
      });
    if (commodityTypes.length === 0) {
      AlertNotification(i18n.t("noCommodityOnProject"), "warning");
      return;
    }
    axios.get(`asset/getAvailableAssets/?commodityTypes=${commodityTypes}`).then((res) => {
      this.setState({
        assets: res.data["assets"],
        attachedAssets: res.data["attachedAssets"],
      });
    });
  };

  addWO = () => {
    if (this.state.wos.length === 0) {
      this.getWOInitialData();
    }
    this.setState({
      wos: this.state.wos.concat({
        project: this.state.project.id,
        instance:this.state.project.instance.id,
        trips: [],
        startDate: moment().format("YYYY-MM-DDTHH:mm"),
      }),
    });
  };

  removeWO = (e) => {
    let projectWOs = [...this.state.wos];
    projectWOs.splice(e.target.getAttribute("index"), 1);
    this.setState({ wos: projectWOs });
  };

  // used to send any required data to odoo by passing the url and the data
  sendDataToOdoo = (odooURL, odooData) => {
		odooAxios
		  .post(odooURL, odooData, {}, {
			  headers: {
				'Content-Type': 'application/json',
			  }
		  })
		  .then((odooResponse) => {
				console.log("Data successfully sent to Odoo:", odooResponse.data.result);
		  })
		  .catch((odooError) => {
				console.error("Failed to send data to Odoo:", odooError);
		  });
	};

  submitProjectWOs = (e) => {
    e.preventDefault();
    let wos = [...this.state.wos];
    this.setState({ formLoading: true });
    axios
      .post(`workorder/`, wos)
      .then((res) => {
        // update project status in odoo
        if(this.state.project.status === "Did Not Start") {
          axios
            .get(`project/?serializer=odooUpdate&id=${this.state.project.id}`)
            .then(async (result) => {
              const project = result.data[0];
              const odooData = {
                projectCode: project.code,
                projectStatus: project.status,
              }
              const { encryptedData, authTag, iv } = await encryptData(odooData);
              const encryptedObject = {
                encrypted_data: encryptedData,
                tag: authTag,
                iv,
              }
              this.sendDataToOdoo(`update_project`, encryptedObject);
            })
        }
        this.setState({ formLoading: false });
        if (res) {
          this.setState({ wos: [] });
          AlertNotification(i18n.t("submit"), "success");
          this.getProjectWOs();
        }
      })
      .catch((err) => {
        this.setState({ formLoading: false });
        if (err.response) {
          AlertNotification(null, "error", err.response.status);
          console.error(err.response);
        } else AlertNotification(null, "error");
      });
  };

  getWOValues = (e, v, woIndex) => {
    let value;
    let target;
    let wos = this.state.wos;
    let index;
    if (v) {
      index = woIndex;
      target = e.target.id.split("-")[0];
      value = v.id;
      if (target === "driver" || target === "assisstant") value = v.user;
      wos[index][target] = value;
      wos[index][`${target}Object`] = v;
      wos[index][`invalid${target.charAt(0).toUpperCase()}${target.slice(1, target.length)}`] = false;
      if (target === "asset") wos[index]["startKM"] = v.lastGaugeReading;
      this.setState({ wos: wos });
    } else {
      index = e.target.getAttribute("index");
      target = e.target.id;
      value = e.target.value;
      wos[index][target] = value;
      wos[index][`invalid${target.charAt(0).toUpperCase()}${target.slice(1, target.length)}`] = false;

      //////////////////////////////////////////////////////////////////////
      // Work order start KM validation commented until required
      // if (target === "startKM" && wos[index]["assetObject"] && value < wos[index]["assetObject"].lastGaugeReading)
      // 	wos[index][`invalid${target.charAt(0).toUpperCase()}${target.slice(1, target.length)}`] = true;
      if (target === "endKM" && wos[index]["startKM"] && value < wos[index]["startKM"])
        wos[index][`invalid${target.charAt(0).toUpperCase()}${target.slice(1, target.length)}`] = true;

      this.setState({ wos: wos });
    }
  };

  validateWOs = (e) => {
    let targetID = e.target.id.split("-")[0];
    let index = e.target.getAttribute("name");
    let wos = this.state.wos;
    wos[index][`invalid${targetID.charAt(0).toUpperCase()}${targetID.slice(1, targetID.length)}`] = true;
    this.setState({ wos: wos });
  };

  getProjectWOs = () => {
    let projectID = this.props.history.location.projectID;
    if (!projectID) projectID = new URLSearchParams(this.props.location.search).get("id");
    this.setState({ loading: true });
    axios.get(`workorder/?serializer=list&project=${projectID}`).then((res) => {
      this.setState({
        projectWorkOrders: res.data,
        loading: false,
      });
    });
  };

  getProject = () => {
    let projectID = this.props.history.location.projectID;
    if (!projectID) projectID = new URLSearchParams(this.props.location.search).get("id");
    axios.get(`project/${projectID}/?serializer=get`).then((res) => {
      this.setState(
        {
          project: res.data,
          selectedCommodities: res.data.commodity,
          selectedLegs: [],
        },
        () => {
          this.getCommodityTypes();
        }
      );
      if (!res.data.plan) {
        this.getProjectWOs();
      }
    });
  };

  changeField = (field) => {
    let project = { ...this.state.project };
    project[`change${field.charAt(0).toUpperCase()}${field.slice(1, field.length)}`] = true;
    project[`old${field.charAt(0).toUpperCase()}${field.slice(1, field.length)}`] = project[field];
    project[`invalid${field.charAt(0).toUpperCase()}${field.slice(1, field.length)}`] = false;
    this.setState({ project: project });
  };

  cancelChange = (field) => {
    let project = { ...this.state.project };
    project[`change${field.charAt(0).toUpperCase()}${field.slice(1, field.length)}`] = false;
    project[field] = project[`old${field.charAt(0).toUpperCase()}${field.slice(1, field.length)}`];
    this.setState({ project: project });
  };

  getUpdatedValue = (e) => {
    let target = e.target.id,
      value = e.target.value,
      project = { ...this.state.project };
    project[target] = value;
    project[`invalid${target.charAt(0).toUpperCase()}${target.slice(1, target.length)}`] = false;
    this.setState({ project: project });
  };

  updateProjectField = (field) => {
    let project = { ...this.state.project };
    if (!project[field]) {
      project[`invalid${field.charAt(0).toUpperCase()}${field.slice(1, field.length)}`] = true;
      this.setState({ project: project });
      AlertNotification(i18n.t("noValue"), "error");
      return;
    }
    this.setState({ formLoading: true });
    axios
      .patch(`project/${project.id}/?serializer=update`, {
        [field]: project[field],
      })
      .then((res) => {
        project[`change${field.charAt(0).toUpperCase()}${field.slice(1, field.length)}`] = false;
        this.setState({ formLoading: false, project: project });
        AlertNotification(i18n.t("updated"), "success");
      })
      .catch((err) => {
        this.setState({ formLoading: false });
        console.error(err.response);
        AlertNotification(i18n.t("errorMessage"), "error");
      });
  };

  getInitialData = () => {
    this.getProject();
    axios.get(`profile/userinstances`).then((res) => {
			this.setState({ instances: res.data });
		})
    axios.get(`leg`).then((res) => {
      this.setState({ legs: this.state.legs.concat(res.data) });
    });
    axios.get(`uom/?serializer=`).then((res) => {
      this.setState({ uoms: this.state.uoms.concat(res.data) });
    });
  };

  getStandards = (e, v, index) => {
    let standards = { ...this.state.standards };
    axios.get(`standard/?serializer=get&isArchive=false&leg=${v.id}`).then((res) => {
      standards[index] = res.data;
      this.setState({ standards: standards });
    });
  };

  closeProject = () => {
    axios
      .put(`project/closeProject/`, { id: this.state.project.id })
      .then((res) => {
        if (res) {
          axios
            .get(`project/?serializer=odooUpdate&id=${this.state.project.id}`)
            .then(async (result) => {
              const project = result.data[0];
              const odooData = {
                projectCode: project.code,
                projectStatus: project.status,
                endDate: project.endDate
              }
              const { encryptedData, authTag, iv } = await encryptData(odooData);
              const encryptedObject = {
                encrypted_data: encryptedData,
                tag: authTag,
                iv,
              }
              this.sendDataToOdoo(`update_project`, encryptedObject);
            })
          this.getProject();
          AlertNotification(i18n.t("closed"), "success");
        }
      })
      .catch((err) => {
        console.error(err.response);
        AlertNotification(i18n.t("errorMessage"), "error");
      });
  };

  confirmDeleteWorkOrder = (workorder) => {
    return DeletePopup({
      title: "Are you sure ?",
      cancelbtntxt: "No",
      confirmbtntxt: "Yes",
      text: `This will delete Work Order ${workorder.code}`,
      confirmedresult: () => this.deleteWorkOrder(workorder),
    });
  };

  deleteWorkOrder = (wo) => {
    this.setState({ loading: true });
    axios
      .delete(`workorder/${wo.id}`)
      .then(async (res) => {
        if (res) {
          const odooData = {
						woCode: wo.code
					}
          const { encryptedData, authTag, iv } = await encryptData(odooData);
          const encryptedObject = {
            encrypted_data: encryptedData,
            tag: authTag,
            iv,
          }
					this.sendDataToOdoo(`delete_wo_trips`, encryptedObject);
          AlertNotification(i18n.t("deleted"), "success");
          let workorders = [...this.state.projectWorkOrders].filter((workorder) => workorder.id !== wo.id);
          this.setState({ projectWorkOrders: workorders }, () => {
            this.setState({ loading: false });
          });
        }
      })
      .catch((err) => {
        console.error(err.response);
        this.setState({ loading: false });
        AlertNotification(i18n.t("errorMessage"), "error");
      });
  };

  componentDidMount() {
    this.getInitialData();
  }

  render() {
    const t = i18n.t;
    const headerFormatter = (column) => <div className="info-header">{column.text}</div>;
    const woColumns = [
      {
        sort: true,
        align: "center",
        headerAlign: "center",
        dataField: "code",
        text: t("code"),
      },
      {
        sort: true,
        align: "center",
        dataField: "status",
        headerAlign: "center",
        text: t("status"),
      },
      {
        sort: true,
        align: "center",
        headerAlign: "center",
        dataField: "startDate",
        text: t("startDate"),
      },
      {
        sort: true,
        align: "center",
        headerAlign: "center",
        dataField: "endDate",
        text: t("endDate"),
      },
      {
        sort: true,
        align: "center",
        headerAlign: "center",
        dataField: "startKM",
        text: t("startKM"),
        formatter: (cell) => Number(cell).toLocaleString(),
      },
      {
        sort: true,
        align: "center",
        headerAlign: "center",
        dataField: "endKM",
        text: t("endKM"),
        formatter: (cell) => {
          if (cell) return Number(cell).toLocaleString();
        },
      },
      {
        sort: true,
        hidden: true,
        align: "center",
        dataField: "serviceType",
        headerAlign: "center",
        text: t("serviceType"),
      },
      {
        sort: true,
        align: "center",
        headerAlign: "center",
        dataField: "driver",
        text: t("driver"),
      },
      {
        sort: true,
        hidden: true,
        align: "center",
        headerAlign: "center",
        dataField: "assisstant",
        text: t("assisstant"),
      },
      {
        sort: true,
        align: "center",
        headerAlign: "center",
        dataField: "asset.code",
        text: t("asset"),
      },
      {
        sort: true,
        align: "center",
        headerAlign: "center",
        dataField: "attachedAsset.code",
        text: t("attachedAsset"),
      },
      {
        sort: true,
        align: "center",
        dataField: "tripsCount",
        headerAlign: "center",
        text: t("tripsCount"),
        formatter: (cell, row) => {
          return row.trips.length;
        },
      },
      {
        dataField: "actions",
        align: "center",
        headerAlign: "center",
        text: t("actions"),
        formatter: (cell, wo) => {
          return (
            <>
              <GetPermission perm="ops.view_workorder">
                <ViewIcon code="perm" target="_blank" to={`/viewworkorder/?id=${wo.id}`} />
              </GetPermission>
              <GetPermission perm="ops.delete_workorder">
                <div code="perm">
                  <DeleteIcon ondelete={() => this.confirmDeleteWorkOrder(wo)} hidden={!wo.deletable} />
                </div>
              </GetPermission>
            </>
          );
        },
      },
    ];
    const tripColumns = [
      {
        sort: true,
        editable: false,
        align: "center",
        dataField: "number",
        headerAlign: "center",
        text: i18n.t("number"),
        headerFormatter: headerFormatter,
      },
      {
        sort: true,
        align: "center",
        dataField: "startKM",
        headerAlign: "center",
        text: i18n.t("startKM"),
        headerFormatter: headerFormatter,
        formatter: (cell) => Number(cell).toLocaleString(),
      },
      {
        sort: true,
        align: "center",
        dataField: "endKM",
        headerAlign: "center",
        text: i18n.t("endKM"),
        headerFormatter: headerFormatter,
        formatter: (cell) => Number(cell).toLocaleString(),
      },
      {
        sort: true,
        align: "center",
        headerAlign: "center",
        dataField: "distance",
        text: i18n.t("distance"),
        formatter: (cell, trip, index) => {
          let distance = trip["endKM"] - trip["startKM"];
          return Number(distance).toLocaleString();
        },
        style: (cell, trip) => {
          let distance = trip["endKM"] - trip["startKM"];
          if (!(distance - trip["tripDistance"] >= -10 && distance - trip["tripDistance"] <= 10)) {
            return { color: "red" };
          }
        },
        headerFormatter: headerFormatter,
      },
      {
        sort: true,
        align: "center",
        headerAlign: "center",
        dataField: "loadedQTY",
        text: i18n.t("loadedQTY"),
        headerFormatter: headerFormatter,
        formatter: (cell) => Number(cell).toLocaleString(),
      },
      {
        sort: true,
        align: "center",
        headerAlign: "center",
        dataField: "deliveredQTY",
        text: i18n.t("deliveredQTY"),
        headerFormatter: headerFormatter,
        formatter: (cell) => Number(cell).toLocaleString(),
      },
      {
        sort: true,
        align: "center",
        headerAlign: "center",
        dataField: "difference",
        text: i18n.t("difference"),
        formatter: (cell, trip, index) => {
          let difference = trip["loadedQTY"] - trip["deliveredQTY"];
          if (isNaN(difference)) {
            return "-";
          }
          return Number(difference).toLocaleString();
        },
        style: (cell, trip) => {
          let difference = trip["loadedQTY"] - trip["deliveredQTY"];
          if (Math.sign(difference) === -1) {
            return { color: "red" };
          }
        },
        headerFormatter: headerFormatter,
      },
      {
        align: "center",
        editable: false,
        headerAlign: "center",
        dataField: "loadingDate",
        text: i18n.t("loadingDate"),
        formatter: (cell) => {
          if (cell) {
            return moment(cell, "YYYY-MM-DDTHH:mm").format("DD-MM-YYYY hh:mm A");
          } else return cell;
        },
        headerFormatter: headerFormatter,
      },
      {
        align: "center",
        editable: false,
        headerAlign: "center",
        dataField: "unLoadingDate",
        text: i18n.t("unLoadingDate"),
        formatter: (cell) => {
          if (cell) {
            return moment(cell, "YYYY-MM-DDTHH:mm").format("DD-MM-YYYY hh:mm A");
          } else return cell;
        },
        headerFormatter: headerFormatter,
      },
      {
        sort: true,
        editable: false,
        align: "center",
        dataField: "leg",
        headerAlign: "center",
        text: i18n.t("leg"),
        formatter: (cell, trip, index) => {
          if (trip.projectLeg) {
            return trip.projectLeg;
          } else {
            return trip.leg;
          }
        },
        headerFormatter: headerFormatter,
      },
      {
        align: "center",
        editable: false,
        dataField: "owner.fullName",
        headerAlign: "center",
        text: i18n.t("owner"),
        headerFormatter: headerFormatter,
      },
    ];
    const expandRow = {
      renderer: (row) => <BootstrapTable keyField="id" condensed={true} data={row.trips} columns={tripColumns} />,
    };
    return (
      this.state.project && (
        <Fragment>
          <PageTitleAlt2 heading={t("viewProject")} icon="bi bi-exclude icon-gradient bg-happy-fisher" />
          <LoadingOverlay
            tag="div"
            styles={{
              overlay: (base) => ({
                ...base,
                background: "#fff",
                opacity: 0.5,
              }),
            }}
            active={this.state.formLoading}
            spinner={<Loader active color="#30b1ff" type="line-spin-fade-loader" />}
          >
            <Row>
              <Col xs="12">
                <ProjectCard
                  instances={this.state.instances}
                  addWO={this.addWO}
                  addLeg={this.addLeg}
                  data={this.state.project}
                  addCommodity={this.showHideCommodityCard}
                  closeProject={this.closeProject}
                  changeField={this.changeField}
                  getValue={this.getUpdatedValue}
                  cancelChange={this.cancelChange}
                  updateProjectField={this.updateProjectField}
                />
              </Col>
              <Col xs="12">
                <Form onInvalid={this.validate}>
                  <Row hidden={this.state.hideCommodityCard}>
                    <Col xs="12" sm="12" md="12" lg="12" xl="12">
                      <Card className="main-card mb-3" key="0">
                        <CardBody>
                          <Row className="d-flex justify-content-start">
                            <Col xs="12" sm="12" md="6" lg="3" xl="3">
                              <Label>{t("commodityType")}</Label>
                              <Selector
                                size="small"
                                isObjectOption
                                id="commodityType"
                                optionAccessor="name"
                                onChange={this.getCommodities}
                                options={this.state.commodityTypes}
                                disabled={this.state.newCommodities.length !== 0 || false}
                              />
                            </Col>
                            <Col xs="12" md="9">
                              <Label>{t("commodity")}</Label>
                              <Selector
                                multiple
                                size="small"
                                id="commodity"
                                isObjectOption
                                optionAccessor="name"
                                options={this.state.commodities}
                                onChange={this.getSelectedCommodities}
                              />
                            </Col>
                          </Row>
                        </CardBody>
                        <CardFooter className="d-flex justify-content-end">
                          <GetPermission perm="ops.view_commodity">
                            <div code="perm">
                              <Button color="dark" className="mr-1" type="button" onClick={this.showHideCommodityCard}>
                                {t("cancel")}
                              </Button>
                            </div>
                          </GetPermission>
                          <GetPermission perm="ops.change_commodity">
                            <div code="perm">
                              <Button color="info" type="button" onClick={this.updateProjectCommodities}>
                                {t("submit")}
                              </Button>
                            </div>
                          </GetPermission>
                        </CardFooter>
                      </Card>
                    </Col>
                  </Row>
                  <Row hidden={this.state.selectedLegs.length === 0}>
                    <Col xs="12">
                      <Card className="main-card mb-3">
                        <CardBody>
                          <div>
                            <div className="dropdown-menu-header m-0">
                              <div className="dropdown-menu-header-inner m-0 rounded bg-primary">
                                <div className="menu-header-content">
                                  <h5 className="menu-header-title">{t("legs")}</h5>
                                </div>
                              </div>
                            </div>
                            <Row className="mt-3">
                              {this.state.selectedLegs.map((leg, index) => {
                                return (
                                  <Col lg="6" key={index}>
                                    <LegForm
                                      index={index}
                                      selectedLeg={leg}
                                      uoms={this.state.uoms}
                                      legs={this.state.legs}
                                      onDelete={this.removeLeg}
                                      getValue={this.getLegValues}
                                      getStandards={this.getStandards}
                                      standards={this.state.standards[index]}
                                      commodities={this.state.project.commodity}
                                      getSelectedValue={this.getLegSelectedValues}
                                    />
                                  </Col>
                                );
                              })}
                              <Col xs="12" className="d-flex justify-content-end">
                                <GetPermission perm="ops.add_projectleg">
                                  <div code="perm">
                                    <Button color="info" onClick={this.addLeg}>
                                      {t("addLeg")}
                                    </Button>
                                  </div>
                                </GetPermission>
                                <GetPermission perm="ops.change_projectleg">
                                  <div code="perm">
                                    <Button
                                      color="info"
                                      className="ml-2"
                                      type="button"
                                      onClick={this.updateProjectLegs}
                                    >
                                      {t("submit")}
                                    </Button>
                                  </div>
                                </GetPermission>
                              </Col>
                            </Row>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </Form>
              </Col>
              <Col xs="12">
                <Form onInvalid={this.validateWOs} onSubmit={this.submitProjectWOs}>
                  <Row hidden={this.state.wos.length === 0}>
                    <Col xs="12">
                      <Card className="main-card mb-3">
                        <CardBody>
                          <div>
                            <div className="dropdown-menu-header m-0">
                              <div className="dropdown-menu-header-inner m-0 rounded bg-primary">
                                <div className="menu-header-content">
                                  <h5 className="menu-header-title">{t("workOrders")}</h5>
                                </div>
                              </div>
                            </div>
                            <Row className="mt-3">
                              {this.state.wos.map((wo, index) => {
                                return (
                                  <Col lg="6" key={index}>
                                    <NonPlannedWOForm
                                      index={index}
                                      selectedWO={wo}
                                      onDelete={this.removeWO}
                                      getValue={this.getWOValues}
                                      drivers={this.state.drivers}
                                      assisstants={this.state.assisstants}
                                      assets={this.state.assets}
                                      attachedAssets={this.state.attachedAssets}
                                      serviceType={this.state.serviceType}
                                    />
                                  </Col>
                                );
                              })}
                              <Col xs="12" className="d-flex justify-content-end">
                                <GetPermission perm="ops.add_workorder">
                                  <div code="perm">
                                    <Button color="info" onClick={this.addWO}>
                                      {t("addWO")}
                                    </Button>
                                    <Button color="info" className="ml-2" type="submit">
                                      {t("submit")}
                                    </Button>
                                  </div>
                                </GetPermission>
                              </Col>
                            </Row>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </Form>
              </Col>
              <Col xs="12">
                <BillingAccordion data={this.state.project.projectBilling} />
              </Col>
              <Col xs="12">
                <LegAccordion data={this.state.project.projectLegs} />
              </Col>
              {!this.state.project.plan && (
                <Col xs="12">
                  <CardHeader className="mb-3 h-auto pl-0 pr-0 bg-transparent no-border">
                    <div className="card-header-title fsize-2 text-capitalize text-dark font-weight-normal">
                      {t("workOrders")}
                    </div>
                  </CardHeader>
                  <Card className="main-card mb-3">
                    <CardBody>
                      <TableWithPaginationSearch
                        scrollable
                        exportable
                        filename="workorders.csv"
                        columns={woColumns}
                        data={this.state.projectWorkOrders}
                        expandRow={expandRow}
                        loading={this.state.loading}
                      />
                    </CardBody>
                  </Card>
                </Col>
              )}
            </Row>
          </LoadingOverlay>
        </Fragment>
      )
    );
  }
}

export default withRouter(ViewProject);
