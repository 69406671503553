import i18n from "i18n";
import axios from "axios";
import { Col, Row, Label } from "reactstrap";
import React, { Component, Fragment } from "react";
import VehicleStats from "../components/vehicle_stats";
import DriverStats from "../components/driver_stats";
import PageTitleAlt2 from "../../common/PageTitleAlt2";
import GetPermission from "../../common/get_permission";
import ProjectsStats from "../components/ProjectsStats";
import Selector from "ops/common/selector";

class HomeScene extends Component {
	constructor() {
		super();
		this.state = {
			truckData: [],
			trailerData: [],
			driverData: [],
			driverStatus: [],
			instances: [],
			selectedInstance: null,
		};
	}

	getData = () => {
		axios.get(`asset/stats?assetType=truck`).then((res) => {
			let newData = res.data.map((asset, index) => {
				asset.id = index;
				return asset;
			});
			this.setState({ truckData: newData });
		});
		axios.get(`asset/stats?assetType=trailer`).then((res) => {
			let newData = res.data.map((asset, index) => {
				asset.id = index;
				return asset;
			});
			this.setState({ trailerData: newData });
		});
		axios.get(`license/stats`).then((res) => {
			let newData = res.data.map((driver, index) => {
				driver.id = index;
				return driver;
			});
			this.setState({ driverData: newData });
		});
		axios.get(`license/driver-status`).then((res) => {
            this.setState({ driverStatus: res.data });
		});
		axios.get(`profile/userinstances`).then((res) => {
			this.setState({instances: res.data});
		})
	};

	filterByInstance = (e, v) => {
		if (v) {
			this.setState({ selectedInstance: v.id });
		}
		else {
			this.setState({ selectedInstance: null });
		}
	};

	componentDidMount() {
		this.getData();
	}

	render() {
		let t = i18n.t;
		return (
			<Fragment>
				<Row>
					<Col xs="12" lg="9" className="d-flex">
						<PageTitleAlt2 
							heading={t("home")} 
							icon="pe-7s-home icon-gradient bg-happy-fisher" 
							className="mb-0" 
						/>
					</Col>
					{
						this.state.instances.length > 1 && (
						<Col xs="12" lg="3">
							<Label>{i18n.t("company")}</Label>
								<Selector
									clearable
									id="instanceFilter"
									size="small"
									isObjectOption
									optionAccessor="name"
									options={this.state.instances}
									onChange={this.filterByInstance}
									className="w-100"
								/>
						</Col>
						)
					}
				</Row>

				<GetPermission perm="users.view_ops_dashboard">
					<ProjectsStats instances={this.state.instances} selectedInstance={this.state.selectedInstance} />
					<Row code="perm">
						<Col xs="12" sm="12" md="12" lg="12" xl="12" className="mt-3">
							<VehicleStats truckstats={this.state.truckData} trailerstats={this.state.trailerData} />
						</Col>
						<Col xs="12" sm="12" md="12" lg="12" xl="12" className="mt-3">
							<DriverStats driver_stats={this.state.driverData} driverStatus={this.state.driverStatus} />
						</Col>
					</Row>
				</GetPermission>
			</Fragment>
		);
	}
}

export default HomeScene;
