/*export async function generateKey() {
    return await crypto.subtle.generateKey({ name: "AES-GCM", length: 256 }, true, ["encrypt", "decrypt"]);
}

export async function exportKey(key) {
    const rawKey = await crypto.subtle.exportKey("raw", key);
    return btoa(String.fromCharCode(...new Uint8Array(rawKey)));
}*/

const STATIC_KEY = "A7GAw2j3bLLu27rgyKOP7MgxlIxW7bjHnHlex0rUbeg="; // Base64-encoded 256-bit key

async function getStaticCryptoKey() {
    const rawKey = Uint8Array.from(atob(STATIC_KEY), (c) => c.charCodeAt(0)); // Decode Base64 key
    return await crypto.subtle.importKey(
        "raw",
        rawKey, // Convert Base64 key to binary
        { name: "AES-GCM" },
        true, // Whether the key can be exported (optional, can be false if not needed)
        ["encrypt", "decrypt"]
    );
}

export async function encryptData(data) {
    const key = await getStaticCryptoKey(); // Use the static key
    const iv = crypto.getRandomValues(new Uint8Array(12)); // Random IV
    const encodedData = new TextEncoder().encode(JSON.stringify(data));
    const encryptedBuffer = await crypto.subtle.encrypt({ name: "AES-GCM", iv }, key, encodedData);

    // Extract authentication tag and ciphertext
    const encryptedBytes = new Uint8Array(encryptedBuffer);
    const ciphertext = encryptedBytes.slice(0, -16); // Ciphertext without tag
    const authTag = encryptedBytes.slice(-16); // Last 16 bytes are the tag
    return {
        encryptedData: btoa(String.fromCharCode(...ciphertext)),
        authTag: btoa(String.fromCharCode(...authTag)),
        iv: btoa(String.fromCharCode(...iv)),
    };
};