import i18n from "i18n";
import React from "react";
import Selector from "ops/common/selector";
import EditIcon from "ops/common/edit_icon";
import UpdateIcon from "ops/common/update_icon";
import { CancelIcon } from "ops/common/cancel_icon";
import GetPermission from "ops/common/get_permission";
import { CardBody, Col, ListGroup, ListGroupItem, Card, Row, CardHeader, CardFooter, Button, Input } from "reactstrap";

/* Displays details of one operations project. */

class ProjectCard extends React.Component {
	render() {
		const t = i18n.t;
		return (
			this.props.data && (
				<Card className="main-card mb-3">
					<CardHeader className="card-header-tab">
						<div className="card-header-title font-size-lg text-capitalize font-weight-normal">
							<i className="header-icon pe-7s-note2 icon-gradient bg-malibu-beach"> </i>
							{t("projectData")}
						</div>
					</CardHeader>
					<Row className="no-gutters">
						<Col xs="12" lg="4" className="pr-5">
							<CardBody className="pt-0 pb-0">
								<ListGroup flush>
									{	
										this.props.instances.length > 1 && 
										<ListGroupItem>
											<div className="widget-content p-0">
												<div className="widget-content-outer">
													<div className="widget-content-wrapper">
														<div className="widget-content-left">
															<div className="widget-heading">{t("company")}</div>
															<div className="widget-numbers text-info">{this.props.data.instance.name}</div>
														</div>
													</div>
												</div>
											</div>
										</ListGroupItem>
									}
									<ListGroupItem>
										<div className="widget-content p-0">
											<div className="widget-content-outer">
												<div className="widget-content-wrapper">
													<div className="widget-content-left">
														<div className="widget-heading">{t("projectCode")}</div>
														<div className="widget-numbers text-info">{this.props.data.code}</div>
													</div>
												</div>
											</div>
										</div>
									</ListGroupItem>
									<ListGroupItem>
										<div className="widget-content p-0">
											<div className="widget-content-outer">
												<div className="widget-content-wrapper">
													<div className="widget-content-left">
														<div className="widget-heading">{t("startDate")}</div>
														<div className="widget-numbers text-info">{this.props.data.startDate.slice(0, 10)}</div>
													</div>
												</div>
											</div>
										</div>
									</ListGroupItem>
									<ListGroupItem className="d-flex justify-content-between">
										<div className="widget-content p-0">
											<div className="widget-content-outer">
												<div className="widget-content-wrapper">
													<div className="widget-content-left">
														<div className="widget-heading">{t("totalQuantity")}</div>
														{this.props.data.changeTotalQTY ? (
															<Input
																type="number"
																step={0.01}
																min={0}
																bsSize="sm"
																id="totalQTY"
																onChange={this.props.getValue}
																invalid={this.props.data.invalidTotalQTY}
																defaultValue={this.props.data.totalQTY}
															/>
														) : (
															<div className="widget-numbers text-info">{Number(this.props.data.totalQTY).toLocaleString()}</div>
														)}
													</div>
												</div>
											</div>
										</div>
										<div className="widget-content p-0">
											<div className="widget-content-outer">
												<div className="widget-content-wrapper">
													<div className="widget-content-right">
														{this.props.data.changeTotalQTY ? (
															<div className="icon-wrapper align-items-start">
																<UpdateIcon onupdate={(e) => this.props.updateProjectField("totalQTY")} />
																<CancelIcon
																	parentClassName="align-items-start"
																	onHoverColor="danger"
																	onCancel={(e) => this.props.cancelChange("totalQTY")}
																/>
															</div>
														) : (
															<GetPermission perm="ops.change_project">
																<div code="perm">
																	<EditIcon
																		hidden={!this.props.data.openEndedQTY}
																		onEdit={() => this.props.changeField("totalQTY")}
																	/>
																</div>
															</GetPermission>
														)}
													</div>
												</div>
											</div>
										</div>
									</ListGroupItem>
									<ListGroupItem>
										<div className="widget-content p-0">
											<div className="widget-content-outer">
												<div className="widget-content-wrapper">
													<div className="widget-content-left">
														<div className="widget-heading">{t("customer")}</div>
														<div className="widget-numbers text-info">{this.props.data.company}</div>
													</div>
												</div>
											</div>
										</div>
									</ListGroupItem>
								</ListGroup>
							</CardBody>
						</Col>
						<Col xs="12" lg="4" className="pr-5">
							<CardBody className="pt-0 pb-0">
								<ListGroup flush>
									<ListGroupItem>
										<div className="widget-content p-0">
											<div className="widget-content-outer">
												<div className="widget-content-wrapper">
													<div className="widget-content-left">
														<div className="widget-heading">{t("projectType")}</div>
														<div className="widget-numbers text-info">{this.props.data.projectType}</div>
													</div>
												</div>
											</div>
										</div>
									</ListGroupItem>
									<ListGroupItem>
										<div className="widget-content p-0">
											<div className="widget-content-outer">
												<div className="widget-content-wrapper">
													<div className="widget-content-left">
														<div className="widget-heading">{t("status")}</div>
														<div className="widget-numbers text-info">{this.props.data.status}</div>
													</div>
												</div>
											</div>
										</div>
									</ListGroupItem>
									<ListGroupItem>
										<div className="widget-content p-0">
											<div className="widget-content-outer">
												<div className="widget-content-wrapper">
													<div className="widget-content-left">
														<div className="widget-heading">{t("targetQuantityPerDay")}</div>
														<div className="widget-numbers text-info">
															{Number(this.props.data.targetQTYDay).toLocaleString()}
														</div>
													</div>
												</div>
											</div>
										</div>
									</ListGroupItem>
									<ListGroupItem>
										<div className="widget-content p-0">
											<div className="widget-content-outer">
												<div className="widget-content-wrapper">
													<div className="widget-content-left">
														<div className="widget-heading">{t("commodity")}</div>
														<Selector
															multiple
															size="small"
															id="commodity"
															isObjectOption
															disabled={true}
															optionAccessor="name"
															options={this.props.data.commodity}
															defaultValue={this.props.data.commodity}
														/>
													</div>
												</div>
											</div>
										</div>
									</ListGroupItem>
								</ListGroup>
							</CardBody>
						</Col>
						<Col xs="12" lg="4" className="pr-5">
							<CardBody className="pt-0 pb-0">
								<ListGroup flush>
									<ListGroupItem>
										<div className="widget-content p-0">
											<div className="widget-content-outer">
												<div className="widget-content-wrapper">
													<div className="widget-content-left">
														<div className="widget-heading">{t("description")}</div>
														<div className="widget-numbers text-info">{this.props.data.description}</div>
													</div>
												</div>
											</div>
										</div>
									</ListGroupItem>
									<ListGroupItem className="d-flex justify-content-between">
										<div className="widget-content p-0">
											<div className="widget-content-outer">
												<div className="widget-content-wrapper">
													<div className="widget-content-left">
														<div className="widget-heading">{t("allocatedUnits")}</div>
														{this.props.data.changeAllocatedUnits ? (
															<Input
																type="number"
																bsSize="sm"
																id="allocatedUnits"
																min={0}
																onChange={this.props.getValue}
																defaultValue={this.props.data.allocatedUnits || 0}
																invalid={this.props.data.invalidAllocatedUnits}
															/>
														) : (
															<div className="widget-numbers text-info">
																{Number(this.props.data.allocatedUnits).toLocaleString()}
															</div>
														)}
													</div>
												</div>
											</div>
										</div>
										<div className="widget-content p-0">
											<div className="widget-content-outer">
												<div className="widget-content-wrapper">
													<div className="widget-content-right">
														{this.props.data.changeAllocatedUnits ? (
															<div className="icon-wrapper align-items-start">
																<UpdateIcon onupdate={(e) => this.props.updateProjectField("allocatedUnits")} />
																<CancelIcon
																	parentClassName="align-items-start"
																	onHoverColor="danger"
																	onCancel={(e) => this.props.cancelChange("allocatedUnits")}
																/>
															</div>
														) : (
															<GetPermission perm="ops.change_project">
																<EditIcon code="perm" onEdit={() => this.props.changeField("allocatedUnits")} />
															</GetPermission>
														)}
													</div>
												</div>
											</div>
										</div>
									</ListGroupItem>
									<ListGroupItem className="d-flex justify-content-between">
										<div className="widget-content p-0">
											<div className="widget-content-outer">
												<div className="widget-content-wrapper">
													<div className="widget-content-left">
														<div className="widget-heading">{t("rentedDays")}</div>
														{this.props.data.changeRentedDays ? (
															<Input
																type="number"
																bsSize="sm"
																id="rentedDays"
																min={0}
																onChange={this.props.getValue}
																defaultValue={this.props.data.rentedDays || 0}
																invalid={this.props.data.invalidRentedDays}
															/>
														) : (
															<div className="widget-numbers text-info">
																{Number(this.props.data.rentedDays).toLocaleString()}
															</div>
														)}
													</div>
												</div>
											</div>
										</div>
										<div className="widget-content p-0">
											<div className="widget-content-outer">
												<div className="widget-content-wrapper">
													<div className="widget-content-right ">
														{this.props.data.changeRentedDays ? (
															<div className="icon-wrapper align-items-start">
																<UpdateIcon onupdate={(e) => this.props.updateProjectField("rentedDays")} />
																<CancelIcon
																	parentClassName="align-items-start"
																	onHoverColor="danger"
																	onCancel={(e) => this.props.cancelChange("rentedDays")}
																/>
															</div>
														) : (
															<GetPermission perm="ops.change_project">
																<EditIcon code="perm" onEdit={() => this.props.changeField("rentedDays")} />
															</GetPermission>
														)}
													</div>
												</div>
											</div>
										</div>
									</ListGroupItem>
								</ListGroup>
							</CardBody>
						</Col>
					</Row>
					<CardFooter className="d-flex justify-content-end">
						<GetPermission perm="ops.close_project">
							<div code="perm">
								<Button color="danger" onClick={this.props.closeProject} hidden={this.props.data.status === "Completed"}>
									{t("close")}
								</Button>
							</div>
						</GetPermission>
						{this.props.data.status !== "Completed" && (
							<>
								<GetPermission perm="ops.add_workorder">
									<div code="perm">
										<Button color="info" onClick={this.props.addWO} className="ml-1" hidden={this.props.data.plan}>
											{t("addWO")}
										</Button>
									</div>
								</GetPermission>
								<GetPermission perm="ops.add_commodity">
									<div code="perm">
										<Button color="info" onClick={this.props.addCommodity} className="ml-1">
											{t("addCommodity")}
										</Button>
									</div>
								</GetPermission>
								<GetPermission perm="ops.add_projectleg">
									<div code="perm">
										<Button color="info" onClick={this.props.addLeg} className="ml-1">
											{t("addLeg")}
										</Button>
									</div>
								</GetPermission>
							</>
						)}
					</CardFooter>
				</Card>
			)
		);
	}
}
export default ProjectCard;
