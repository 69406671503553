import { sideMenu } from "./NavItems";
import { connect } from "react-redux";
import MetisMenu from "react-metismenu";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import React, { Component, Fragment } from "react";
import GetPermission from "ops/common/get_permission";
import { setEnableMobileMenu } from "../../reducers/ThemeOptions";

class Nav extends Component {
	state = {};

	toggleMobileSidebar = () => {
		let { enableMobileMenu, setEnableMobileMenu } = this.props;
		setEnableMobileMenu(!enableMobileMenu);
	};

	render() {
		const { t } = this.props;
		return (
			<Fragment>
				<MetisMenu
					iconNamePrefix=""
					activeLinkFromLocation
					content={sideMenu(t).Home}
					className="vertical-nav-menu"
					onSelected={this.toggleMobileSidebar}
					classNameStateIcon="pe-7s-angle-down"
				/>
				<GetPermission perm="ops.view_project">
					<div code="perm">
						<MetisMenu
							iconNamePrefix=""
							activeLinkFromLocation
							className="vertical-nav-menu"
							content={sideMenu(t).Project}
							onSelected={this.toggleMobileSidebar}
							classNameStateIcon="pe-7s-angle-down"
						/>
					</div>
				</GetPermission>

				<GetPermission perm="ops.view_costing">
					<div code="perm">
						<MetisMenu
							iconNamePrefix=""
							activeLinkFromLocation
							className="vertical-nav-menu"
							content={sideMenu(t).ProjectCosting}
							onSelected={this.toggleMobileSidebar}
							classNameStateIcon="pe-7s-angle-down"
						/>
					</div>
				</GetPermission>

				<GetPermission perm="ops.view_plan">
					<div code="perm">
						<MetisMenu
							iconNamePrefix=""
							activeLinkFromLocation
							content={sideMenu(t).Plan}
							className="vertical-nav-menu"
							onSelected={this.toggleMobileSidebar}
							classNameStateIcon="pe-7s-angle-down"
						/>
					</div>
				</GetPermission>
				<GetPermission perm="ops.view_workorder">
					<div code="perm">
						<MetisMenu
							iconNamePrefix=""
							activeLinkFromLocation
							content={sideMenu(t).WO}
							className="vertical-nav-menu"
							onSelected={this.toggleMobileSidebar}
							classNameStateIcon="pe-7s-angle-down"
						/>
					</div>
				</GetPermission>
				<GetPermission perm="ops.archive_workorder">
					<div code="perm">
						<MetisMenu
							iconNamePrefix=""
							activeLinkFromLocation
							content={sideMenu(t).ArchivedWO}
							className="vertical-nav-menu"
							onSelected={this.toggleMobileSidebar}
							classNameStateIcon="pe-7s-angle-down"
						/>
					</div>
				</GetPermission>
				<GetPermission perm="mntc.view_testdriverequest">
					<div code="perm">
						<MetisMenu
							iconNamePrefix=""
							activeLinkFromLocation
							content={sideMenu(t).testDriveRequest}
							className="vertical-nav-menu"
							onSelected={this.toggleMobileSidebar}
							classNameStateIcon="pe-7s-angle-down"
						/>
					</div>
				</GetPermission>
				<GetPermission perm="ops.view_trip">
					<div code="perm">
						<MetisMenu
							iconNamePrefix=""
							activeLinkFromLocation
							content={sideMenu(t).Trip}
							className="vertical-nav-menu"
							onSelected={this.toggleMobileSidebar}
							classNameStateIcon="pe-7s-angle-down"
						/>
					</div>
				</GetPermission>
				<GetPermission perm="ops.add_vendortrip">
					<div code="perm">
						<MetisMenu
							iconNamePrefix=""
							activeLinkFromLocation
							content={sideMenu(t).vendor}
							className="vertical-nav-menu"
							onSelected={this.toggleMobileSidebar}
							classNameStateIcon="pe-7s-angle-down"
						/>
					</div>
				</GetPermission>
				<GetPermission perm="mntc.view_rfr">
					<div code="perm">
						<MetisMenu
							iconNamePrefix=""
							activeLinkFromLocation
							content={sideMenu(t).RFR}
							className="vertical-nav-menu"
							onSelected={this.toggleMobileSidebar}
							classNameStateIcon="pe-7s-angle-down"
						/>
					</div>
				</GetPermission>
				<GetPermission perm="ops.view_trip">
					<div code="perm">
						<MetisMenu
							iconNamePrefix=""
							activeLinkFromLocation
							content={sideMenu(t).Reports}
							className="vertical-nav-menu"
							onSelected={this.toggleMobileSidebar}
							classNameStateIcon="pe-7s-angle-down"
						/>
					</div>
				</GetPermission>
				<GetPermission perm="users.change_ops_settings">
					<div code="perm">
						<MetisMenu
							iconNamePrefix=""
							activeLinkFromLocation
							content={sideMenu(t).Setup}
							className="vertical-nav-menu"
							onSelected={this.toggleMobileSidebar}
							classNameStateIcon="pe-7s-angle-down"
						/>
					</div>
				</GetPermission>
			</Fragment>
		);
	}

	isPathActive = (path) => {
		return this.props.location.pathname.startsWith(path);
	};
}

const mapStateToProps = (state) => ({
	enableMobileMenu: state.ThemeOptions.enableMobileMenu,
});

const mapDispatchToProps = (dispatch) => ({
	setEnableMobileMenu: (enable) => dispatch(setEnableMobileMenu(enable)),
});

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withRouter(Nav)));
