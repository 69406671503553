import i18n from "i18n";
import axios from "axios";
import { odooAxios } from "axios_interceptors";
import Loader from "react-loaders";
import React, { Component } from "react";
import DeleteIcon from "ops/common/delete_icon";
import LoadingOverlay from "react-loading-overlay";
import PageTitleAlt2 from "ops/common/PageTitleAlt2";
import { Card, CardBody, Col, Row } from "reactstrap";
import GetPermission from "ops/common/get_permission";
import TableWithPaginationSearch from "ops/common/table_with_pagination_search";
import { encryptData } from '../../common/encryption_service';

class ListLeg extends Component {
	constructor() {
		super();
		this.state = {
			legs: [],
			loading: false,
		};
	}

	getLegs = () => {
		this.setState({ loading: true });
		axios
			.get(`leg/?serializer=get`)
			.then((res) => {
				let data = res.data.map((leg, index) => {
					return { ...leg, index: index };
				});
				this.setState({ loading: false, legs: data }, () => {
					this.setState({ updateLegs: this.state.legs });
				});
			})
			.catch((err) => {
				console.error(err.response);
				this.setState({ loading: false });
			});
	};

	// used to send any required data to odoo by passing the url and the data
	sendDataToOdoo = (odooURL, odooData) => {
		odooAxios
		  .post(odooURL, odooData, {}, {
			  headers: {
				'Content-Type': 'application/json',
			  }
		  })
		  .then((odooResponse) => {
				console.log("Data successfully sent to Odoo:", odooResponse.data.result);
		  })
		  .catch((odooError) => {
				console.error("Failed to send data to Odoo:", odooError);
		  });
	};

	deleteLeg = (e, leg, index) => {
		this.setState({ loading: true });
		axios
			.delete(`leg/${leg.id}/`)
			.then(async (res) => {
				// passing leg ID to delete it from odoo
				const odooData = {
					id: String(leg.id)+"-odoo-code"
				}
				const { encryptedData, authTag, iv } = await encryptData(odooData);
				const encryptedObject = {
					encrypted_data: encryptedData,
					tag: authTag,
					iv,
				}
				this.sendDataToOdoo(`delete_leg`, encryptedObject);
				let legs = this.state.legs.filter((state) => state.id !== leg.id);
				let data = legs.map((leg, index) => {
					return { ...leg, index: index };
				});
				this.setState({ legs: data }, () => {
					this.setState({ loading: false });
				});
			})
			.catch((err) => {
				console.error(err.response);
				this.setState({ loading: false });
			});
	};

	componentDidMount() {
		this.getLegs();
	}

	render() {
		const t = i18n.t;

		const legsColumns = [
			{
				sort: true,
				align: "center",
				dataField: "name",
				headerAlign: "center",
				text: t("name"),
			},
			{
				sort: true,
				align: "center",
				dataField: "source",
				headerAlign: "center",
				text: t("source"),
			},
			{
				sort: true,

				align: "center",
				dataField: "destination",
				headerAlign: "center",
				text: t("destination"),
			},
			{
				sort: true,
				align: "center",
				dataField: "distance",
				headerAlign: "center",
				text: t("distance"),
			},
			{
				sort: true,
				align: "center",
				headerAlign: "center",
				dataField: "actions",
				text: t("actions"),
				formatter: (cell, leg, index) => {
					if (leg && leg["deleteable"]) {
						return (
							<GetPermission perm="ops.delete_leg">
								<DeleteIcon code="perm" index={leg.index} ondelete={(e) => this.deleteLeg(e, leg, index)} />
							</GetPermission>
						);
					}
				},
			},
		];

		return (
			<>
				<PageTitleAlt2 heading={t("legs")} icon="bi bi-signpost-2-fill icon-gradient bg-happy-fisher" />
				<LoadingOverlay
					tag="div"
					styles={{
						overlay: (base) => ({
							...base,
							background: "#fff",
							opacity: 0.5,
						}),
					}}
					active={this.state.loading}
					spinner={<Loader active color="#30b1ff" type="line-spin-fade-loader" />}>
					<Row className="m-3 justify-content-center">
						<Col xs="12">
							<Card>
								<CardBody>
									<Row>
										<Col>
											<TableWithPaginationSearch
												scrollable
												search
												exportable
												filename="legs.csv"
												data={this.state.legs}
												keyfield="id"
												columns={legsColumns}
												loading={this.state.loading}
											/>
										</Col>
									</Row>
								</CardBody>
							</Card>
						</Col>
					</Row>
				</LoadingOverlay>
			</>
		);
	}
}

export default ListLeg;
