import i18n from "i18n";
import React from "react";
import { Row, Col } from "reactstrap";
import CardZeroButton from "../../common/card_zero_button";
import { Bar, XAxis, YAxis, Brush, Legend, Tooltip, BarChart, ReferenceLine, CartesianGrid, ResponsiveContainer } from "recharts";

class VehicleStats extends React.Component {
	constructor() {
		super();
		this.state = {
			trailerData: [],
		};
	}
	render() {
		let t = i18n.t;
		/*let columns = [
			{
				sort: true,
				align: "center",
				headerAlign: "center",
				text: t("truckMakeModel"),
				dataField: "assetModel__make__name",
				formatter: (cell, row) => {
					return cell + "-" + row.assetModel__name;
				},
			},
			{
				sort: true,
				hidden: true,
				align: "center",
				headerAlign: "center",
				text: t("truckMakeModel"),
				dataField: "assetModel__name",
			},
			{
				sort: true,
				align: "center",
				text: t("available"),
				headerAlign: "center",
				dataField: "Available",
				formatter: (cell) => {
					return Number(cell).toLocaleString("en");
				},
				style: (cell) => {
					if (Number(cell) <= 0) {
						return { color: "red" };
					}
				},
			},
			{
				sort: true,
				align: "center",
				headerAlign: "center",
				text: t("unavailable"),
				dataField: "Unavailable",
				formatter: (cell) => {
					return Number(cell).toLocaleString("en");
				},
			},
			{
				sort: true,
				align: "center",
				headerAlign: "center",
				text: t("dispatched"),
				dataField: "Dispatched",
				formatter: (cell) => {
					return Number(cell).toLocaleString("en");
				},
			},
		];
		let trailerColumns = [
			{
				sort: true,
				align: "center",
				headerAlign: "center",
				text: t("trailerType"),
				dataField: "assetSubType__name",
				formatter: (cell) => {
					return cell ? cell : "N/A";
				},
			},
			{
				sort: true,
				align: "center",
				text: t("available"),
				headerAlign: "center",
				dataField: "Available",
				formatter: (cell) => {
					return Number(cell).toLocaleString("en");
				},
				style: (cell) => {
					if (Number(cell) <= 0) {
						return { color: "red" };
					}
				},
			},
			{
				sort: true,
				align: "center",
				headerAlign: "center",
				text: t("unavailable"),
				dataField: "Unavailable",
				formatter: (cell, row, rowIndex) => {
					return Number(cell).toLocaleString("en");
				},
			},
			{
				sort: true,
				align: "center",
				headerAlign: "center",
				text: t("dispatched"),
				dataField: "Dispatched",
				formatter: (cell) => {
					return Number(cell).toLocaleString("en");
				},
			},
		];*/
		return (
			<Row>
				{/*<Col xs="12" sm="12" md="4" lg="4" xl="4" hidden={this.props.truckstats.length > 0 ? false : true}>
					<CardZeroButton title={t("truckStats")}>
						<TableWithPaginationSearch scrollable exportable key={0} columns={columns} data={this.props.truckstats} />
					</CardZeroButton>
				</Col>*/}
				<Col xs="12" sm="12" md="12" lg="12" xl="12" hidden={this.props.truckstats.length > 0 ? false : true}>
					<CardZeroButton title={t("truckStats")}>
						<ResponsiveContainer width="100%" aspect={2.0 / 0.9}>
							<BarChart data={this.props.truckstats}>
								<XAxis dataKey="assetModel__name" />
								<YAxis />
								<Tooltip />
								<Legend verticalAlign="top" wrapperStyle={{ lineHeight: "40px" }} />
								<Brush
									height={20}
									stroke="#fece78"
									dataKey="assetModel__name"
									//endIndex={this.props.truckstats.length > 3 ? 3 : 0}
								/>
								<Bar dataKey="Unavailable" fill="#fece78" name={t("unavailable")} />
								<Bar dataKey="Available" fill="#82ca9d" name={t("available")} />
								<Bar dataKey="Dispatched" fill="#007788" name={t("dispatched")} />
							</BarChart>
						</ResponsiveContainer>
					</CardZeroButton>
				</Col>
				{/*<Col xs="12" sm="12" md="4" lg="4" xl="4" hidden={this.props.trailerstats.length > 0 ? false : true}>
					<CardZeroButton title={t("trailerStats")}>
						<TableWithPaginationSearch scrollable exportable key={1} columns={trailerColumns} data={this.props.trailerstats} />
					</CardZeroButton>
				</Col>*/}
				<Col xs="12" sm="12" md="12" lg="12" xl="12" hidden={this.props.trailerstats.length > 0 ? false : true}>
					<CardZeroButton title={t("trailerStats")}>
						<ResponsiveContainer width="100%" aspect={2.0 / 0.9}>
							<BarChart data={this.props.trailerstats}>
								<CartesianGrid strokeDasharray="3 3" />
								<XAxis dataKey={"assetSubType__name"} />
								<YAxis />
								<Tooltip />
								<Legend verticalAlign="top" wrapperStyle={{ lineHeight: "40px" }} />
								<ReferenceLine y={0} stroke="#000" />
								<Brush dataKey="assetSubType__name" height={20} stroke="#fece78" />
								<Bar dataKey="Unavailable" fill="#fece78" name={t("unavailable")} />
								<Bar dataKey="Available" fill="#82ca9d" name={t("available")} />
								<Bar dataKey="Dispatched" fill="#007788" name={t("dispatched")} />
							</BarChart>
						</ResponsiveContainer>
					</CardZeroButton>
				</Col>
			</Row>
		);
	}
}

export default VehicleStats;
