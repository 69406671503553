import i18n from "i18n";
import axios from "axios";
import ViewIcon from "ops/common/view_icon";
import { withRouter } from "react-router-dom";
import DeleteIcon from "ops/common/delete_icon";
import DeletePopup from "ops/common/delete_popup";
import React, { Component, Fragment } from "react";
import PageTitleAlt2 from "ops/common/PageTitleAlt2";
import { Card, CardBody, Col, Row, Label, Spinner } from "reactstrap";
import GetPermission from "ops/common/get_permission";
import DateRangePicker from "ops/common/date_range_picker";
import { AlertNotification } from "ops/common/alert_notification";
import TableWithPaginationSearch from "ops/common/table_with_pagination_search";
import Selector from "ops/common/selector";

class ViewNonPlannedArchivedWOList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			wos: [],
			loading: false,
            startDate: new Date((new Date()).getFullYear(), new Date().getMonth(), 1).toLocaleDateString("en-CA") + "T00:00:00",
            endDate: new Date().toLocaleDateString("en-CA") + "T23:59:59",
			instances:[],
			selectedInstance: null,
		};
	}

	filterByInstance = (e, v) => {
		if (v) {
			this.setState({ selectedInstance: v.id });
		} else {
			this.setState({selectedInstance: null});
		}
	};
	
	getInstances = (callback) => {
		axios.get(`profile/userinstances`).then((res) => {
			this.setState({ instances: res.data }, () => {
				if (callback) callback(); // Execute callback after instances are set
			});
		})
	}

	getDates = (sDate, eDate) => {
		this.setState({ startDate: sDate, endDate: eDate }, () => {
			this.getWOs(sDate, eDate);
		});
	};

	getWOs = (sDate, eDate) => {
		this.setState({ loading: true });

		const { instances, selectedInstance } = this.state;

		// Use selected instance ID if it exists, otherwise check if there's only one instance
		const instanceFilter = selectedInstance 
			? `&instance=${selectedInstance}`
			: instances.length === 1 ? `&instance=${instances[0].id}` : "";
		
		axios
            .get(`workorder/?serializer=archive&isArchived=1&startDate__gte=${sDate}&startDate__lte=${eDate}${instanceFilter}`)
            .then((res) => {
                this.setState({
                    wos: res.data,
                    loading: false,
                });
            });
	};

	confirmDeleteWorkOrder = (workorder) => {
		return DeletePopup({
			title: "Are you sure ?",
			cancelbtntxt: "No",
			confirmbtntxt: "Yes",
			text: `This will delete Work Order ${workorder.code}`,
			confirmedresult: () => this.deleteWorkOrder(workorder.id),
		});
	};

	deleteWorkOrder = (workorderID) => {
		this.setState({ loading: true });
		axios
			.delete(`workorder/${workorderID}`)
			.then((res) => {
				if (res) {
					AlertNotification(i18n.t("deleted"), "success");
					let workorders = [...this.state.wos].filter((workorder) => workorder.id !== workorderID);
					this.setState({ wos: workorders }, () => {
						this.setState({ loading: false });
					});
				}
			})
			.catch((err) => {
				console.error(err.response);
				this.setState({ loading: false });
				AlertNotification(i18n.t("errorMessage"), "error");
			});
	};

	componentDidMount = () => {
		// Call getInstances and pass a callback to getWOs
		this.getInstances(() => {
			this.getWOs(this.state.startDate, this.state.endDate);
		});
	};

	componentDidUpdate(prevProps, prevState) {
		if (prevState.selectedInstance !== this.state.selectedInstance) {
			this.getWOs(this.state.startDate, this.state.endDate);
		}
	}

	render() {
		const t = i18n.t;
		const woColumns = [
			this.state.instances.length > 1 && {
				sort: true,
				align: "center",
				headerAlign: "center",
				dataField: "instance",
				text: t("company"),
			},
			{
				sort: true,
				align: "center",
				headerAlign: "center",
				dataField: "code",
				text: t("code"),
			},
			{
				sort: true,
				align: "center",
				headerAlign: "center",
				dataField: "startDate",
				text: t("startDate"),
			},
			{
				sort: true,
				align: "center",
				headerAlign: "center",
				dataField: "driver",
				text: t("driver"),
			},
			{
				hidden: true,
				sort: true,
				align: "center",
				headerAlign: "center",
				dataField: "assisstant",
				text: t("assistant"),
			},
			{
				sort: true,
				align: "center",
				headerAlign: "center",
				dataField: "asset",
				text: t("asset"),
			},
			{
				sort: true,
				align: "center",
				headerAlign: "center",
				dataField: "attachedAsset",
				text: t("attachedAsset"),
			},
			{
				sort: true,
				align: "center",
				dataField: "tripsCount",
				headerAlign: "center",
				text: t("tripsCount"),
			},
			{
				align: "center",
				headerAlign: "center",
				dataField: "actions",
				text: t("actions"),
				formatter: (cell, wo) => {
					return (
						<>
							<GetPermission perm="ops.view_workorder">
								<ViewIcon code="perm" target="_blank" to={`/viewworkorder/?id=${wo.id}`} />
							</GetPermission>
							<GetPermission perm="ops.delete_workorder">
								<div code="perm">
									<DeleteIcon ondelete={() => this.confirmDeleteWorkOrder(wo)} hidden={!wo.deletable} />
								</div>
							</GetPermission>
						</>
					);
				},
			},
		];
		return (
			<Fragment>
				<PageTitleAlt2 heading={t("archivedWorkOrders")} icon="bi bi-hdd-network icon-gradient bg-happy-fisher" />
				<Row className="mb-3">
					<Col xs="6">
						<DateRangePicker getdata={this.getDates} />
					</Col>
					{
						this.state.instances.length > 1 &&
						<Col xs="3">
							<Label>{t("company")}</Label>
							<Selector
								clearable
								id="instanceFilter"
								size="small"
								isObjectOption
								optionAccessor="name"
								options={this.state.instances}
								onChange={this.filterByInstance}
							/>
						</Col>
					}
				</Row>
				<Row>
				{
					this.state.loading ? (
						<Col xs="12" className="d-flex justify-content-center align-items-center">
							<div style={{ textAlign: "center" }}>
								<Spinner style={{ width: "3rem", height: "3rem" }} color="black" />
								<p style={{ display: "block", marginTop: "1rem" }}>Loading...</p>
							</div>
						</Col>
					) : (
					<Col xs="12">
						<Card className="main-card mb-3" key="0">
							<CardBody>
								<TableWithPaginationSearch
									scrollable
									search
									keyfield="id"
									columns={woColumns}
									data={this.state.wos}
									loading={this.state.loading}
								/>
							</CardBody>
						</Card>
					</Col>
				)}
				</Row>
			</Fragment>
		);
	}
}

export default withRouter(ViewNonPlannedArchivedWOList);
