import React, {Component} from "react";
import {Card, CardBody, CardHeader} from "reactstrap";

class CardZeroButton extends Component {
	render() {
		return (
			<Card className="main-card mb-3" hidden={this.props.hidden}>
				<CardHeader className="text-capitalize card-header-tab" hidden={this.props.hideheader}>
					<div className="card-header-title font-size-lg text-capitalize font-weight-normal">
						<i
							className={
								"header-icon " +
								(this.props.headerIcon ? this.props.headerIcon : "pe-7s-note2 icon-gradient bg-arielle-smile")
							}>
							{" "}
						</i>
						{this.props.title}
					</div>
				</CardHeader>
				<CardBody>{this.props.children}</CardBody>
			</Card>
		);
	}
}
export default CardZeroButton;
