import React, { Component } from "react";
import axios from "axios";
import TableWithPaginationSearch from "../../common/table_with_pagination_search";
import {
  Row,
  Col,
  Input,
  Card,
  CardBody,
  CardTitle,
  Spinner,
} from "reactstrap";
import CardZeroButton from "../../common/card_zero_button";
import DateRangePicker from "../../common/date_range_picker";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Brush,
} from "recharts";
import { t } from "i18next";

class ProjectsStats extends Component {
  constructor(props) {
    super(props);
    this.state = {
      projectTypes: [],
      projectsIDs: {},
      projectDataByType: {},
      sizePerpage: 4,
      projectsStatsData: {},
      projectWorkOrders: {},
      startDate: "",
      endDate: "",
      startDateTrips: "",
      endDateTrips: "",
      selectedTypeId: "",
      allTrips: [],
      serviceTypesWithWO: [],
      loading: false,
      instances: props.instances || [],
      selectedInstance: props.selectedInstance || null 
    };
  }

  componentDidMount() {
    axios
      .get("getprojecttype/")
      .then((res) => {
        this.setState({ projectTypes: res.data });
      })
      .catch((error) => console.error("Error fetching project types:", error));
  }

  componentDidUpdate(prevProps) {
    // Check if selectedInstance or instances have changed
    if (
      prevProps.selectedInstance !== this.props.selectedInstance ||
      prevProps.instances !== this.props.instances
    ) {
      // Update the state with the latest instances and selectedInstance from props
      this.setState(
        {
          instances: this.props.instances,
          selectedInstance: this.props.selectedInstance
        },
        () => {
          // Call data-fetching functions after the state has been updated
          this.fetchProjectsAndWorkOrdersByType();
          this.fetchProjectsStatsByType();
          this.fetchProjectsIDsByType();
          this.fetchWOwithServiceType();
        }
      );
    }
  }
  
  

  fetchProjectsAndWorkOrdersByType = () => {
    const { projectTypes } = this.state;
    let allProjectsData = {};
    const { instances, selectedInstance } = this.state;
		// Use selected instance ID if it exists, otherwise check if there's only one instance
		let instanceFilter = selectedInstance 
			? `&instance=${selectedInstance}`
			: instances.length === 1 ? `&instance=${instances[0].id}` : "";

    const projectRequests = projectTypes.map((type) =>
      axios
        .get(
          `project/?serializer=basic&projectType=${type.id}&status=In Progress${instanceFilter}`
        )
        .then((res) => {
          allProjectsData[type.id] = res.data;
        })
        .catch((error) =>
          console.error(`Error fetching projects for type ${type.name}:`, error)
        )
    );

    Promise.all(projectRequests).then(() => {
      Object.keys(allProjectsData).forEach((typeId) => {
        allProjectsData[typeId] = allProjectsData[typeId].filter(
          (project) => project.workOrders > 0
        );
      });

      // Update state once with all the data
      this.setState({ projectDataByType: allProjectsData });
    });
  };

  fetchProjectsStatsByType = () => {
    const { projectTypes } = this.state;
    const { instances, selectedInstance } = this.state;
		// Use selected instance ID if it exists, otherwise check if there's only one instance
		let instanceFilter = selectedInstance 
			? `&instance=${selectedInstance}`
			: instances.length === 1 ? `&instance=${instances[0].id}` : "";

    projectTypes.forEach((type) => {
      axios
        .get(
          `project/?serializer=project&projectType=${type.id}&startDate__gte=${this.state.startDate}&startDate__lte=${this.state.endDate}${instanceFilter}`
        )
        .then((res) => {
          this.setState((prevState) => ({
            projectsStatsData: {
              ...prevState.projectsStatsData,
              [type.id]: res.data,
            },
          }));
        })
        .catch((error) =>
          console.error(`Error fetching projects for type ${type.name}:`, error)
        );
    });
  };

  fetchProjectsIDsByType = () => {
    const { projectTypes } = this.state;
    const { instances, selectedInstance } = this.state;
		// Use selected instance ID if it exists, otherwise check if there's only one instance
		let instanceFilter = selectedInstance 
			? `&instance=${selectedInstance}`
			: instances.length === 1 ? `&instance=${instances[0].id}` : "";

    projectTypes.forEach((type) => {
      axios
        .get(`project/?serializer=id&projectType=${type.id}${instanceFilter}`)
        .then((res) => {
          this.setState((prevState) => ({
            projectsIDs: {
              ...prevState.projectsIDs,
              [type.id]: res.data,
            },
          }));
        })
        .catch((error) =>
          console.error(`Error fetching projects for type ${type.name}:`, error)
        );
    });
  };

  fetchProjectWorkOrdersByType = async (typeId) => {
    this.setState({ loading: true });
    const {
      projectsIDs,
      startDateTrips,
      endDateTrips,
      projectWorkOrders,
    } = this.state;
    const projects = projectsIDs[typeId] || [];
    const currentDateRange = `${startDateTrips}-${endDateTrips}`;

    // Initialize projectWorkOrders[typeId] as an array if it doesn't exist
    if (!projectWorkOrders[typeId]) {
      projectWorkOrders[typeId] = [];
    }

    // Check if data has already been fetched for the current date range and project type
    const existingData = projectWorkOrders[typeId].find(
      (entry) => entry.dateRange === currentDateRange
    );
    if (existingData) {
      // If data is already fetched, just call the filter function
      this.filterTripsByDate();
      this.setState({ loading: false });
      return;
    }

    // Fetch all project data for the given type
    const requests = projects.map((project) =>
      axios.get(
        `workorder/?serializer=simple&project=${project.id}&start_date=${startDateTrips}&end_date=${endDateTrips}`
      )
    );

    try {
      const responses = await Promise.all(requests);

      // Prepare the data to be saved
      const newDataEntry = {
        dateRange: currentDateRange,
        data: {},
      };

      responses.forEach((res, index) => {
        newDataEntry.data[projects[index].id] = res.data;
      });

      // Update state with the new data
      this.setState((prevState) => ({
        projectWorkOrders: {
          ...prevState.projectWorkOrders,
          [typeId]: [...prevState.projectWorkOrders[typeId], newDataEntry],
        },
      }));
    } catch (error) {
      console.error(`Error fetching projects for type ${typeId}:`, error);
    } finally {
      this.setState({ loading: false });
    }
  };

  filterTripsByDate = () => {
    const {
      projectWorkOrders,
      selectedTypeId,
      startDateTrips,
      endDateTrips,
    } = this.state;

    // Check if selectedTypeId is valid and if projectWorkOrders contains data for it
    if (!selectedTypeId || !projectWorkOrders[selectedTypeId]) return;

    const currentDateRange = `${startDateTrips}-${endDateTrips}`;

    // Find the data entry for the current date range
    const projectDataEntry = projectWorkOrders[selectedTypeId].find(
      (entry) => entry.dateRange === currentDateRange
    );

    if (!projectDataEntry) return; // No data found for the selected date range

    const projectData = projectDataEntry.data;
    let allTrips = [];

    // Iterate through each project’s work orders and filter trips by date range
    Object.values(projectData).forEach((workOrders) => {
      workOrders.forEach((workOrder) => {
        if (workOrder.trips) {
          // Filter trips based on the selected date range
          const filteredTrips = workOrder.trips.filter((trip) => {
            const creationDate = trip.creationDate;
            return (
              creationDate >= startDateTrips && creationDate <= endDateTrips
            );
          });

          // Transform the creationDate format
          const transformedTrips = filteredTrips.map((trip) => ({
            ...trip,
            creationDate: new Date(trip.creationDate)
              .toISOString()
              .split("T")[0],
          }));

          allTrips = [...allTrips, ...transformedTrips];
        }
      });
    });

    // Update state with the filtered trips
    this.setState({ allTrips });
  };

  handleProjectTypeChange = async (event) => {
    const selectedTypeId = event.target.value;
    this.setState({ selectedTypeId }, async () => {
      if (selectedTypeId) {
        await this.fetchProjectWorkOrdersByType(selectedTypeId); // Ensure data fetching is complete
        this.filterTripsByDate(); // Ensure filtering happens after data is fetched
      } else {
        this.setState({ allTrips: [] }); // Clear trips if no type is selected
      }
    });
  };

  getDates = (sdate, edate) => {
    this.setState({ startDate: sdate, endDate: edate }, () => {
      this.fetchProjectsStatsByType();
    });
  };

  getDatesforTrips = (sdate, edate) => {
    this.setState({ startDateTrips: sdate, endDateTrips: edate }, async () => {
      const { selectedTypeId } = this.state;
      if (selectedTypeId) {
        await this.fetchProjectWorkOrdersByType(selectedTypeId); // Await data fetching
        this.filterTripsByDate(); // Ensure filtering happens after data is fetched
      }
    });
  };

  fetchWOwithServiceType = async () => {
    const { instances, selectedInstance } = this.state;
		// Use selected instance ID if it exists, otherwise check if there's only one instance
		let instanceFilter = selectedInstance 
			? `&instance=${selectedInstance}`
			: instances.length === 1 ? `&instance=${instances[0].id}` : "";
    try {
      const response = await axios.get(`/getservicetype?${instanceFilter}`);
      const filteredServiceTypes = response.data.filter(
        (serviceType) => serviceType.workOrders > 0
      );
      this.setState({ serviceTypesWithWO: filteredServiceTypes });
    } catch (error) {
      console.error(
        "Error fetching service types with work order counts:",
        error
      );
    }
  };

  calculateTotals = (typeId) => {
    const { projectsStatsData } = this.state;
    let totalTrips = 0;
    let totalLoadedQTY = 0;
    let totalDistance = 0;

    const projectStats = projectsStatsData[typeId] || [];

    projectStats.forEach((project) => {
      totalTrips += project.number_of_trips || 0;
      totalLoadedQTY += project.loadedQTY || 0;
      totalDistance += project.total_distance || 0;
    });

    return { totalTrips, totalLoadedQTY, totalDistance };
  };

  aggregateTripsByDate = () => {
    const { allTrips } = this.state;

    // Create an object to hold the count of trips for each date
    const tripsCountByDate = allTrips.reduce((acc, trip) => {
      const date = trip.creationDate;
      if (!acc[date]) {
        acc[date] = 0;
      }
      acc[date] += 1;
      return acc;
    }, {});

    // Convert the object to an array of { date, count } for charting
    const aggregatedData = Object.keys(tripsCountByDate).map((date) => ({
      date,
      count: tripsCountByDate[date],
    }));

    return aggregatedData;
  };

  aggregateLoadedQTYByDate = () => {
    const { allTrips } = this.state;

    // Create an object to hold the total loadedQTY for each date
    const loadedQTYByDate = allTrips.reduce((acc, trip) => {
      const date = trip.creationDate;
      const loadedQTY = parseFloat(trip.loadedQTY) || 0; // Ensure loadedQTY is treated as a number

      if (!acc[date]) {
        acc[date] = 0;
      }
      acc[date] += loadedQTY; // Sum the loadedQTY for trips on the same date

      return acc;
    }, {});

    // Convert the object to an array of { date, loadedQTY } for charting
    const aggregatedData = Object.keys(loadedQTYByDate).map((date) => ({
      date,
      loadedQTY: parseFloat(loadedQTYByDate[date].toFixed(2)), // Optionally round to 2 decimal places
    }));

    return aggregatedData;
  };

  aggregateAverageLoadedQTYByDate = () => {
    const { allTrips } = this.state;

    // Create an object to hold the total loadedQTY and the count of trips for each date
    const qtyAndCountByDate = allTrips.reduce((acc, trip) => {
      const date = trip.creationDate;
      if (!acc[date]) {
        acc[date] = { totalQTY: 0, tripCount: 0 };
      }
      acc[date].totalQTY += parseFloat(trip.loadedQTY) || 0; // Accumulate total loadedQTY
      acc[date].tripCount += 1; // Increment the trip count
      return acc;
    }, {});

    // Calculate the average loadedQTY for each date and format the data for the chart
    const aggregatedData = Object.keys(qtyAndCountByDate).map((date) => ({
      date,
      averageLoadedQTY: parseFloat(
        qtyAndCountByDate[date].totalQTY / qtyAndCountByDate[date].tripCount
      ).toFixed(2),
    }));

    return aggregatedData;
  };

  renderTableForType = (type) => {
    const projects = this.state.projectDataByType[type.id] || [];
    // Calculate the total count of trucks
    const countOfTrucks = projects.reduce(
      (total, project) => total + project.workOrders,
      0
    );
    const columns = [
      {
        sort: true,
        align: "center",
        headerAlign: "center",
        text: t("projectName"),
        dataField: "code",
      },
      {
        sort: true,
        align: "center",
        headerAlign: "center",
        text: t("projectDescription"),
        dataField: "description",
      },
      {
        sort: true,
        align: "center",
        headerAlign: "center",
        text: t("commodity"),
        dataField: "commodity",
        formatter: (cell) => cell.map((commodity) => commodity.name).join(", "),
      },
      {
        sort: true,
        align: "center",
        headerAlign: "center",
        text: t("status"),
        dataField: "status",
        hidden: true,
      },
      {
        sort: true,
        align: "center",
        headerAlign: "center",
        text: t("countOfTrucks"),
        dataField: "workOrders",
      },
    ];

    const CustomCard = ({ title, value }) => {
      const formattedValue = value.toLocaleString(undefined, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
      });

      return (
        <Card
          style={{
            border: "1px solid #007788",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            borderRadius: "10px",
            textAlign: "center",
            fontSize: "13px",
          }}
        >
          <CardBody style={{ padding: "10px" }}>
            <CardTitle
              style={{
                fontWeight: "bold",
                fontSize: "1.1em",
                color: "#007788",
              }}
            >
              {title}
            </CardTitle>
            <h5 style={{ color: "#444", fontSize: "1.4em" }}>
              {formattedValue}
            </h5>
          </CardBody>
        </Card>
      );
    };

    return (
      <Col xs="12" sm="12" md="12" lg="12" xl="12" key={type.id}>
        <CardZeroButton title={`${type.name} Projects`}>
          <TableWithPaginationSearch
            keyfield="id"
            columns={columns}
            pagination
            search
            sizePerpage={this.state.sizePerpage}
            data={this.state.projectDataByType[type.id] || []}
            /*onSelectFilter={this.onSelectFilter}
            filtersOptions={this.state.filtersOptions}
            filterTitle={this.state.filtersOptions[0].name}*/
          />
          <Row className="mb-4">
            <Col xs="12" sm="12" md="12" lg="12" xl="12">
              <CustomCard title={t("countOfTrucks")} value={countOfTrucks} />
            </Col>
          </Row>
        </CardZeroButton>
      </Col>
    );
  };

  renderTableForServiceType = () => {
    const workOrders = this.state.serviceTypesWithWO || [];
    const countOfTrucks = workOrders.reduce(
      (total, workOrder) => total + workOrder.workOrders,
      0
    );

    const columns = [
      {
        sort: true,
        align: "center",
        headerAlign: "center",
        text: t("projectName"),
        dataField: "name",
      },
      {
        sort: true,
        align: "center",
        headerAlign: "center",
        text: t("countOfTrucks"),
        dataField: "workOrders",
      },
    ];

    const CustomCard = ({ title, value }) => {
      /* const formattedValue = value.toLocaleString(undefined, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
      });*/

      return (
        <Card
          style={{
            border: "1px solid #007788",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            borderRadius: "10px",
            textAlign: "center",
            fontSize: "13px",
          }}
        >
          <CardBody style={{ padding: "10px" }}>
            <CardTitle
              style={{
                fontWeight: "bold",
                fontSize: "1.1em",
                color: "#007788",
              }}
            >
              {title}
            </CardTitle>
            <h5 style={{ color: "#444", fontSize: "1.4em" }}>{value}</h5>
          </CardBody>
        </Card>
      );
    };

    return (
      <Col xs="12" sm="12" md="12" lg="12" xl="12">
        <CardZeroButton title={`Service Projects`}>
          <TableWithPaginationSearch
            keyfield="id"
            columns={columns}
            pagination
            search
            sizePerpage={this.state.sizePerpage}
            data={workOrders || []}
            /*onSelectFilter={this.onSelectFilter}
            filtersOptions={this.state.filtersOptions}
            filterTitle={this.state.filtersOptions[0].name}*/
          />
          <Row className="mb-4">
            <Col xs="12" sm="12" md="12" lg="12" xl="12">
              <CustomCard title={t("countOfTrucks")} value={countOfTrucks} />
            </Col>
          </Row>
        </CardZeroButton>
      </Col>
    );
  };

  renderProjectsStats = (type) => {
    const data = this.state.projectsStatsData[type.id] || [];
    const brushEnabled = data.length > 9;
    const { totalTrips, totalLoadedQTY, totalDistance } = this.calculateTotals(
      type.id
    );

    const CustomCard = ({ title, value }) => {
      const formattedValue = value.toLocaleString(undefined, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
      });

      return (
        <Card
          style={{
            border: "1px solid #007788",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            borderRadius: "10px",
            textAlign: "center",
            fontSize: "15px",
          }}
        >
          <CardBody style={{ padding: "15px" }}>
            <CardTitle
              style={{
                fontWeight: "bold",
                fontSize: "1.2em",
                color: "#007788",
              }}
            >
              {title}
            </CardTitle>
            <h5 style={{ color: "#444", fontSize: "1.5em" }}>
              {formattedValue}
            </h5>
          </CardBody>
        </Card>
      );
    };

    return (
      <>
        <CardZeroButton title={`${type.name} Stats`}>
          <Row className="mb-4">
            <Col xs="12" sm="4" md="4" lg="4" xl="4">
              <CustomCard title={t("trips")} value={totalTrips} />
            </Col>
            <Col xs="12" sm="4" md="4" lg="4" xl="4">
              <CustomCard title={t("loadedQTY")} value={totalLoadedQTY} />
            </Col>
            <Col xs="12" sm="4" md="4" lg="4" xl="4">
              <CustomCard title={t("legKM")} value={totalDistance} />
            </Col>
          </Row>
          <Col xs="12" sm="12" md="12" lg="12" xl="12" key={type.id}>
            <ResponsiveContainer width="100%" aspect={1.7 / 0.7}>
              <BarChart data={data} layout="vertical">
                <XAxis
                  type="number"
                  dataKey="number_of_trips"
                  tick={{ fontSize: 12 }}
                />
                <YAxis
                  type="category"
                  dataKey="code"
                  tick={{ fontSize: 12 }}
                  width={150}
                />
                <Tooltip />
                {brushEnabled && (
                  <Brush
                    dataKey="code"
                    height={20}
                    stroke="#fece78"
                    startIndex={0}
                    endIndex={8}
                  />
                )}
                <Bar
                  dataKey="number_of_trips"
                  fill="#007788"
                  name={t("numOfTrips")}
                />
              </BarChart>
            </ResponsiveContainer>
          </Col>
        </CardZeroButton>
      </>
    );
  };

  renderTripsAndQuantityStats = () => {
    const { projectTypes } = this.state;
    const tripsData = this.aggregateTripsByDate(); // Aggregate trips by date
    const loadedQTYData = this.aggregateLoadedQTYByDate();
    const averageLoadedQTYData = this.aggregateAverageLoadedQTYByDate();

    const brushEnabledTrips = tripsData.length >= 8;
    const brushEnabledQTY = loadedQTYData.length >= 8;
    const brushEnabledAverage = averageLoadedQTYData.length >= 8;
    const showChart =
      this.state.allTrips.length > 0 &&
      this.state.startDateTrips &&
      this.state.endDateTrips;

    const TripsChart = ({ data }) => (
      <ResponsiveContainer width="100%" aspect={1.7 / 0.7}>
        <BarChart data={data}>
          <XAxis dataKey="date" />
          <YAxis />
          <Tooltip />
          {brushEnabledTrips && (
            <Brush
              dataKey="count"
              height={20}
              stroke="#fece78"
              startIndex={0}
              endIndex={8}
            />
          )}
          <Bar dataKey="count" fill="#007788" name={t("numOfTrips")} />
        </BarChart>
      </ResponsiveContainer>
    );

    const LoadedQTYChart = ({ data }) => (
      <ResponsiveContainer width="100%" aspect={1.7 / 0.7}>
        <BarChart data={data}>
          <XAxis dataKey="date" />
          <YAxis />
          <Tooltip />
          {brushEnabledQTY && (
            <Brush
              dataKey="loadedQTY"
              height={20}
              stroke="#fece78"
              startIndex={0}
              endIndex={8}
            />
          )}
          <Bar dataKey="loadedQTY" fill="#007788" name={t("loadedQTY")} />
        </BarChart>
      </ResponsiveContainer>
    );

    const AverageLoadedQTYChart = ({ data }) => (
      <ResponsiveContainer width="100%" aspect={1.7 / 0.7}>
        <BarChart data={data}>
          <XAxis dataKey="date" />
          <YAxis />
          <Tooltip />
          {brushEnabledAverage && (
            <Brush
              dataKey="averageLoadedQTY"
              height={20}
              stroke="#fece78"
              startIndex={0}
              endIndex={8}
            />
          )}
          <Bar
            dataKey="averageLoadedQTY"
            fill="#007788"
            name={t("averageLoadedQtyPerTrip")}
          />
        </BarChart>
      </ResponsiveContainer>
    );

    return (
      <>
        <CardZeroButton title={t("tripsAndQuantityStats")}>
          <Row>
            <Col xs="12" md="8">
              <DateRangePicker getdata={this.getDatesforTrips} />
            </Col>
            <Col xs="12" md="4" style={{ marginTop: "25px" }}>
              <Input
                type="select"
                name={t("selectProjectType")}
                onChange={this.handleProjectTypeChange} // Handle the change of project type
              >
                <option value="">{t("selectProjectType")}</option>
                {projectTypes.map((type) => (
                  <option key={type.id} value={type.id}>
                    {type.name}
                  </option>
                ))}
              </Input>
            </Col>
          </Row>
        </CardZeroButton>
        {this.state.loading ? (
          <Row style={{ marginTop: "25px" }}>
            <Col xs="12" className="text-center">
              <Spinner animation="border" />
              <p>Loading...</p>
            </Col>
          </Row>
        ) : showChart ? (
          <>
            <CardZeroButton title={t("tripsStats")}>
              <Row style={{ marginTop: "25px" }}>
                <Col xs="12">
                  <TripsChart data={tripsData} />
                </Col>
              </Row>
            </CardZeroButton>
            <CardZeroButton title={t("loadedQTYStats")}>
              <Row style={{ marginTop: "25px" }}>
                <Col xs="12">
                  <LoadedQTYChart data={loadedQTYData} />
                </Col>
              </Row>
            </CardZeroButton>
            <CardZeroButton title={t("averageLoadedQtyPerTripStats")}>
              <Row style={{ marginTop: "25px" }}>
                <Col xs="12">
                  <AverageLoadedQTYChart data={averageLoadedQTYData} />
                </Col>
              </Row>
            </CardZeroButton>
          </>
        ) : (
          <Row style={{ marginTop: "25px" }}>
            <Col xs="12">
              <p style={{ justifyContent: "center", display: "flex" }}>
                {t("noDataAvailable")}
              </p>
            </Col>
          </Row>
        )}
      </>
    );
  };

  render() {
    const { projectTypes } = this.state;

    return (
      <>
        <div>
          {projectTypes.map(this.renderTableForType)}
          {this.renderTableForServiceType()}
          <Col xs="12" md="7" style={{ marginLeft: "-13px" }}>
            <DateRangePicker getdata={this.getDates} />
          </Col>
          {projectTypes.map(this.renderProjectsStats)}
          {this.renderTripsAndQuantityStats()}
        </div>
      </>
    );
  }
}

export default ProjectsStats;
