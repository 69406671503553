import i18n from "i18n";
import axios from "axios";
import React from "react";
import moment from "moment";
import { Loader } from "react-loaders";
import { withRouter } from "react-router";
import { Input } from "@material-ui/core";
import Selector from "ops/common/selector";
import { authAxios } from "axios_interceptors";
import DeleteIcon from "ops/common/delete_icon";
import LoadingOverlay from "react-loading-overlay";
import PageTitleAlt2 from "ops/common/PageTitleAlt2";
import GetPermission from "ops/common/get_permission";
import NewRFR from "../../non_planned_wo/components/new_rfr";
import { AlertNotification } from "ops/common/alert_notification";
import TripForm from "../../non_planned_wo/components/trip_form";
import WorkOrderCard from "ops/non_planned_wo/components/wo_card";
import ExpenseForm from "ops/non_planned_wo/components/expense_form";
import WOCloseModal from "../../non_planned_wo/components/wo_close_modal";
import ListNonPlannedWOTrips from "ops/non_planned_wo/components/list_trip";
import { Row, Col, Card, CardBody, CardFooter, Button, Form } from "reactstrap";
import ReviewExpenseForm from "ops/non_planned_wo/components/review_expense_form";

class ViewWO extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			logs: {},
			wo: null,
			trips: [],
			newTrips: [],
			newRFR: null,
			companies: [],
			editWO: false,
			editable: false,
			closeWOData: {},
			scrolPosition: 0,
			expensesTypes: [],
			tripsReceipts: [],
			tripsExpenses: [],
			confirmEdit: false,
			formLoading: false,
			initExpense: false,
			postExpenses: false,
			routeProjectLegs: [],
			closeWOModal: false,
			expensesReviews: [],
			modifiedExpenses: [],
			endKMEditIndex: null,
			totalReviewedExpense: 0,
			startKMEditIndex: null,
			indexOfEditingCell: null,
			projectsExpenseTypes: {},
			extraInfoActiveIndex: null,
			newTripEndKMEditIndex: null,
			newTripStartKMEditIndex: null,
		};
		this.expensesCount = 0;
		this.expenses = [];
	}

	setExtraInfoActiveIndex = (index) => {
		this.setState({ extraInfoActiveIndex: index });
	};

	setScrolPosition = (scrol) => {
		this.setState({ scrolPosition: scrol.scrollLeft });
	};

	getCompanies = () => {
		this.setState({ formLoading: true });
		axios
			.get("company/")
			.then((res) => {
				if (res)
					this.setState({ companies: res.data }, () => {
						this.setState({ formLoading: false });
					});
			})
			.catch((err) => {
				if (err) {
					AlertNotification(null, "error", err.response);
					console.error(err.response);
					this.setState({ formLoading: false });
				}
			});
	};

	validateTripRecipet = (e) => {
		let tripIndex = e.target.getAttribute("tripindex");
		let tripID = e.target.getAttribute("tripid");
		let target = e.target.name;
		let tripsReceipts = [...this.state.tripsReceipts];
		if (!tripsReceipts[tripIndex]) {
			tripsReceipts[tripIndex] = { id: tripID };
		}
		tripsReceipts[tripIndex][`invalid${target.charAt(0).toUpperCase()}${target.slice(1, target.length)}`] = true;
		this.setState({ tripsReceipts: tripsReceipts });
	};

	getLoadingReceiptAndCopounNos = (e) => {
		let tripIndex = e.target.getAttribute("tripindex");
		let tripID = e.target.getAttribute("tripid");
		let target = e.target.name;
		let value = e.target.value;
		let tripsReceipts = [...this.state.tripsReceipts];
		if (tripsReceipts[tripIndex]) {
			tripsReceipts[tripIndex][target] = value;
		} else {
			tripsReceipts[tripIndex] = { [target]: value, id: tripID };
		}
		tripsReceipts[tripIndex][`invalid${target.charAt(0).toUpperCase()}${target.slice(1, target.length)}`] = false;
		this.setState({ tripsReceipts: tripsReceipts });
	};

	postExpenses = (e) => {
		e.preventDefault();
		this.setState({ postExpenses: true });
	};

	getExpenseValue = (tripexpenses) => {
		this.expenses.push(...tripexpenses);
		this.expensesCount = this.expensesCount + 1;
		if (this.expensesCount !== 0 && this.expensesCount === this.state.trips.length) {
			this.submitInitSettel();
		}
	};

	submitInitSettel = () => {
		if (this.expensesCount !== this.state.trips.length) {
			return;
		}
		let allExpenses = [...this.expenses];
		this.setState({ postExpenses: false });

		this.expensesCount = 0;
		this.expenses = [];
		this.setState({ formLoading: true });
		axios
			.post(`expense/`, { allExpenses: allExpenses, tripsReceipts: this.state.tripsReceipts })
			.then((res) => {
				if (res) {
					this.setState({ formLoading: false, tripsExpenses: [], initExpense: false });
					this.viewWOPlan();
				}
			})
			.catch((err) => {
				if (err) {
					AlertNotification(null, "error");
					this.setState({ formLoading: false });
					console.error(err.response);
				}
			});
	};

	addTrip = () => {
		this.getRouteProjectLegs();
		this.getCompanies();
		let startKM = 0;
		if (this.state.trips.length === 0 && this.state.newTrips.length === 0) startKM = this.state.wo.startKM;
		this.setState({
			newTrips: this.state.newTrips.concat({
				workOrder: this.state.wo.id,
				startKM: startKM,
				endKM: 0,
				loadedQTY: 0,
				deliveredQTY: 0,
				loadingDate: moment().format("YYYY-MM-DDTHH:mm"),
				unLoadingDate: moment().format("YYYY-MM-DDTHH:mm"),
			}),
		});
	};

	removeTrip = (e) => {
		let index = e.target.getAttribute("index");
		let updatedNewTrips = [...this.state.newTrips];
		updatedNewTrips.splice(index, 1);
		this.setState({ newTrips: updatedNewTrips });
	};

	getNewTripValue = (e) => {
		let value = e.target.value;
		let target = e.target.id;
		let index = e.target.getAttribute("index");
		if (target.includes("Date")) {
			value = moment(value + moment().format("THH:mm")).format("YYYY-MM-DDTHH:mm");
		}
		let updatedNewTrips = [...this.state.newTrips];
		updatedNewTrips[index][target] = value;
		updatedNewTrips[index][`invalid${target.charAt(0).toUpperCase()}${target.slice(1, target.length)}`] = false;
		if (target === "startKM") this.setState({ newTripStartKMEditIndex: index });
		if (target === "endKM") this.setState({ newTripEndKMEditIndex: index });

		this.setState({ newTrips: updatedNewTrips });
	};

	validateNewTrip = (e) => {
		let target = e.target.id.split("-")[0];
		let tripIndex = e.target.name;
		let newTrips = this.state.newTrips;
		newTrips[tripIndex][`invalid${target.charAt(0).toUpperCase()}${target.slice(1, target.length)}`] = true;
		this.setState({ newTrips: newTrips });
	};

	getNewTripSelectedValue = (e, v, index) => {
		let target = e.target.id.split("-")[0];
		let updatedNewTrips = [...this.state.newTrips];
		updatedNewTrips[index][target] = v.id;
		updatedNewTrips[index][`${target}Object`] = v;
		updatedNewTrips[index][`invalid${target.charAt(0).toUpperCase()}${target.slice(1, target.length)}`] = false;

		/////////////////////////////////////////////////////////////////////////
		// This code related to trip sequance validation which commented untill implenting settings feature
		// if (target === "leg" || target === "projectLeg") {
		// 	if (index !== 0) {
		// 		let prevTrip = updatedNewTrips[index - 1];
		// 		if (
		// 			prevTrip[`${target}Object`].destination.toLowerCase() !== updatedNewTrips[index][`${target}Object`].source.toLowerCase()
		// 		) {
		// 			updatedNewTrips[index][`invalid${target.charAt(0).toUpperCase()}${target.slice(1, target.length)}`] = true;
		// 		}
		// 	} else {
		// 		if (this.state.trips.length !== 0) {
		// 			let prevTrip = this.state.trips[this.state.trips.length - 1];
		// 			if (prevTrip.destination.toLowerCase() !== updatedNewTrips[index][`${target}Object`].source.toLowerCase()) {
		// 				updatedNewTrips[index][`invalid${target.charAt(0).toUpperCase()}${target.slice(1, target.length)}`] = true;
		// 			}
		// 		}
		// 	}
		// }
		/////////////////////////////////////////////////////////////////////////

		this.setState({ newTrips: updatedNewTrips });
	};

	isValidDate = (id, value, index) => {
		let valid = true;
		let woEndDate = moment(this.state.wo.endDate, "DD-MM-YYYY hh:mm A").format("YYYY-MM-DD");
		let woStartDate = moment(this.state.wo.startDate, "DD-MM-YYYY hh:mm A").format("YYYY-MM-DD");
		if (id === "unLoadingDate" && moment(value).isBefore(this.state.trips[index].loadingDate, "day")) valid = false;
		if (id === "loadingDate" && moment(value).isBefore(woStartDate, "day")) valid = false;
		if (id === "loadingDate" && moment(value).isAfter(this.state.trips[index].unLoadingDate, "day")) valid = false;
		if (((this.state.wo.endDate && id === "unLoadingDate") || id === "loadingDate") && moment(value).isAfter(woEndDate, "day"))
			valid = false;
		return valid;
	};

	getTripDateValue = (e) => {
		let id = e.target.id;
		let value = e.target.value;
		value = moment(value + moment().format("THH:mm")).format("YYYY-MM-DDTHH:mm");
		let updatedTrips = [...this.state.trips];
		let index = e.target.name;
		if ((id === "unLoadingDate" || id === "loadingDate") && !this.isValidDate(id, value, index))
			updatedTrips[index][`invalid${id.charAt(0).toUpperCase()}${id.slice(1, id.length)}`] = true;
		else {
			updatedTrips[index][id] = value;
			updatedTrips[index][`invalid${id.charAt(0).toUpperCase()}${id.slice(1, id.length)}`] = false;
		}
		this.setState({ trips: updatedTrips });
	};

	getTripCellValue = (e, v, tripIndex) => {
		let value = e.target.value,
			id = e.target.id,
			index = e.target.name;
		let updatedTrips = [...this.state.trips];
		if (v) {
			id = e.target.id.split("-")[0];
			value = v.id;
			index = tripIndex;
			updatedTrips[index][`${id}Obj`] = v;
		}
		updatedTrips[index][id] = value;
		if (id === "startKM") this.setState({ startKMEditIndex: index });
		if (id === "endKM") this.setState({ endKMEditIndex: index });
		if (id === "startKM" || id === "endKM") {
			updatedTrips[index]["distance"] = updatedTrips[index]["endKM"] - updatedTrips[index]["startKM"];
		}
		if (id === "loadedQTY" || id === "deliveredQTY") {
			updatedTrips[index]["difference"] = updatedTrips[index]["loadedQTY"] - updatedTrips[index]["deliveredQTY"];
		}
		this.setState({ trips: updatedTrips });
	};

	onFill = (e) => {
		this.calculateKM("trips");
	};

	calculateKM = (tripsState) => {
		this.setState({ formLoading: true });
		let trips = [...this.state.trips];
		let firstTripStartKM = Number(trips[0].startKM).toFixed(2);
		let lastTripEndKM = Number(trips[trips.length - 1].endKM).toFixed(2);
		if (firstTripStartKM > 0 && lastTripEndKM > 0) {
			if (lastTripEndKM - firstTripStartKM < 0) {
				AlertNotification(i18n.t("lastTripEndKMMustBeGreaterThanFirstTripStartKM"), "error");
				this.setState({ formLoading: false });
				return;
			}
			let stdDistance = 0;
			trips.forEach((trip) => {
				stdDistance += trip.tripDistance;
			});
			let actualDistance = lastTripEndKM - firstTripStartKM;
			trips.forEach((trip, index) => {
				if (index === 0) {
					trip.startKM = firstTripStartKM;
				} else {
					trip.startKM = Number(trips[index - 1].endKM).toFixed(2);
				}
				if (index === trips.length - 1) {
					trip.endKM = lastTripEndKM;
				} else {
					trip.endKM = (Number(trip.startKM) + (Number(trip.tripDistance) / stdDistance) * actualDistance).toFixed(2);
				}
				trip.distance = (Number(trip.endKM) - Number(trip.startKM)).toFixed(2);
			});
		} else {
			trips.forEach((trip, index) => {
				if (index === 0) {
					trip.startKM = Number(this.state.wo.startKM).toFixed(2);
				} else {
					trip.startKM = Number(trips[index - 1].endKM).toFixed(2);
				}
				trip.endKM = (Number(trip.startKM) + Number(trip.tripDistance)).toFixed(2);
				trip.distance = (Number(trip.endKM) - Number(trip.startKM)).toFixed(2);
			});
		}
		this.setState({
			[tripsState]: trips,
			formLoading: false,
		});
	};

	submitTrip = (e) => {
		e.preventDefault();

		/////////////////////////////////////////////////////////////////////////
		// This code related to trip sequance validation which commented untill implenting settings feature
		// for (let trip of this.state.newTrips) {
		// 	if (trip.invalidLeg || trip.invalidProjectLeg) {
		// 		return;
		// 	}
		// }
		/////////////////////////////////////////////////////////////////////////

		this.setState({ formLoading: true });
		axios
			.post(`trip/bulk-create/`, this.state.newTrips)
			.then((res) => {
				if (res) {
					this.getWoData(this.state.wo.id);
					if (this.state.initExpense) this.initExpense();
				}
				this.setState({ formLoading: false });
			})
			.catch((err) => {
				if (err) {
					console.error(err.response);
					this.setState({ formLoading: false });
					AlertNotification(null, "error");
				}
			});
	};

	updateTrips = () => {
		let pass = true;
		let data = this.state.trips.map((trip) => {
			let newTrip = {
				id: trip.id,
				endKM: trip.endKM,
				startKM: trip.startKM,
				loadedQTY: trip.loadedQTY,
				deliveredQTY: trip.deliveredQTY,
				owner: trip.owner && (typeof trip.owner === "object" ? trip.owner.id : trip.owner),
				loadingDate: trip.loadingDate ? moment(trip.loadingDate).format("YYYY-MM-DDTHH:mm") : trip.loadingDate,
				unLoadingDate: trip.unLoadingDate ? moment(trip.unLoadingDate).format("YYYY-MM-DDTHH:mm") : trip.unLoadingDate,
			};
			if (trip.projectLegObj) {
				newTrip.projectLeg = trip.projectLeg;
			}
			if (trip.invalidUnLoadingDate || trip.invalidLoadingDate) pass = false;
			return newTrip;
		});
		if (!pass) return AlertNotification(i18n.t("inconsistentDates"), "error");
		this.setState({ formLoading: true });
		axios
			.post(`trip/bulkupdate/`, data)
			.then((res) => {
				if (res) {
					this.setState({ formLoading: false });
					AlertNotification(i18n.t("updated"), "success");
				}
			})
			.catch((err) => {
				if (err) {
					console.error(err.response);
					this.setState({ formLoading: false });
					AlertNotification(null, "error");
				}
			});
	};

	deleteTrip = async (e) => {
		let index = e.target.getAttribute("index");
		let tripID = this.state.trips[index].id;
		this.setState({ formLoading: true });
		await axios
			.delete(`trip/${this.state.trips[index].id}/`)
			.then((res) => {
				if (res) {
					let updatedTrips = this.state.trips.filter((trip) => trip.id !== tripID);
					let trips = updatedTrips.map((trip, index) => {
						return { ...trip, index: index };
					});
					this.setState({ trips: trips }, () => {
						this.setState({ formLoading: false });
						if (this.state.initExpense) this.initExpense();
					});
					AlertNotification(i18n.t("deleted"), "success");
				}
			})
			.catch((err) => {
				if (err) {
					console.error(err.response);
					AlertNotification(null, "error");
					this.setState({ formLoading: false });
				}
			});
	};

	viewWOPlan = () => {
		let planID = this.props.history.location.plan;
		if (!planID) planID = this.state.wo.plan;
		this.props.history.push({ pathname: "/viewplan/", planID: planID });
	};

	closeWO = (e) => {
		e.preventDefault();
		let closeWOData = { ...this.state.closeWOData };
		if (closeWOData["invalidEndKMExceedMin"] || !closeWOData["endDate"]) return false;
		if (this.state.editWO) {
			this.setState({ confirmEdit: true, closeWOModal: false });
		} else {
			axios
				.put(`workorder/close/`, { id: this.state.wo.id, closeWOData: closeWOData })
				.then((res) => {
					if (res) {
						this.setState({ wo: { ...this.state.wo, ...this.state.closeWOData }, closeWOModal: false });
						AlertNotification(i18n.t("closed"), "success");
					}
				})
				.catch((err) => {
					if (err) {
						console.error(err.response);
						AlertNotification(null, "error");
					}
				});
		}
	};

	getUpdatedTrips = (ordering = "") => {
		this.setState({ formLoading: true });
		axios
			.get(`trip/?serializer=workorder&workOrder=${this.state.wo.id}${ordering}`)
			.then((res) => {
				if (res) {
					let trips = res.data.map((trip, index) => {
						return { ...trip, index: index };
					});
					this.setState({ trips: trips, newTrips: [] }, () => {
						this.setState({ formLoading: false });
					});
				}
			})
			.catch((err) => {
				if (err) {
					AlertNotification(null, "error", err.response);
					console.error(err.response);
					this.setState({ formLoading: false });
				}
			});
	};

	initExpense = async () => {
		this.setState({ formLoading: true });
		let projectsCodes = this.props.history.location.projects;
		if (!projectsCodes) {
			projectsCodes = [];
			await axios.get(`project/?plan=${this.state.wo.plan}`).then((res) => {
				for (let project of res.data) {
					projectsCodes.push(project.code);
				}
			});
		}
		await axios
			.post(`project/getExpenseTypes/`, { projectsCodes: projectsCodes })
			.then((res) => {
				if (res) {
					this.setState({ projectsExpenseTypes: res.data }, () => {
						this.setState({ formLoading: false });
						this.getUpdatedTrips("&ordering=loadingDate");
					});
				}
			})
			.catch((err) => {
				if (err) {
					console.error(err.response);
					AlertNotification(null, "error");
				}
			});
		await axios
			.get(`trip/?serializer=initsettel&workOrder=${this.state.wo.id}&ordering=loadingDate`, {
				projectsCodes: projectsCodes,
			})
			.then((res) => {
				if (res) {
					this.setState({ tripsExpenses: res.data });
				}
			})
			.catch((err) => {
				if (err) {
					console.error(err.response);
					AlertNotification(null, "error", err.response);
				}
			});
		await axios
			.get(`expensetype/`)
			.then((res) => {
				if (res) {
					this.setState(
						{
							expensesTypes: res.data,
						},
						() => {
							this.setState({ formLoading: false, initExpense: true });
						}
					);
				}
			})
			.catch((err) => {
				if (err) {
					console.error(err.response);
					this.setState({ formLoading: false });
					AlertNotification(null, "error", err.response);
				}
			});
	};

	getExpenses = () => {
		this.setState({ formLoading: true });
		this.getUpdatedTrips("&ordering=loadingDate");
		axios
			.get(`trip/?serializer=expenses&workOrder=${this.state.wo.id}&ordering=loadingDate`)
			.then((res) => {
				if (res)
					this.setState({ expensesReviews: res.data }, () => {
						this.setState({ formLoading: false });
					});
			})
			.catch((err) => {
				if (err) {
					AlertNotification(null, "error", err.response);
					console.error(err.response);
					this.setState({ formLoading: false });
				}
			});
	};

	getExpensesTotal = (tripindex) => {
		let sum = 0;
		const expensesReviews = [...this.state.expensesReviews];
		let expenses = expensesReviews[tripindex]["expenses"];
		expenses.forEach((expense) => {
			if (expense.expenseType !== "Driver Bonus") sum += Number(expense.amount);
		});
		expensesReviews[tripindex]["total"] = sum.toFixed(2);
		this.setState({ expensesReviews: expensesReviews });
	};

	getExpenseReviewValue = (e) => {
		let expenseIndex = e.target.getAttribute("index");
		let tripIndex = e.target.getAttribute("tripindex");
		let updatedExpenses = [...this.state.modifiedExpenses];
		let updatedExpensesReviews = [...this.state.expensesReviews];
		let expenseObj = updatedExpensesReviews[tripIndex]["expenses"][expenseIndex];
		expenseObj.amount = e.target.value;
		if (!expenseObj.index) {
			let expenseObjIndex = updatedExpenses.push(expenseObj);
			expenseObj.index = expenseObjIndex - 1;
		} else updatedExpenses[expenseObj.index] = expenseObj;
		this.setState({ expensesReviews: updatedExpensesReviews, modifiedExpenses: updatedExpenses }, () => {
			this.getExpensesTotal(tripIndex);
		});
	};

	submitExpenseReview = (e) => {
		this.setState({ formLoading: true });
		axios
			.post(`expense/review/?actionType=${e.target.name}&workOrder=${this.state.wo.id}`, this.state.modifiedExpenses)
			.then((res) => {
				if (res) {
					this.setState({ modifiedExpenses: [], expensesReviews: [] });
					this.setState({ formLoading: false });
					this.viewWOPlan();
				}
			})
			.catch((err) => {
				if (err) {
					this.setState({ formLoading: false });
					console.error(err.response);
				}
			});
	};

	getRouteProjectLegs = () => {
		axios
			.get(`route/${this.state.wo.route.id}/routeleg`)
			.then((res) => {
				if (res) this.setState({ routeProjectLegs: res.data });
			})
			.catch((err) => {
				if (err) {
					AlertNotification(null, "error", err.response);
					console.error(err.response);
				}
			});
	};

	getWoData = (woID) => {
		this.setState({ formLoading: true });
		axios
			.get(`workorder/${woID}/?serializer=get`)
			.then((res) => {
				let trips = res.data["trips"].map((trip, index) => {
					return { ...trip, index: index };
				});
				this.setState(
					{
						formLoading: false,
						wo: res.data,
						trips: trips,
						newTrips: [],
						confirmEdit: false,
					},
					() => {
						this.getRouteProjectLegs();
						if (this.state.wo.endDate) {
							this.setState({ editWO: true }, () => {
								if (this.state.wo.logs.length !== 0) {
									let logDict = {};
									for (let log of this.state.wo.logs) {
										let key = log.event.replaceAll(" ", "");
										logDict[`${key.charAt(0).toLowerCase()}${key.slice(1, key.length)}`] = true;
									}
									this.setState({ logs: logDict }, () => {
										this.updatePermission();
									});
								}
							});
						}
					}
				);
			})
			.catch((err) => {
				console.error(err.response);
				this.setState({ formLoading: false });
			});
	};

	createRFR = () => {
		this.setState({ newRFR: { workOrder: this.state.wo.id } });
	};

	cancelRFR = () => {
		this.setState({ newRFR: null });
	};

	closeWOModal = () => {
		if (this.state.closeWOModal) {
			this.setState({
				closeWOModal: false,
			});
			return;
		}
		let pass = true;
		let trips = [...this.state.trips];
		for (let trip of trips) {
			if (
				!Number(trip.startKM) ||
				!Number(trip.endKM) ||
				!Number(trip.loadedQTY) ||
				!Number(trip.deliveredQTY) ||
				!trip.loadingDate ||
				!trip.unLoadingDate ||
				!trip.owner
			) {
				pass = false;
			}
		}
		if (trips.length === 0) pass = true;
		if (!pass) {
			AlertNotification(i18n.t("fillTrips"), "error");
			return;
		}
		this.setState({
			closeWOModal: !this.state.closeWOModal,
			closeWOData: {
				startDate: moment(this.state.wo.startDate, "DD-MM-YYYY hh:mm A").format("YYYY-MM-DDTHH:mm"),
				endDate: this.state.wo.endDate
					? moment(this.state.wo.endDate, "DD-MM-YYYY hh:mm A").format("YYYY-MM-DDTHH:mm")
					: moment().format("YYYY-MM-DDTHH:mm"),
				startKM: this.state.wo.startKM,
				endKM: this.state.wo.endKM
					? this.state.wo.endKM
					: this.state.trips.length !== 0
					? this.state.trips[this.state.trips.length - 1].endKM
					: this.state.wo.startKM,
			},
		});
	};

	getCloseWOValues = (e) => {
		let target = e.target.name,
			value = e.target.value;
		if (target === "endDate" && moment(e.target.value).isBefore(this.state.closeWOData.startDate, "day")) {
			this.setState({
				closeWOData: {
					...this.state.closeWOData,
					[target]: null,
					[`invalid${target.charAt(0).toUpperCase()}${target.slice(1, target.length)}`]: true,
				},
			});
		} else if (target === "endKM") {
			let actualDistance = value - parseInt(this.state.closeWOData.startKM),
				standardDistance = this.state.wo.distance["distance__sum"];
			if (!standardDistance) standardDistance = 0;
			if (!(actualDistance - standardDistance >= -10 && actualDistance - standardDistance <= 10) || actualDistance < 0) {
				this.setState({
					closeWOData: {
						...this.state.closeWOData,
						[target]: value,
						invalidEndKMExceedMin: actualDistance < 0 ? i18n.t("invalidEndKMExceedMin") + this.state.closeWOData.startKM : null,
						invalidEndKMDistance: !(actualDistance - standardDistance >= -10 && actualDistance - standardDistance <= 10)
							? i18n.t("invalidEndKMDistance") + standardDistance
							: null,
						[`invalid${target.charAt(0).toUpperCase()}${target.slice(1, target.length)}`]: true,
					},
				});
			} else {
				this.setState({
					closeWOData: {
						...this.state.closeWOData,
						[target]: value,
						invalidEndKMExceedMin: null,
						invalidEndKMDistance: null,
						[`invalid${target.charAt(0).toUpperCase()}${target.slice(1, target.length)}`]: false,
					},
				});
			}
		} else {
			this.setState({
				closeWOData: {
					...this.state.closeWOData,
					[target]: value,
					[`invalid${target.charAt(0).toUpperCase()}${target.slice(1, target.length)}`]: false,
				},
			});
		}
	};

	validateCloseWO = (e) => {
		let target = e.target.name;
		this.setState({
			closeWOData: {
				...this.state.closeWOData,
				[`invalid${target.charAt(0).toUpperCase()}${target.slice(1, target.length)}`]: true,
			},
		});
	};

	editWO = () => {
		this.setState({ editWO: true }, () => {
			this.getCompanies();
			this.closeWOModal();
		});
	};

	onConfirmEdit = () => {
		let pass = true;
		let closeWOData = { ...this.state.closeWOData };
		let trips = this.state.trips.map((trip) => {
			let updatedTrip = {
				id: trip.id,
				endKM: trip.endKM,
				startKM: trip.startKM,
				loadedQTY: trip.loadedQTY,
				deliveredQTY: trip.deliveredQTY,
				owner: trip.owner && (typeof trip.owner === "object" ? trip.owner.id : trip.owner),
				loadingDate: trip.loadingDate ? moment(trip.loadingDate).format("YYYY-MM-DDTHH:mm") : trip.loadingDate,
				unLoadingDate: trip.unLoadingDate ? moment(trip.unLoadingDate).format("YYYY-MM-DDTHH:mm") : trip.unLoadingDate,
			};
			if (trip.invalidUnLoadingDate) pass = false;
			return updatedTrip;
		});
		if (!pass) return;
		this.setState({ formLoading: true });
		axios
			.put(`workorder/updateWorkOrder/`, {
				id: this.state.wo.id,
				closeWOData: closeWOData,
				trips: trips,
				event: this.state.eventName,
			})
			.then((res) => {
				if (res) {
					this.setState({ formLoading: false });
					AlertNotification(i18n.t("updated"), "success");
					this.getWoData(this.state.wo.id);
				}
			})
			.catch((err) => {
				if (err) {
					console.error(err.response);
					this.setState({ formLoading: false });
					AlertNotification(null, "error");
				}
			});
	};

	onCancelEdit = () => {
		this.setState({ editWO: false, confirmEdit: false });
	};

	updatePermission = async () => {
		let hasSuperiorEdit = false;
		await authAxios.post(`checkperm`, { perm: "ops.delete_log" }).then((res) => {
			if (res.data) hasSuperiorEdit = !res.data.perm;
		});
		let editable = true,
			eventName = "";
		let logs = { ...this.state.logs };
		if (logs["initialSettelment"]) {
			if (logs["expenseReview"]) {
				if (logs["finalSettlement"]) {
					if (logs["editWorkOrderFinal"]) {
						editable = false;
					} else {
						eventName = "Edit WorkOrder Final";
					}
				} else {
					if (logs["editWorkOrderReview"]) {
						editable = false;
					} else {
						eventName = "Edit WorkOrder Review";
					}
				}
			} else {
				if (logs["editWorkOrderInitial"]) {
					editable = false;
				} else {
					eventName = "Edit WorkOrder Initial";
				}
			}
		}
		if (hasSuperiorEdit) {
			eventName = "Edit WorkOrder Super";
			editable = true;
		}
		this.setState({ editable: editable, eventName: eventName });
	};

	componentDidMount() {
		this.getCompanies();
		let woID = this.props.history.location.woID;
		if (!woID) woID = new URLSearchParams(this.props.location.search).get("id");
		if (woID) {
			this.getWoData(woID);
		} else if (this.props.history.location.wo) {
			let trips = this.props.history.location.wo.trips.map((trip, index) => {
				return { ...trip, index: index };
			});
			this.setState({ wo: this.props.history.location.wo, trips: trips, confirmEdit: false }, () => {
				if (this.state.wo.endDate) {
					this.getRouteProjectLegs();
					this.setState({ editWO: true }, () => {
						if (this.state.wo.logs.length !== 0) {
							let logDict = {};
							for (let log of this.state.wo.logs) {
								let key = log.event.replaceAll(" ", "");
								logDict[`${key.charAt(0).toLowerCase()}${key.slice(1, key.length)}`] = true;
							}
							this.setState({ logs: logDict }, () => {
								this.updatePermission();
							});
						}
					});
				}
			});
		}
	}

	render() {
		const t = i18n.t;
		const headerFormatter = (column) => <div style={{ width: "150px", minWidth: "fit-content", padding: 2 }}>{column.text}</div>;
		const tripColumns = [
			{
				sort: false,
				align: "center",
				dataField: "number",
				headerAlign: "center",
				text: i18n.t("number"),
				hidden: true,
			},
			{
				align: "center",
				headerAlign: "center",
				dataField: "loadingDate",
				text: i18n.t("loadingDate"),
				headerFormatter: headerFormatter,
				style: (cell, trip) => {
					if (this.state.trips[trip.index] && this.state.trips[trip.index]["invalidLoadingDate"]) {
						return { borderColor: "white white red white" };
					}
				},
				formatter: (cell, trip, index) => {
					let loadingDate = moment(trip.loadingDate).format("YYYY-MM-DD");
					return (
						<Input
							fullWidth
							name={trip.index.toString()}
							id="loadingDate"
							type="date"
							defaultValue={loadingDate}
							onBlur={this.getTripDateValue}
							error={this.state.trips[trip.index] && this.state.trips[trip.index]["invalidLoadingDate"]}
						/>
					);
				},
			},
			{
				sort: false,
				align: "center",
				headerAlign: "center",
				dataField: "projectLeg",
				text: i18n.t("projectLeg"),
				headerFormatter: headerFormatter,
				formatter: (cell, trip, rowIndex) => {
					return (
						<Selector
							id="projectLeg"
							size="small"
							disabled={this.state.wo.logs.length !== 0}
							width={350}
							isObjectOption
							optionAccessor="legName"
							onChange={(e, v) => this.getTripCellValue(e, v, trip.index)}
							name={trip.index.toString()}
							defaultValue={trip.projectLegObj ? trip.projectLegObj : { legName: trip.projectLeg }}
							error={this.state.trips[trip.index] && this.state.trips[trip.index]["invalidProjectLeg"]}
							options={this.state.routeProjectLegs}
						/>
					);
				},
			},
			{
				sort: false,
				align: "center",
				headerAlign: "center",
				dataField: "loadedQTY",
				text: i18n.t("loadedQTY"),
				headerFormatter: headerFormatter,
				formatter: (cell, trip, index) => {
					return (
						<Input
							type="number"
							step={0.001}
							name={trip.index.toString()}
							id="loadedQTY"
							fullWidth
							defaultValue={cell || 0}
							onBlur={this.getTripCellValue}
						/>
					);
				},
			},
			{
				sort: false,
				editable: false,
				align: "center",
				dataField: "owner.fullName",
				headerAlign: "center",
				text: i18n.t("owner"),
				headerFormatter: headerFormatter,
				formatter: (cell, trip, rowIndex) => {
					return (
						<Selector
							id="owner"
							size="small"
							width={200}
							isObjectOption
							optionAccessor="fullName"
							onChange={(e, v) => this.getTripCellValue(e, v, trip.index)}
							name={trip.index.toString()}
							defaultValue={trip.ownerObj ? trip.ownerObj : cell ? { fullName: cell } : { fullName: "" }}
							error={this.state.trips[trip.index] && this.state.trips[trip.index]["invalidOwner"]}
							options={this.state.companies}
						/>
					);
				},
			},
			{
				sort: false,
				align: "center",
				headerAlign: "center",
				dataField: "deliveredQTY",
				text: i18n.t("deliveredQTY"),
				headerFormatter: headerFormatter,
				formatter: (cell, trip, index) => {
					return (
						<Input
							type="number"
							step={0.001}
							name={trip.index.toString()}
							id="deliveredQTY"
							fullWidth
							defaultValue={cell || 0}
							onBlur={this.getTripCellValue}
						/>
					);
				},
			},
			{
				align: "center",
				headerAlign: "center",
				dataField: "unLoadingDate",
				text: i18n.t("unLoadingDate"),
				headerFormatter: headerFormatter,
				style: (cell, trip) => {
					let tripIndex = this.state.trips.indexOf(trip);
					if (this.state.trips[tripIndex] && this.state.trips[tripIndex]["invalidUnLoadingDate"]) {
						return { borderColor: "white white red white" };
					}
				},
				formatter: (cell, trip, index) => {
					let unLoadingDate = moment(trip.unLoadingDate).format("YYYY-MM-DD");
					return (
						<Input
							name={trip.index.toString()}
							id="unLoadingDate"
							type="date"
							fullWidth
							defaultValue={unLoadingDate}
							onBlur={this.getTripDateValue}
							error={this.state.trips[trip.index]["invalidUnLoadingDate"]}
						/>
					);
				},
			},
			{
				sort: false,
				align: "center",
				headerAlign: "center",
				text: i18n.t("difference"),
				dataField: "difference",
				headerFormatter: headerFormatter,
				formatter: (cell, trip, index) => {
					let difference = trip["loadedQTY"] - trip["deliveredQTY"];
					if (isNaN(difference)) {
						difference = "-";
					}
					return (
						<Input
							type="number"
							step={0.001}
							value={cell || difference}
							fullWidth
							style={{
								color: difference !== 0 && "red",
							}}
							disabled
						/>
					);
				},
			},
			{
				sort: false,
				align: "center",
				dataField: "startKM",
				headerAlign: "center",
				text: i18n.t("startKM"),
				headerFormatter: headerFormatter,
				formatter: (cell, trip, index) => {
					return (
						<Input
							fullWidth
							type="number"
							step={0.1}
							name={trip.index.toString()}
							id="startKM"
							defaultValue={cell}
							onBlur={this.getTripCellValue}
						/>
					);
				},
			},
			{
				sort: false,
				align: "center",
				dataField: "endKM",
				headerAlign: "center",
				text: i18n.t("endKM"),
				headerFormatter: headerFormatter,
				formatter: (cell, trip, index) => {
					return (
						<Input
							type="number"
							step={0.1}
							name={trip.index.toString()}
							id="endKM"
							fullWidth
							defaultValue={cell}
							onBlur={this.getTripCellValue}
						/>
					);
				},
			},
			{
				sort: false,
				align: "center",
				headerAlign: "center",
				text: i18n.t("distance"),
				dataField: "distance",
				headerFormatter: headerFormatter,
				formatter: (cell, trip, index) => {
					let distance = Number(trip["endKM"] - trip["startKM"]).toFixed(2);
					return (
						<Input
							fullWidth
							type="number"
							step={0.01}
							value={cell || distance}
							style={{
								color: !(distance - trip["tripDistance"] >= -10 && distance - trip["tripDistance"] <= 10) && "red",
							}}
							disabled
						/>
					);
				},
			},
			{
				align: "center",
				dataField: "actions",
				headerAlign: "center",
				text: i18n.t("actions"),
				formatter: (cell, trip, index) => {
					return (
						<GetPermission perm="ops.delete_trip">
							<div code="perm">
								<DeleteIcon hidden={this.state.wo.logs.length !== 0} index={trip.index} ondelete={this.deleteTrip} />
							</div>
						</GetPermission>
					);
				},
			},
		];

		return (
			this.state.wo && (
				<>
					<PageTitleAlt2 heading={t("viewPlannedWO")} icon="bi bi-kanban icon-gradient bg-happy-fisher" />
					<LoadingOverlay
						tag="div"
						styles={{
							overlay: (base) => ({
								...base,
								background: "#fff",
								opacity: 0.5,
							}),
						}}
						active={this.state.formLoading}
						spinner={<Loader active color="#30b1ff" type="line-spin-fade-loader" />}
					>
						<Row>
							<Col xs="12">
								<WorkOrderCard
									wo={this.state.wo}
									edit={this.editWO}
									expensesForPrint={[]}
									addTrip={this.addTrip}
									logs={this.state.logs}
									close={this.closeWOModal}
									plan={this.state.wo.plan}
									createRFR={this.createRFR}
									viewPlan={this.viewWOPlan}
									editable={this.state.editable}
									initExpense={this.initExpense}
									getExpenses={this.getExpenses}
									onCancelEdit={this.onCancelEdit}
									onConfirmEdit={this.onConfirmEdit}
									confirmEdit={this.state.confirmEdit}
									mntcURL={process.env.NODE_ENV === "production" ? process.env.REACT_APP_MNTC_FE_URL : "http://localhost:3002/"}
								/>
							</Col>
							<Col>
								<WOCloseModal
									wo={this.state.wo}
									onSubmit={this.closeWO}
									editWO={this.state.editWO}
									toggle={this.closeWOModal}
									woTrips={this.state.trips}
									isOpen={this.state.closeWOModal}
									getValues={this.getCloseWOValues}
									closeWOData={this.state.closeWOData}
									validateCloseWO={this.validateCloseWO}
								/>
							</Col>

							{this.state.newRFR && (
								<Col xs="12" className="my-3">
									<Card>
										<CardBody>
											<NewRFR
												planned
												assets={
													this.state.wo.attachedAsset ? [this.state.wo.asset, this.state.wo.attachedAsset] : [this.state.wo.asset]
												}
												wo={this.state.wo}
												cancelRFR={this.cancelRFR}
												getWO={this.getWoData}
											/>
										</CardBody>
										<CardFooter className="d-flex justify-content-end">
											<Button color="dark" onClick={this.cancelRFR}>
												{t("cancel")}
											</Button>
										</CardFooter>
									</Card>
								</Col>
							)}
							<Col xs="12" hidden={this.state.newTrips.length === 0}>
								<Form onInvalid={this.validateNewTrip} onSubmit={this.submitTrip}>
									<Card className="main-card mb-3">
										<CardBody>
											<div className="dropdown-menu-header m-0">
												<div className="dropdown-menu-header-inner m-0 rounded bg-primary">
													<div className="menu-header-content">
														<h5 className="menu-header-title">{t("newTrips")}</h5>
													</div>
												</div>
											</div>
											<Row className="mt-2">
												{this.state.newTrips.map((trip, index) => {
													return (
														<Col xs="12" md="6" key={index}>
															<TripForm
																trip={trip}
																index={index}
																onDelete={this.removeTrip}
																getValue={this.getNewTripValue}
																companies={this.state.companies}
																woEndDate={this.state.wo.endDate}
																woStartDate={this.state.wo.startDate}
																projectLegs={this.state.routeProjectLegs}
																getSelectedValue={this.getNewTripSelectedValue}
															/>
														</Col>
													);
												})}
											</Row>
										</CardBody>
										<CardFooter className="d-flex justify-content-end">
											<GetPermission perm="ops.add_trip">
												<div code="perm">
													<Button color="info" onClick={this.addTrip}>
														{t("addTrip")}
													</Button>
													<Button className="ml-2" color="info" type="submit">
														{t("submit")}
													</Button>
												</div>
											</GetPermission>
										</CardFooter>
									</Card>
								</Form>
							</Col>
							<Col xs="12">
								<Card className="main-card mb-3">
									<CardBody>
										{this.state.companies.length !== 0 && this.state.routeProjectLegs.length !== 0 && (
											<ListNonPlannedWOTrips
												editable={this.state.editable}
												editWO={this.state.editWO}
												trips={this.state.trips}
												tripColumns={tripColumns}
												confirmEdit={this.state.confirmEdit}
												onUpdate={this.updateTrips}
												logs={this.state.wo.logs}
												onFill={this.onFill}
												loading={this.state.formLoading}
												logsState={this.state.logs}
											/>
										)}
									</CardBody>
								</Card>
							</Col>
							{this.state.initExpense && this.state.tripsExpenses.length !== 0 && (
								<Col xs="12">
									<Form onInvalid={this.validateTripRecipet} onSubmit={this.postExpenses}>
										<Card className="main-card mb-3">
											<CardBody>
												<div className="dropdown-menu-header ">
													<div className="dropdown-menu-header-inner rounded bg-primary ">
														<div className="menu-header-content ">
															<h5 className="menu-header-title">{t("tripsExpenses")}</h5>
														</div>
													</div>
												</div>
												<Row className="mt-2">
													{this.state.tripsExpenses.map((trip, index) => (
														<Col xs="12" className="mt-1" key={index}>
															<ExpenseForm
																trip={trip}
																tripIndex={index}
																getValue={this.getExpenseValue}
																expenses={this.expenses[index]}
																setScrol={this.setScrolPosition}
																tripData={this.state.trips[index]}
																onFocus={this.setExtraInfoActiveIndex}
																postExpenses={this.state.postExpenses}
																tripsReceipts={this.state.tripsReceipts}
																expensesTypes={this.state.expensesTypes}
																scrolPosition={this.state.scrolPosition}
																extraInfoActiveIndex={this.state.extraInfoActiveIndex}
																getLoadingReceiptAndCopounNos={this.getLoadingReceiptAndCopounNos}
																projectExpenseTypes={this.state.projectsExpenseTypes[trip.projectCode]}
															/>
														</Col>
													))}
												</Row>
											</CardBody>
											<CardFooter>
												<Col>
													<GetPermission perm="ops.add_expense">
														<Button code="perm" type="submit" color="info" className="btn-right float-right">
															{t("submit")}
														</Button>
													</GetPermission>
												</Col>
											</CardFooter>
										</Card>
									</Form>
								</Col>
							)}
							{this.state.expensesReviews.length !== 0 && (
								<Col xs="12">
									<Card className="main-card mb-3">
										<CardBody>
											<div className="dropdown-menu-header ">
												<div className="dropdown-menu-header-inner rounded bg-primary ">
													<div className="menu-header-content ">
														<h5 className="menu-header-title">{t("expensesReview")}</h5>
													</div>
												</div>
											</div>
											<Row className="mt-2">
												{this.state.expensesReviews.map((expense, index) => (
													<Col xs="12" className="mt-1" key={index}>
														<ReviewExpenseForm
															expense={expense}
															tripIndex={index}
															trips={this.state.trips}
															setScrol={this.setScrolPosition}
															getValue={this.getExpenseReviewValue}
															onFocus={this.setExtraInfoActiveIndex}
															scrolPosition={this.state.scrolPosition}
															getExpensesTotal={this.getExpensesTotal}
															extraInfoActiveIndex={this.state.extraInfoActiveIndex}
														/>
													</Col>
												))}
											</Row>
										</CardBody>
										<CardFooter>
											<Col>
												<GetPermission perm="ops.change_expense">
													<Button
														className="btn-right float-right"
														code="perm"
														type="button"
														onClick={this.submitExpenseReview}
														color="info"
														name={this.state.logs["expenseReview"] ? "Final Settlement" : "Expense Review"}
													>
														{this.state.logs["expenseReview"] ? t("submitFinalSettlement") : t("submitExpenseReview")}
													</Button>
												</GetPermission>
											</Col>
										</CardFooter>
									</Card>
								</Col>
							)}
						</Row>
					</LoadingOverlay>
				</>
			)
		);
	}
}

export default withRouter(ViewWO);
