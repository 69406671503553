import i18n from "i18n";
import { getCookie } from "cookies";
import HomeScene from "ops/home/scenes/home";
import AppHeader from "../../Layout/AppHeader";
import Report from "ops/reports/scenes/report";
import LocaleContext from "../../LocaleContext";
import NewLegScene from "ops/leg/scenes/new_leg";
import AppSidebar from "../../Layout/AppSidebar/";
import LoginScene from "../../login/scenes/login";
import React, { useState, Suspense } from "react";
import ListLegScene from "ops/leg/scenes/list_leg";
import NewPlanScene from "ops/plan/scenes/new_plan";
import ViewPlanScene from "ops/plan/scenes/view_plan";
import ListPlanScene from "ops/plan/scenes/list_plans";
import ListTripScene from "ops/trip/scenes/list_trips";
import UploadTripScene from "ops/trip/scenes/upload_trips";
import ViewDriverScene from "ops/driver/scenes/view_driver";
import NewProjectScene from "ops/project/scenes/new_project";
import ListDriverScene from "ops/driver/scenes/list_drivers";
import WORawDataReport from "ops/reports/scenes/wo_raw_data";
import { Route, Redirect, withRouter } from "react-router-dom";
import ViewProjectScene from "ops/project/scenes/view_project";
import ViewPlannedWOScene from "ops/planned_wo/scenes/view_wo";
import ListProjectScene from "ops/project/scenes/list_projects";
import SearchWoScene from "ops/non_planned_wo/scenes/search_wo";
import NewLocationScene from "ops/location/scenes/new_location";
import NewStandardScene from "ops/standard/scenes/new_standard";
import UploadGPSDataScene from "ops/trip/scenes/upload_gps_data";
import ListStandardScene from "ops/standard/scenes/list_standard";
import NewCommodityScene from "ops/commodity/scenes/new_commodity";
import NewNonPlannedWOScene from "ops/non_planned_wo/scenes/new_wo";
import ViewAssisstantScene from "ops/driver/scenes/view_assisstant";
import ListAssisstantScene from "ops/driver/scenes/list_assisstants";
import NewSiteOwnerScene from "ops/site_owner/scenes/new_site_owner";
import NewCostingScene from "ops/project_costing/scenes/new_costing";
import ListNonPlannedWOScene from "ops/non_planned_wo/scenes/list_wo";
import ListNonPlannedArchivedWOScene from "ops/non_planned_wo/scenes/list_archived_wo"
import ViewNonPlannedWOScene from "ops/non_planned_wo/scenes/view_wo";
import ListCostingScene from "ops/project_costing/scenes/list_costing";
import ViewCostingScene from "ops/project_costing/scenes/view_costing";
import ListSiteOwnerScene from "ops/site_owner/scenes/list_site_owner";
import ReplaceStandard from "ops/standard/components/replace_standard";
import ExpesesOrderScene from "ops/expenses_order/scenes/expenses_order";
import BulkCreateDriverScene from "ops/driver/scenes/bulk_create_driver";
import ListVendorTripScene from "ops/vendor_trip/scenes/list_vendor_trips";
import NewCommodityTypeScene from "ops/commodity/scenes/new_commodity_type";
import UploadVendorTripScene from "ops/vendor_trip/scenes/upload_vendor_trip";
import BulkCreateAssisstantScene from "ops/driver/scenes/bulk_create_assisstant";
import ListTestDriveRequestScene from "ops/test_drive_request/scenes/list_test_drive_request";
import ViewTestDriveRequestScene from "ops/test_drive_request/scenes/view_test_drive_request ";

const AppMain = (props) => {
	const cookie = getCookie("toki");
	const [locale, setLocale] = useState(i18n.language);
	i18n.on("languageChanged", () => setLocale(i18n.language));
	const url = new URLSearchParams(props.location.search).get("redirect");

	if (cookie && url) props.history.push("/home");

	if (cookie) {
		return (
			<LocaleContext.Provider value={{ locale, setLocale }}>
				<Suspense fallback={<div>Loading</div>}>
					<AppHeader />
					<div className="app-main">
						<AppSidebar />
						<div className="app-main__outer">
							<div className="app-main__inner">
								<Route path="/home" component={HomeScene} />
								<Route path="/login" component={LoginScene} />
								<Route path="/data-studio" component={Report} />
								<Route path="/newleg" component={NewLegScene} />
								<Route path="/newplan" component={NewPlanScene} />
								<Route path="/listlegs" component={ListLegScene} />
								<Route path="/viewplan" component={ViewPlanScene} />
								<Route path="/searchwo" component={SearchWoScene} />
								<Route path="/listtrips" component={ListTripScene} />
								<Route path="/listplans" component={ListPlanScene} />
								<Route path="/newproject" component={NewProjectScene} />
								<Route path="/viewdriver" component={ViewDriverScene} />
								<Route path="/newcosting" component={NewCostingScene} />
								<Route path="/wo-raw-data" component={WORawDataReport} />
								<Route path="/listdrivers" component={ListDriverScene} />
								<Route path="/newlocation" component={NewLocationScene} />
								<Route path="/newstandard" component={NewStandardScene} />
								<Route path="/viewproject" component={ViewProjectScene} />
								<Route path="/viewcosting" component={ViewCostingScene} />
								<Route path="/upload-trips" component={UploadTripScene} />
								<Route path="/listprojects" component={ListProjectScene} />
								<Route path="/listcostings" component={ListCostingScene} />
								<Route path="/liststandard" component={ListStandardScene} />
								<Route path="/new-commodity" component={NewCommodityScene} />
								<Route path="/expensesorder" component={ExpesesOrderScene} />
								<Route path="/uploadgpsdata" component={UploadGPSDataScene} />
								<Route path="/replace-standard" component={ReplaceStandard} />
								<Route path="/newdrivers" component={BulkCreateDriverScene} />
								<Route path="/viewplannedwo" component={ViewPlannedWOScene} />
								<Route path="/newworkorder" component={NewNonPlannedWOScene} />
								<Route exact path="/" render={() => <Redirect to="/home" />} />
								<Route path="/viewworkorder" component={ViewNonPlannedWOScene} />
								<Route path="/listvendortrips" component={ListVendorTripScene} />
								<Route path="/view-assisstant" component={ViewAssisstantScene} />
								<Route path="/list-assisstants" component={ListAssisstantScene} />
								<Route path="/listworkorders" component={ListNonPlannedWOScene} />
								<Route path="/listarchivedworkorders" component={ListNonPlannedArchivedWOScene} />
								<Route path="/uploadvendortrip" component={UploadVendorTripScene} />
								<Route path="/new-commodity-type" component={NewCommodityTypeScene} />
								<Route path="/new-siteowner-customer" component={NewSiteOwnerScene} />
								<Route path="/new-assisstants" component={BulkCreateAssisstantScene} />
								<Route path="/list-siteowner-customer" component={ListSiteOwnerScene} />
								<Route path="/list-test-drive-request" component={ListTestDriveRequestScene} />
								<Route path="/view-test-drive-request" component={ViewTestDriveRequestScene} />
							</div>
						</div>
					</div>
				</Suspense>
			</LocaleContext.Provider>
		);
	} else {
		return (
			<div className="app-main__inner">
				<Route path="/" component={LoginScene} />
			</div>
		);
	}
};
export default withRouter(AppMain);
