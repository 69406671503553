import i18n from "i18n";
import axios from "axios";
import moment from "moment";
import Loader from "react-loaders";
import React, { Component } from "react";
import Selector from "ops/common/selector";
import ViewIcon from "ops/common/view_icon";
import { withRouter } from "react-router-dom";
import DeleteIcon from "ops/common/delete_icon";
import DeletePopup from "ops/common/delete_popup";
import LoadingOverlay from "react-loading-overlay";
import { CancelIcon } from "ops/common/cancel_icon";
import PageTitleAlt2 from "ops/common/PageTitleAlt2";
import GetPermission from "ops/common/get_permission";
import BootstrapTable from "react-bootstrap-table-next";
import DateRangePicker from "ops/common/date_range_picker";
import { AlertNotification } from "ops/common/alert_notification";
import { Button, Card, CardBody, Col, Input, Label, Row } from "reactstrap";
import TableWithPaginationSearch from "ops/common/table_with_pagination_search";

class SearchWo extends Component {
	constructor() {
		super();
		this.state = {
			wos: null,
			quary: "",
			loading: false,
			hasPlan: false,
			hasProject: false,
			workOrder: false,
			drivers: [],
			projects: [],
			instances:[],
			clearDates: false,
			startDate: null,
			attachedAssets: [],
			assets: [],
			endDate: null,
			hiddenRows: [],
			selectedDriver: null,
			selectedAsset: null,
			selectedProject: null,
			selectedAttachedAsset: null,
			filterOptions: [
				{ id: 0, name: "All" },
				{ id: 1, name: "Active" },
				{ id: 2, name: "Closed" },
				{ id: 3, name: "Initial Settelment" },
				{ id: 4, name: "Final Settlement" },
			],
		};
	}

	filter = (e) => {
		this.setState({ loading: true });
		let hiddenRows = [];
		let criteria = e.target.value;
		if (criteria === "All") this.setState({ hiddenRows: hiddenRows, loading: false });
		else
			this.state.wos.forEach((wo) => {
				if (wo.status !== criteria) hiddenRows.push(wo.id);
			});
		this.setState({ hiddenRows: hiddenRows, loading: false });
	};

	getProjects = () => {
		let url = "";
		if (this.state.instances.length === 1) {
			url = `&instance=${this.state.instances[0].id}`;
		} else if (this.state.instances.length > 1) {
			if (this.state.selectedInstance) {
				url = `&instance=${this.state.selectedInstance}`
			}
		}
		axios.get(`project/?serializer=id`+url).then((res) => {
			if (res) this.setState({ projects: res.data });
		});
	};
	
	getInstances = () => {
		axios.get(`profile/userinstances`).then((res) => {
			this.setState({ instances: res.data });
		})
	};

	filterByInstance = (e, v) => {
		if (v) {
			this.setState({ selectedInstance: v.id });
		} else {
			this.setState({selectedInstance: null});
		}
	};

	getDrivers = () => {
		axios
			.get(`profile/?serializer=driver&departments__name=Drivers`)
			.then((res) => {
				if (res) this.setState({ drivers: res.data });
			})
			.catch((err) => {
				console.error(err.response);
				AlertNotification(null, "error");
			});
	};

	getAssets = () => {
		axios
			.get(`asset/?serializer=minimum&assetType__name__ne=Trailer`)
			.then((res) => {
				this.setState({ assets: res.data });
			})
			.catch((err) => {
				console.error(err.response);
				AlertNotification(null, "error");
			});
	};

	getAtatchedAssets = () => {
		axios
			.get(`asset/?serializer=minimum&assetType__name=Trailer`)
			.then((res) => {
				this.setState({ attachedAssets: res.data });
			})
			.catch((err) => {
				console.error(err.response);
				AlertNotification(null, "error");
			});
	};

	getSelectedValue = (e, v) => {
		let target = e.target.id.split("-")[0];
		this.setState({ [`selected${target.charAt(0).toUpperCase()}${target.slice(1, target.length)}`]: v });
	};

	getWOType = (e) => {
		let target = e.target.name;
		this.setState((prevState) => {
			return { [target]: !prevState[target], wos: null };
		});
	};

	getSearchQuery = (e) => {
		this.setState({ quary: e.target.value });
	};

	getDates = (sDate, eDate) => {
		this.setState({ startDate: sDate, endDate: eDate });
	};

	search = () => {
		if (!this.state.hasPlan && !this.state.hasProject && !this.state.workOrder)
			return AlertNotification(i18n.t("selectFilterationCriteria"), "error");
		let selectedAssetQuery = this.state.selectedAsset && "&asset__id=" + this.state.selectedAsset.id;
		let selectedDriverQuery = this.state.selectedDriver && "&driver__id=" + this.state.selectedDriver.user;
		let selectedProjectQuery = this.state.selectedProject && "&project__id=" + this.state.selectedProject.id;
		let selectedAttachecAssetQuery =
			this.state.selectedAttachedAsset && "&attachedAsset__id=" + this.state.selectedAttachedAsset.id;
		let startDateQuery = this.state.startDate && "&trips__loadingDate__gte=" + this.state.startDate;
		let endDateQuery = this.state.endDate && "&trips__loadingDate__lte=" + this.state.endDate;
		let searchQueries =
			(selectedAssetQuery ? selectedAssetQuery : "") +
			(selectedDriverQuery ? selectedDriverQuery : "") +
			(selectedProjectQuery ? selectedProjectQuery : "") +
			(selectedAttachecAssetQuery ? selectedAttachecAssetQuery : "") +
			(startDateQuery ? startDateQuery : "") +
			(endDateQuery ? endDateQuery : "");

		const { instances, selectedInstance } = this.state;
		// Use selected instance ID if it exists, otherwise check if there's only one instance
		let instanceFilter = selectedInstance 
			? `&instance=${selectedInstance}`
			: instances.length === 1 ? `&instance=${instances[0].id}` : "";

		this.setState({ loading: true });
		axios
			.get(
				`workorder/search-work-orders/?plan__isnull=${!this.state.hasPlan}&project__isnull=${!this.state
					.hasProject}${searchQueries}&search=${this.state.quary}&isArchived=0${instanceFilter}`
			)
			.then((res) => {
				this.setState({
					wos: res.data,
					loading: false,
				});
			})
			.catch((err) => {
				console.error(err.response.data);
				this.setState({ loading: false });
			});
	};

	confirmDeleteWorkOrder = (workorder) => {
		return DeletePopup({
			title: "Are you sure ?",
			cancelbtntxt: "No",
			confirmbtntxt: "Yes",
			text: `This will delete Work Order ${workorder.code}`,
			confirmedresult: () => this.deleteWorkOrder(workorder.id),
		});
	};

	deleteWorkOrder = (workorderID) => {
		this.setState({ loading: true });
		axios
			.delete(`workorder/${workorderID}`)
			.then((res) => {
				if (res) {
					AlertNotification(i18n.t("deleted"), "success");
					let workorders = [...this.state.wos].filter((workorder) => workorder.id !== workorderID);
					this.setState({ wos: workorders }, () => {
						this.setState({ loading: false });
					});
				}
			})
			.catch((err) => {
				console.error(err.response);
				this.setState({ loading: false });
				AlertNotification(i18n.t("errorMessage"), "error");
			});
	};

	clearSearchCriteria = (e) => {
		this.setState({
			wos: null,
			quary: "",
			loading: false,
			hasPlan: false,
			hasProject: false,
			workOrder: false,
			startDate: null,
			endDate: null,
			clearDates: !this.state.clearDates,
			selectedDriver: null,
			selectedAsset: null,
			selectedProject: null,
			selectedAttachedAsset: null,
		});
	};

	componentDidMount() {
		this.getAssets();
		this.getDrivers();
		this.getProjects();
		this.getAtatchedAssets();
		this.getInstances();
	}

	render() {
		const t = i18n.t;
		const woColumns = [
			this.state.instances.length > 1 && {
				sort: true,
				align: "center",
				headerAlign: "center",
				dataField: "instance",
				text: t("company"),
			},
			{
				sort: true,
				align: "center",
				text: t("code"),
				dataField: "code",
				headerAlign: "center",
			},
			{
				sort: true,
				align: "center",
				text: t("status"),
				dataField: "status",
				headerAlign: "center",
			},
			{
				sort: true,
				align: "center",
				headerAlign: "center",
				dataField: "project.code",
				text: i18n.t("projectCode"),
				hidden: !this.state.hasProject,
				csvExport: this.state.hasProject,
			},
			{
				sort: true,
				align: "center",
				headerAlign: "center",
				hidden: !this.state.hasProject,
				dataField: "project.description",
				csvExport: this.state.hasProject,
				text: i18n.t("projectDescription"),
			},
			{
				sort: true,
				align: "center",
				text: t("startDate"),
				headerAlign: "center",
				dataField: "startDate",
			},
			{
				sort: true,
				align: "center",
				text: t("endDate"),
				dataField: "endDate",
				headerAlign: "center",
			},
			{
				sort: true,
				align: "center",
				headerAlign: "center",
				text: i18n.t("route"),
				dataField: "route.name",
				hidden: !this.state.hasPlan,
				csvExport: this.state.hasPlan,
			},
			{
				sort: true,
				hidden: true,
				align: "center",
				headerAlign: "center",
				text: t("serviceType"),
				dataField: "serviceType",
			},
			{
				sort: true,
				align: "center",
				text: t("startKM"),
				dataField: "startKM",
				headerAlign: "center",
				formatter: (cell) => Number(cell).toLocaleString(),
			},
			{
				sort: true,
				align: "center",
				text: t("endKM"),
				dataField: "endKM",
				headerAlign: "center",
				formatter: (cell) => {
					if (cell) return Number(cell).toLocaleString();
				},
			},
			{
				sort: true,
				align: "center",
				text: t("driver"),
				dataField: "driver",
				headerAlign: "center",
			},
			{
				sort: true,
				hidden: true,
				align: "center",
				csvExport: false,
				text: t("assistant"),
				headerAlign: "center",
				dataField: "assisstant",
			},
			{
				sort: true,
				align: "center",
				text: t("asset"),
				headerAlign: "center",
				dataField: "asset.code",
			},
			{
				sort: true,
				align: "center",
				headerAlign: "center",
				text: t("attachedAsset"),
				dataField: "attachedAsset.code",
			},
			{
				sort: true,
				align: "center",
				text: t("tripsCount"),
				headerAlign: "center",
				dataField: "tripsCount",
				formatter: (cell, row) => {
					return row.trips.length || 0;
				},
			},
			{
				align: "center",
				csvExport: false,
				text: t("actions"),
				dataField: "actions",
				headerAlign: "center",
				formatter: (cell, wo) => {
					if (this.state.hasPlan) {
						return (
							<>
								<GetPermission perm="ops.view_workorder">
									<ViewIcon code="perm" target="_blank" to={`/viewplannedwo/?id=${wo.id}`} />
								</GetPermission>
								<GetPermission perm="ops.delete_workorder">
									<div code="perm">
										<DeleteIcon ondelete={() => this.confirmDeleteWorkOrder(wo)} hidden={!wo.deletable} />
									</div>
								</GetPermission>
							</>
						);
					} else {
						return (
							<>
								<GetPermission perm="ops.view_workorder">
									<ViewIcon code="perm" target="_blank" to={`/viewworkorder/?id=${wo.id}`} />
								</GetPermission>
								<GetPermission perm="ops.delete_workorder">
									<div code="perm">
										<DeleteIcon ondelete={() => this.confirmDeleteWorkOrder(wo)} hidden={!wo.deletable} />
									</div>
								</GetPermission>
							</>
						);
					}
				},
			},
		];
		const headerFormatter = (column) => <div className="info-header">{column.text}</div>;
		const tripsColumns = [
			{
				sort: true,
				align: "center",
				dataField: "number",
				headerAlign: "center",
				text: i18n.t("number"),
				headerFormatter: headerFormatter,
			},
			{
				sort: true,
				align: "center",
				dataField: "startKM",
				headerAlign: "center",
				text: i18n.t("startKM"),
				headerFormatter: headerFormatter,
				formatter: (cell) => Number(cell).toLocaleString(),
			},
			{
				sort: true,
				align: "center",
				dataField: "endKM",
				headerAlign: "center",
				text: i18n.t("endKM"),
				headerFormatter: headerFormatter,
				formatter: (cell) => Number(cell).toLocaleString(),
			},
			{
				sort: true,
				align: "center",
				headerAlign: "center",
				dataField: "distance",
				text: i18n.t("distance"),
				formatter: (cell, trip, index) => {
					let distance = trip["endKM"] - trip["startKM"];
					return Number(distance).toLocaleString();
				},
				style: (cell, trip) => {
					let distance = trip["endKM"] - trip["startKM"];
					if (!(distance - trip["tripDistance"] >= -10 && distance - trip["tripDistance"] <= 10)) {
						return { color: "red" };
					}
				},
				headerFormatter: headerFormatter,
			},

			{
				sort: true,
				align: "center",
				headerAlign: "center",
				dataField: "loadedQTY",
				text: i18n.t("loadedQTY"),
				headerFormatter: headerFormatter,
				formatter: (cell) => Number(cell).toLocaleString(),
			},
			{
				sort: true,
				align: "center",
				headerAlign: "center",
				dataField: "deliveredQTY",
				text: i18n.t("deliveredQTY"),
				headerFormatter: headerFormatter,
				formatter: (cell) => Number(cell).toLocaleString(),
			},
			{
				sort: true,
				align: "center",
				headerAlign: "center",
				dataField: "difference",
				text: i18n.t("difference"),
				formatter: (cell, trip, index) => {
					let difference = trip["loadedQTY"] - trip["deliveredQTY"];
					if (isNaN(difference)) {
						return "-";
					}
					return Number(difference).toLocaleString();
				},
				style: (cell, trip) => {
					let difference = trip["loadedQTY"] - trip["deliveredQTY"];
					if (Math.sign(difference) === -1) {
						return { color: "red" };
					}
				},
				headerFormatter: headerFormatter,
			},
			{
				sort: true,
				align: "center",
				headerAlign: "center",
				dataField: "loadingDate",
				text: i18n.t("loadingDate"),
				headerFormatter: headerFormatter,
				formatter: (cell) => {
					if (cell) {
						return moment(cell, "YYYY-MM-DDTHH:mm").format("DD-MM-YYYY hh:mm A");
					} else return cell;
				},
			},
			{
				sort: true,
				align: "center",
				headerAlign: "center",
				dataField: "unLoadingDate",
				text: i18n.t("unLoadingDate"),
				headerFormatter: headerFormatter,
				formatter: (cell) => {
					if (cell) {
						return moment(cell, "YYYY-MM-DDTHH:mm").format("DD-MM-YYYY hh:mm A");
					} else return cell;
				},
			},

			{
				sort: true,
				align: "center",
				headerAlign: "center",
				dataField: "projectleg",
				text: i18n.t("projectLeg"),
				formatter: (cell, trip, index) => {
					if (trip.projectLeg) {
						return trip.projectLeg;
					} else {
						return trip.leg;
					}
				},
				headerFormatter: headerFormatter,
			},
			{
				align: "center",
				dataField: "owner.fullName",
				headerAlign: "center",
				text: i18n.t("owner"),
				headerFormatter: headerFormatter,
			},
		];
		const expandRow = {
			renderer: (row) => <BootstrapTable keyField="id" condensed={true} data={row.trips} columns={tripsColumns} />,
		};
		return (
			<>
				<PageTitleAlt2 heading={t("searchWO")} icon="bi bi-hdd-network icon-gradient bg-happy-fisher" />
				<LoadingOverlay
					tag="div"
					styles={{
						overlay: (base) => ({
							...base,
							background: "#fff",
							opacity: 0.5,
						}),
					}}
					active={this.state.loading}
					spinner={<Loader active color="#30b1ff" type="line-spin-fade-loader" />}>
					<Row>
						<Col>
							<Card>
								<CardBody>
									<Row>
										<Col className={(this.state.hasProject || this.state.hasPlan) && "text-muted"}>
											<div>
												<Label className="mr-4">{t("workOrder")}</Label>
												<Input
													type="checkbox"
													name="workOrder"
													id="workOrder"
													checked={this.state.workOrder}
													onChange={this.getWOType}
													disabled={this.state.hasProject || this.state.hasPlan}
												/>
											</div>
										</Col>
										<Col className={(this.state.hasProject || this.state.workOrder) && "text-muted"}>
											<div>
												<Label className="mr-4">{t("hasPlan")}</Label>
												<Input
													type="checkbox"
													name="hasPlan"
													id="hasPlan"
													checked={this.state.hasPlan}
													onChange={this.getWOType}
													disabled={this.state.hasProject || this.state.workOrder}
												/>
											</div>
										</Col>
										<Col className={(this.state.hasPlan || this.state.workOrder) && "text-muted"}>
											<div>
												<Label className="mr-4">{t("hasProject")}</Label>
												<Input
													type="checkbox"
													name="hasProject"
													id="hasProject"
													checked={this.state.hasProject}
													onChange={this.getWOType}
													disabled={this.state.hasPlan || this.state.workOrder}
												/>
											</div>
										</Col>
									</Row>
									<Row className="mb-2 mt-2">
										<Col xs="12" md="6">
											<DateRangePicker
												clearDates
												clear={this.state.clearDates}
												getdata={this.getDates}
												startDate={t("loadingDateStart")}
												endDate={t("loadingDateEnd")}
											/>
										</Col>
										{
											this.state.instances.length > 1 &&
											<Col xs="3">
												<Label>{t("company")}</Label>
												<Selector
													clearable
													id="instanceFilter"
													size="small"
													isObjectOption
													optionAccessor="name"
													options={this.state.instances}
													onChange={this.filterByInstance}
												/>
											</Col>
										}
									</Row>
									<Row>
										<Col xs="12" lg="3" className="d-flex">
											<Label className="mr-1 p-1">{t("project")}</Label>
											<Selector
												options={this.state.projects}
												isObjectOption
												optionAccessor="code"
												onChange={this.getSelectedValue}
												id="project"
												size="small"
												width={180}
												getOptionLabel
												value={this.state.selectedProject && this.state.selectedProject}
											/>
										</Col>
										<Col xs="12" lg="3" className="d-flex">
											<Label className="mr-1 p-1">{t("driver")}</Label>
											<Selector
												options={this.state.drivers}
												isObjectOption
												optionAccessor="driverName"
												onChange={this.getSelectedValue}
												id="driver"
												size="small"
												width={180}
												getOptionLabel
												value={this.state.selectedDriver && this.state.selectedDriver}
											/>
										</Col>
										<Col xs="12" lg="3" className="d-flex">
											<Label className="mr-1 p-1">{t("asset")}</Label>
											<Selector
												options={this.state.assets}
												isObjectOption
												optionAccessor="code"
												onChange={this.getSelectedValue}
												id="asset"
												size="small"
												width={180}
												getOptionLabel
												value={this.state.selectedAsset && this.state.selectedAsset}
											/>
										</Col>
										<Col xs="12" lg="3" className="d-flex">
											<Label className="mr-1 p-1">{t("attachedAsset")}</Label>
											<Selector
												options={this.state.attachedAssets}
												isObjectOption
												optionAccessor="code"
												onChange={this.getSelectedValue}
												id="attachedAsset"
												size="small"
												width={180}
												getOptionLabel
												value={this.state.selectedAttachedAsset && this.state.selectedAttachedAsset}
											/>
										</Col>
									</Row>
									<Row className="my-4">
										<Col xs="12" sm="12" md="6" lg="4" className="d-flex " style={{ maxHeight: "38px" }}>
											<Input type="text" name="search" onChange={this.getSearchQuery} value={this.state.quary} />
											<Button className="mx-1" color="info" onClick={this.search}>
												{t("search")}
											</Button>
											<CancelIcon onHoverColor="danger" iconClassName="btn  mb-3" onCancel={this.clearSearchCriteria} />
										</Col>
									</Row>
								</CardBody>
							</Card>
						</Col>
					</Row>
					{this.state.wos && (
						<Row className="my-4">
							<Col xs="12">
								<Card className="main-card mb-3" key="0">
									<CardBody>
										<TableWithPaginationSearch
											search
											scrollable
											exportable
											keyfield="id"
											columns={woColumns}
											data={this.state.wos}
											expandRow={expandRow}
											filename="workorders.csv"
											onSelectFilter={this.filter}
											loading={this.state.loading}
											hiddenRows={this.state.hiddenRows}
											filtersOptions={this.state.filterOptions}
											filterTitle={this.state.filterOptions[0].name}
										/>
									</CardBody>
								</Card>
							</Col>
						</Row>
					)}
				</LoadingOverlay>
			</>
		);
	}
}

export default withRouter(SearchWo);
