import i18n from "i18n";
import axios from "axios";
import { odooAxios } from "axios_interceptors";
import moment from "moment";
import NewRFR from "./new_rfr";
import Loader from "react-loaders";
import TripForm from "./trip_form";
import WorkOrderCard from "./wo_card";
import ExpenseForm from "./expense_form";
import { Input } from "@material-ui/core";
import Selector from "ops/common/selector";
import WOCloseModal from "./wo_close_modal";
import { withRouter } from "react-router-dom";
import { authAxios } from "axios_interceptors";
import ListNonPlannedWOTrips from "./list_trip";
import DeleteIcon from "ops/common/delete_icon";
import LoadingOverlay from "react-loading-overlay";
import React, { Component, Fragment } from "react";
import PageTitleAlt2 from "ops/common/PageTitleAlt2";
import ReviewExpenseForm from "./review_expense_form";
import GetPermission from "ops/common/get_permission";
import PrintableExpenseForm from "./printable_expenses";
import { AlertNotification } from "ops/common/alert_notification";
import { Button, Card, CardBody, CardFooter, Col, Form, Row } from "reactstrap";
import ArchivePopup from "ops/common/archive_popup";
import { encryptData } from '../../common/encryption_service';

class ViewNonPlannedWO extends Component {
	constructor(props) {
		super(props);
		this.state = {
			instances: [],
			logs: {},
			wo: null,
			legs: [],
			plan: null,
			woTrips: [],
			newTrips: [],
			newRFR: null,
			editWO: false,
			eventName: "",
			companies: [],
			editable: false,
			projectLegs: [],
			closeWOData: {},
			scrolPosition: 0,
			updatedTrips: [],
			tripsExpenses: [],
			tripsReceipts: [],
			expensesTypes: [],
			confirmEdit: false,
			initExpense: false,
			formLoading: false,
			closeWOModal: false,
			postExpenses: false,
			expensesReviews: [],
			modifiedExpenses: [],
			expensesForPrint: [],
			endKMEditIndex: null,
			grandTotalExpenses: 0,
			startKMEditIndex: null,
			totalReviewedExpense: 0,
			indexOfEditingCell: null,
			projectExpenseTypes: null,
			allTripsHaveExpenses: null,
			extraInfoActiveIndex: null,
			newTripEndKMEditIndex: null,
			newTripStartKMEditIndex: null,
			tripsUpdateBtnNotClicked: true,
		};
		this.expenses = [];
		this.expensesCount = 0;
	}

	setExtraInfoActiveIndex = (index) => {
		this.setState({ extraInfoActiveIndex: index });
	};

	setScrolPosition = (scrol) => {
		this.setState({ scrolPosition: scrol.scrollLeft });
	};

	getRouteProjectLegs = () => {
		axios
			.get(`route/${this.state.wo.route.id}/routeleg`)
			.then((res) => {
				if (res) this.setState({ projectLegs: res.data });
			})
			.catch((err) => {
				if (err) {
					AlertNotification(null, "error", err.response.status);
					console.error(err.response);
				}
			});
	};

	getWO = (woID) => {
		axios.get(`workorder/${woID}/?serializer=get`).then((res) => {
			let trips = res.data.trips.map((trip, index) => {
				return { ...trip, index: index };
			});
			this.setState(
				{
					wo: res.data,
					woTrips: trips,
					confirmEdit: false,
				},
				() => {
					if (this.state.wo.route) {
						this.getRouteProjectLegs();
					} else if (this.state.wo.project) {
						this.getProjectLegs();
					} else {
						this.getLegs();
					}
					if (this.state.wo.endDate) {
						this.setState({ editWO: true }, () => {
							if (this.state.wo.logs.length !== 0) {
								let logDict = {};
								for (let log of this.state.wo.logs) {
									let key = log.event.replaceAll(" ", "");
									logDict[`${key.charAt(0).toLowerCase()}${key.slice(1, key.length)}`] = true;
								}
								this.setState({ logs: logDict }, () => {
									this.updatePermission();
								});
							}
						});
					}
				}
			);
		});
		if (this.props.history.location.planID) {
			this.setState({ plan: this.props.history.location.planID });
		}
	};

	getLegs = () => {
		axios.get(`leg`).then((res) => {
			this.setState({ legs: res.data });
		});
	};

	getProjectLegs = () => {
		axios
			.get(`projectleg/?serializer=wo&standard__isArchive=false&project_id__code=${this.state.wo.project.code}`)
			.then((res) => {
				if (res && res.data.length !== 0) {
					this.setState({ projectLegs: res.data });
				} else {
					this.getLegs();
				}
			})
			.catch((err) => {
				console.error(err.response);
			});
	};

	getCompanies = () => {
		axios
			.get("company/")
			.then((res) => {
				if (res) this.setState({ companies: res.data });
			})
			.catch((err) => {
				if (err) {
					AlertNotification(null, "error", err.response.status);
					console.error(err.response);
				}
			});
	};

	closeWO = (e) => {
		e.preventDefault();
		let closeWOData = { ...this.state.closeWOData };
		if (closeWOData["invalidEndKMExceedMin"] || !closeWOData["endDate"] || !closeWOData["startDate"]) {
			return false;
		}
		if (this.state.woTrips.length > 0) {
			let endDate = moment(closeWOData.endDate).format("YYYY-MM-DD");
			let lastUnloadingDate = moment(this.state.woTrips[this.state.woTrips.length - 1].unLoadingDate).format("YYYY-MM-DD");
			if (moment(endDate).isBefore(lastUnloadingDate, "day")) {
				this.invalidate("endDate", null, true);
				return false;
			}
		}
		if (this.state.editWO) {
			this.setState({ confirmEdit: true, closeWOModal: false });
		} else {
			axios
				.put(`workorder/close/`, { id: this.state.wo.id, closeWOData: closeWOData })
				.then(async (res) => {
					if (res) {
						const odooStatus = {
							woCode: this.state.wo.code,
							woStatus: "Closed"
						}
						const { encryptedData, authTag, iv } = await encryptData(odooStatus);
						const encryptedObject = {
							encrypted_data: encryptedData,
							tag: authTag,
							iv,
						}
						// update wo status in odoo
						this.sendDataToOdoo('update_opwo', encryptedObject);
						this.setState({ wo: { ...this.state.wo, ...this.state.closeWOData }, closeWOModal: false });
						AlertNotification(i18n.t("closed"), "success");
					}
				})
				.catch((err) => {
					if (err) {
						console.error(err.response);
						AlertNotification(null, "error", err.response.status);
					}
				});
		}
	};

	deleteTrip = (e) => {
		let index = e.target.getAttribute("index");
		this.setState({ formLoading: true });
		axios
			.delete(`trip/${this.state.woTrips[index].id}/`)
			.then(async (res) => {
				if (res) {
					// prepare required trip data to delete the trip from odoo
					const odooData = {
						tripID: String(this.state.wo.code ) + "-" + String(this.state.woTrips[index].number)
					}
					const { encryptedData, authTag, iv } = await encryptData(odooData);
					const encryptedObject = {
						encrypted_data: encryptedData,
						tag: authTag,
						iv,
					}
					this.sendDataToOdoo(`delete_trip`, encryptedObject);
					this.getUpdatedTrips("&ordering=loadingDate");
					AlertNotification(i18n.t("deleted"), "success");
					if (this.state.initExpense) this.initExpense();
					this.setState({ formLoading: false });
				}
			})
			.catch((err) => {
				if (err) {
					console.error(err.response);
					AlertNotification(null, "error", err.response.status);
					this.setState({ formLoading: false });
				}
			});
	};

	addNewTrip = () => {
		if (this.state.legs.length === 0 && this.state.projectLegs.length === 0) {
			if (this.state.wo.project) {
				this.getProjectLegs();
			} else if (this.state.wo.route) {
				this.getRouteProjectLegs();
			} else {
				this.getLegs();
			}
		}
		if (this.state.companies.length === 0) this.getCompanies();
		let startKM = 0;
		if (this.state.woTrips.length === 0 && this.state.newTrips.length === 0) startKM = this.state.wo.startKM;
		this.setState({
			newTrips: [
				...this.state.newTrips,
				{
					workOrder: this.state.wo.id,
					startKM: startKM,
					endKM: 0,
					loadedQTY: 0,
					deliveredQTY: 0,
					loadingDate: moment().format("YYYY-MM-DDTHH:mm"),
					unLoadingDate: moment().format("YYYY-MM-DDTHH:mm"),
					instance: this.state.wo.instance['id'],
				},
			],
		});
	};

	removeNewTrip = (e) => {
		let tripIndex = e.target.getAttribute("index");
		this.setState((prevState) => {
			prevState.newTrips.splice(tripIndex, 1);
			return { ...prevState, newTrips: prevState.newTrips };
		});
	};

	getNewTripValue = (e, v, index) => {
		let target;
		let value;
		let tripIndex;
		let newTrips = this.state.newTrips;
		if (v) {
			target = e.target.id.split("-")[0];
			tripIndex = index;
			value = v.id;
			newTrips[tripIndex][`${target}Object`] = v;
		} else {
			target = e.target.id;
			tripIndex = e.target.getAttribute("index");
			value = e.target.value;
			if (e.target.id.includes("Date")) {
				value = moment(value + moment().format("THH:mm")).format("YYYY-MM-DDTHH:mm");
			}
			if (target === "startKM") this.setState({ newTripStartKMEditIndex: tripIndex });
			if (target === "endKM") this.setState({ newTripEndKMEditIndex: tripIndex });
		}
		newTrips[tripIndex][target] = value;
		newTrips[tripIndex][`invalid${target.charAt(0).toUpperCase()}${target.slice(1, target.length)}`] = false;

		/////////////////////////////////////////////////////////////////////////
		// This code related to trip sequance validation which commented untill implenting settings feature
		// if (target === "leg" || target === "projectLeg") {
		// 	if (tripIndex !== 0) {
		// 		let prevTrip = newTrips[tripIndex - 1];
		// 		if (
		// 			prevTrip[`${target}Object`].destination.toLowerCase() !== newTrips[tripIndex][`${target}Object`].source.toLowerCase()
		// 		) {
		// 			newTrips[tripIndex][`invalid${target.charAt(0).toUpperCase()}${target.slice(1, target.length)}`] = true;
		// 		}
		// 	} else {
		// 		if (this.state.woTrips.length !== 0) {
		// 			let prevTrip = this.state.woTrips[this.state.woTrips.length - 1];
		// 			if (prevTrip.destination.toLowerCase() !== newTrips[tripIndex][`${target}Object`].source.toLowerCase()) {
		// 				newTrips[tripIndex][`invalid${target.charAt(0).toUpperCase()}${target.slice(1, target.length)}`] = true;
		// 			}
		// 		}
		// 	}
		// }
		/////////////////////////////////////////////////////////////////////////

		this.setState({ newTrips: newTrips });
	};

	validateNewTrip = (e) => {
		let target = e.target.id.split("-")[0];
		let tripIndex = e.target.name;
		let newTrips = this.state.newTrips;
		newTrips[tripIndex][`invalid${target.charAt(0).toUpperCase()}${target.slice(1, target.length)}`] = true;
		this.setState({ newTrips: newTrips });
	};

	handleBeforeUnload = (event) => {
		event.preventDefault();
		event.returnValue = "";
	};

	// used to send any required data to odoo by passing the url and the data
	sendDataToOdoo = async (odooURL, odooData) => {
		try {
			const odooResponse = await odooAxios.post(odooURL, odooData, {
				headers: {
					'Content-Type': 'application/json',
				},
			});
			console.log("Data successfully sent to Odoo:", odooResponse.data.result);
			return true;
		} catch (odooError) {
			console.error("Failed to send data to Odoo:", odooError);
			return false;
		}
	};

	submitNewTrips = async (e) => {
		e.preventDefault();
		window.addEventListener("beforeunload", this.handleBeforeUnload);
		this.setState({ formLoading: true });
		
		try {
		   const tripResponse = await axios.post(`trip/bulk-create/`, this.state.newTrips);
		   
		   if (tripResponse) {
			// prepare trips data to send it to odoo trip by trip 
			  for (let tData of tripResponse.data) {
				 const tripNumber = tData.number;
				 const tripData = await axios.get(`trip?serializer=odoo&workOrder=${this.state.wo.id}&number=${tripNumber}`);
				 const trip = tripData.data[0];
				 this.sendTripsToOdoo(trip);
			  }
			  AlertNotification(i18n.t("submit"), "success");
			  this.getUpdatedTrips("&ordering=loadingDate");
		   }
		} catch (error) {
		   console.error("Error submitting trips: ", error.response || error);
		   AlertNotification(null, "error", error.response.status);
		} finally {
		   this.setState({ formLoading: false });
		   window.removeEventListener("beforeunload", this.handleBeforeUnload);
		}
	 };
	 

	updateTrip = () => {
		let pass = true;
		let data = this.state.updatedTrips.map((trip) => {
			let updatedTrip = {
				id: trip.id,
				number: trip.number,
				endKM: trip.endKM,
				startKM: trip.startKM,
				loadedQTY: trip.loadedQTY,
				deliveredQTY: trip.deliveredQTY,
				owner: trip.owner && (typeof trip.owner === "object" ? trip.owner.id : trip.owner),
				loadingDate: trip.loadingDate && moment(trip.loadingDate).format("YYYY-MM-DDTHH:mm"),
				unLoadingDate: trip.unLoadingDate && moment(trip.unLoadingDate).format("YYYY-MM-DDTHH:mm"),
			};
			if (trip.projectLegObj) {
				updatedTrip.projectLeg = trip.projectLeg;
			} else if (trip.legObj) {
				updatedTrip.leg = trip.leg;
			}
			if (trip.invalidUnLoadingDate || trip.invalidLoadingDate) pass = false;
			return updatedTrip;
		});
		if (!pass) return AlertNotification(i18n.t("inconsistentDates"), "error");
		window.addEventListener("beforeunload", this.handleBeforeUnload);
		this.setState({ formLoading: true });
		axios
			.post(`trip/bulkupdate/`, data)
			.then((res) => {
				if (res) {
					for (let trip of data) {
						// get the required trip data to update each trip in odoo
						const tripNumber = trip.number;
						axios
							.get(`trip?serializer=odoo&workOrder=${this.state.wo.id}&number=${tripNumber}`)
							.then(async (res) => {
								const trip = res.data[0];
								const workOrder = trip.workOrder;
								const odooData = {
									tripID: workOrder.code + "-" + trip.number,
									leg: trip.leg,
									projectLeg: trip.projectLeg,
									loadingDate: trip.loadingDate,
									quantity: trip.loadedQTY,
									tripKM: trip.kmDifference,
								}
								const { encryptedData, authTag, iv } = await encryptData(odooData);
								const encryptedObject = {
									encrypted_data: encryptedData,
									tag: authTag,
									iv,
								}
								this.sendDataToOdoo(`update_trip`, encryptedObject);
							})
							.catch((error) => {
								console.error("Error fetching trips: ", error);
							});
					}
					this.setState({ formLoading: false, tripsUpdateBtnNotClicked: false });
					AlertNotification(i18n.t("updated"), "success");
					this.getUpdatedTrips("&ordering=loadingDate");
				}
			})
			.catch((err) => {
				if (err) {
					console.error(err.response);
					this.setState({ formLoading: false });
					AlertNotification(null, "error", err.response.status);
				}
			})
			.finally(() => {
				window.removeEventListener("beforeunload", this.handleBeforeUnload);
			});
	};

	onFill = (e) => {
		this.calculateKM("updatedTrips");
	};

	calculateKM = (tripsState) => {
		this.setState({ formLoading: true });
		let trips = [...this.state.woTrips];
		let firstTripStartKM = Number(trips[0].startKM).toFixed(2);
		let lastTripEndKM = Number(trips[trips.length - 1].endKM).toFixed(2);
		if (firstTripStartKM > 0 && lastTripEndKM > 0) {
			if (lastTripEndKM - firstTripStartKM < 0) {
				AlertNotification(i18n.t("lastTripEndKMMustBeGreaterThanFirstTripStartKM"), "error");
				this.setState({ formLoading: false });
				return;
			}
			let stdDistance = 0;
			trips.forEach((trip) => {
				stdDistance += trip.tripDistance;
			});
			let actualDistance = lastTripEndKM - firstTripStartKM;
			trips.forEach((trip, index) => {
				if (index === 0) {
					trip.startKM = firstTripStartKM;
				} else {
					trip.startKM = Number(trips[index - 1].endKM).toFixed(2);
				}
				if (index === trips.length - 1) {
					trip.endKM = lastTripEndKM;
				} else {
					trip.endKM = (Number(trip.startKM) + (Number(trip.tripDistance) / stdDistance) * actualDistance).toFixed(2);
				}
				trip.distance = (Number(trip.endKM) - Number(trip.startKM)).toFixed(2);
			});
		} else {
			trips.forEach((trip, index) => {
				if (index === 0) {
					trip.startKM = Number(this.state.wo.startKM).toFixed(2);
				} else {
					trip.startKM = Number(trips[index - 1].endKM).toFixed(2);
				}
				trip.endKM = (Number(trip.startKM) + Number(trip.tripDistance)).toFixed(2);
				trip.distance = (Number(trip.endKM) - Number(trip.startKM)).toFixed(2);
			});
		}
		this.setState({
			[tripsState]: trips,
			formLoading: false,
		});
	};

	getTripCellValue = (e, v, tripIndex) => {
		let newValue = e.target.value,
			field = e.target.id,
			index = e.target.name;
		let updatedTrips = [...this.state.woTrips];
		if (v) {
			field = e.target.id.split("-")[0];
			newValue = v.id;
			index = tripIndex;
			updatedTrips[index][`${field}Obj`] = v;
		}
		updatedTrips[index][field] = newValue;
		if (field === "startKM") this.setState({ startKMEditIndex: index });
		if (field === "endKM") this.setState({ endKMEditIndex: index });
		if (field === "startKM" || field === "endKM") {
			updatedTrips[index]["distance"] = updatedTrips[index]["endKM"] - updatedTrips[index]["startKM"];
		}
		if (field === "loadedQTY" || field === "deliveredQTY") {
			updatedTrips[index]["difference"] = updatedTrips[index]["loadedQTY"] - updatedTrips[index]["deliveredQTY"];
		}
		this.setState({ updatedTrips: updatedTrips });
	};

	isValidDate = (id, value, index) => {
		let valid = true;
		let woEndDate = moment(this.state.wo.endDate, "DD-MM-YYYY hh:mm A").format("YYYY-MM-DD");
		let woStartDate = moment(this.state.wo.startDate, "DD-MM-YYYY hh:mm A").format("YYYY-MM-DD");
		if (id === "unLoadingDate" && moment(value).isBefore(this.state.woTrips[index].loadingDate, "day")) valid = false;
		if (id === "loadingDate" && moment(value).isBefore(woStartDate, "day")) valid = false;
		if (id === "loadingDate" && moment(value).isAfter(this.state.woTrips[index].unLoadingDate, "day")) valid = false;
		if (((this.state.wo.endDate && id === "unLoadingDate") || id === "loadingDate") && moment(value).isAfter(woEndDate, "day"))
			valid = false;
		return valid;
	};

	getTripDateValue = (e) => {
		let id = e.target.id;
		let value = e.target.value;
		value = moment(value + moment().format("THH:mm")).format("YYYY-MM-DDTHH:mm");
		let index = e.target.name;
		let updatedTrips = [...this.state.woTrips];
		if ((id === "unLoadingDate" || id === "loadingDate") && !this.isValidDate(id, value, index))
			updatedTrips[index][`invalid${id.charAt(0).toUpperCase()}${id.slice(1, id.length)}`] = true;
		else {
			updatedTrips[index][id] = value;
			updatedTrips[index][`invalid${id.charAt(0).toUpperCase()}${id.slice(1, id.length)}`] = false;
		}
		this.setState({ updatedTrips: updatedTrips });
	};

	initExpense = async () => {
		this.setState({ formLoading: true });
		if (this.state.wo.project || this.state.wo.plan) {
			let projectsCodes = [];
			if (!this.state.wo.project) {
				await axios.get(`project/?plan=${this.state.wo.plan}`).then((res) => {
					for (let project of res.data) {
						projectsCodes.push(project.code);
					}
				});
			} else {
				projectsCodes = [this.state.wo.project.code];
			}
			await axios
				.post(`project/getExpenseTypes/`, { projectsCodes: projectsCodes })
				.then((res) => {
					if (res) {
						this.setState({ projectExpenseTypes: res.data });
						this.getUpdatedTrips("&ordering=loadingDate");
					}
				})
				.catch((err) => {
					if (err) {
						console.error(err.response);
						AlertNotification(null, "error", err.response.status);
					}
				});
			await axios
				.get(`trip/?serializer=initsettel&workOrder=${this.state.wo.id}&ordering=loadingDate`, {
					projectsCodes: projectsCodes,
				})
				.then((res) => {
					if (res) this.setState({ tripsExpenses: res.data });
				})
				.catch((err) => {
					if (err) {
						console.error(err.response);
						AlertNotification(null, "error", err.response.status);
					}
				});
			this.setState({ formLoading: false });
		}
		await axios
			.get(`expensetype/`)
			.then((res) => {
				if (res) {
					this.setState({
						expensesTypes: res.data,
						allTripsHaveExpenses: {},
						initExpense: true,
						formLoading: false,
					});
				}
			})
			.catch((err) => {
				if (err) {
					console.error(err.response);
					this.setState({ formLoading: false });
					AlertNotification(null, "error", err.response.status);
				}
			});
	};

	validateTripRecipet = (e) => {
		e.preventDefault();
		let target = e.target.name;
		let tripID = e.target.getAttribute("tripid");
		let tripsReceipts = [...this.state.tripsReceipts];
		let tripIndex = e.target.getAttribute("tripindex");
		let targetArr = e.target.getAttribute("target") ? [...this.state[e.target.getAttribute("target")]] : null;
		if (targetArr) {
			let trip = targetArr[tripIndex];
			trip[target] = true;
			this.setState({ [e.target.getAttribute("target")]: targetArr });
		}
		if (!tripsReceipts[tripIndex]) {
			tripsReceipts[tripIndex] = { id: tripID };
		}
		tripsReceipts[tripIndex][`invalid${target.charAt(0).toUpperCase()}${target.slice(1, target.length)}`] = true;
		this.setState({ tripsReceipts: tripsReceipts });
	};

	getLoadingReceiptAndCopounNos = (e) => {
		let tripIndex = e.target.getAttribute("tripindex");
		let tripID = e.target.getAttribute("tripid");
		let target = e.target.name;
		let value = e.target.value;
		let tripsReceipts = [...this.state.tripsReceipts];
		if (tripsReceipts[tripIndex]) {
			tripsReceipts[tripIndex][target] = value;
		} else {
			tripsReceipts[tripIndex] = { [target]: value, id: tripID };
		}
		tripsReceipts[tripIndex][`invalid${target.charAt(0).toUpperCase()}${target.slice(1, target.length)}`] = false;
		this.setState({ tripsReceipts: tripsReceipts });
	};

	postExpenses = (e) => {
		e.preventDefault();
		this.setState({ postExpenses: true });
	};

	getTripExpenseValue = (tripexpenses) => {
		this.expenses.push(...tripexpenses);
		this.expensesCount = this.expensesCount + 1;
		if (this.expensesCount !== 0 && this.expensesCount === this.state.woTrips.length) {
			this.submitTripExpense();
		}
	};

	submitTripExpense = async () => {
		if (this.expensesCount !== this.state.woTrips.length) {
			return;
		}
		let allExpenses = [...this.expenses];
		this.setState({ postExpenses: false });
		this.expensesCount = 0;
		this.expenses = [];
		this.setState({ formLoading: true });
	
		try {
			// Submit all expenses to the backend
			const expenseResponse = await axios.post(`expense/`, { allExpenses: allExpenses, tripsReceipts: this.state.tripsReceipts });
	
			if (expenseResponse) {
				if(this.state.projectLegs.length > 0) {
					const odooLog = {
						woCode: this.state.wo.code,
						woLog: "Initial Settelment"
					}
					const { encryptedData, authTag, iv } = await encryptData(odooLog);
					const encryptedObject = {
						encrypted_data: encryptedData,
						tag: authTag,
						iv,
					}
					// update wo log status in odoo
					this.sendDataToOdoo('update_opwo', encryptedObject);
	
					// Fetch coupon number for each trip in wo to update its value in odoo 
					const tripResponse = await axios.get(`trip?serializer=odooUpdate&workOrder=${this.state.wo.id}`);
	
					for (let trip of tripResponse.data) {
						const odooData = {
							tripID: this.state.wo.code + "-" + trip.number,
							couponNumber: trip.couponNo
						}
						const { encryptedData, authTag, iv } = await encryptData(odooData);
						const encryptedObject = {
							encrypted_data: encryptedData,
							tag: authTag,
							iv,
						}
						// update coupon number for each trip in odoo
						this.sendDataToOdoo('update_trip', encryptedObject);
	
					}
					
					// update expenses in odoo for each trip in wo 
					for (let trip of this.state.woTrips) {
						await this.processTripExpenses(trip);
					}
				}
				
				this.setState({ formLoading: false, tripsExpenses: [], initExpense: false });
				AlertNotification(i18n.t("submit"), "success");
				this.props.history.push({ pathname: "/listworkorders/" });
			}
		} catch (error) {
			console.error("Error processing trip expenses: ", error);
			AlertNotification(error.toJSON().message, "error");
			this.setState({ formLoading: false });
		}
	};
	
	// Helper function to get and send trip expenses to odoo
	processTripExpenses = async (trip) => {
		try {
			// Fetch expenses for a specific trip
			const tripExpenseResponse = await axios.get(`trip/?serializer=odooExpense&id=${trip.id}`);
			const tripExpenses = tripExpenseResponse.data;
	
			// prepare and send trip expenses to odoo trip by trip 
			for (let tripExpense of tripExpenses) {
				for (let expense of tripExpense.expenses) {
					if (this.isRelevantExpense(expense)) {
						// Prepare Odoo data for each trip expense 
						const odooData = {
							tripID: this.state.wo.code + "-" + tripExpense.number,
							leg: trip.projectLeg,
							expenseType: expense.expenseType,
							amount: expense.amount,
							company: this.state.wo.instance['name'],
						};
						const { encryptedData, authTag, iv } = await encryptData(odooData);
						const encryptedObject = {
							encrypted_data: encryptedData,
							tag: authTag,
							iv,
						}
						// send one trip expense to odoo
						await this.sendDataToOdoo('update_expenses', encryptedObject);
					}
				}
			}
		} catch (error) {
			console.error("Error fetching or sending trip expenses: ", error);
		}
	};

	// Helper function to filter relevant expenses
	isRelevantExpense = (expense) => {
		const relevantTypes = [
			"Govte. Scale",
			"Govte. Tolls",
			"Gratuity",
			"Driver Bonus",
			"Driver Allowance",
			"Driver Asst. Allowance",
		];
		return relevantTypes.includes(expense.expenseType);
	};

	getExpenses = (e) => {
		this.setState({ loading: true });
		axios
			.get(`trip/?serializer=expenses&workOrder=${this.state.wo.id}&ordering=loadingDate`)
			.then((res) => {
				if (res) {
					this.getUpdatedTrips("&ordering=loadingDate");
					this.setState({ expensesReviews: res.data, expensesForPrint: res.data }, () => {
						this.setState({ loading: false });
					});
				}
			})
			.catch((err) => {
				if (err) {
					AlertNotification(null, "error", err.response.status);
					console.error(err.response);
					this.setState({ loading: false });
				}
			});
	};

	getExpensesForPrint = () => {
		axios.get(`trip/?serializer=expenses&workOrder=${this.state.wo.id}&ordering=loadingDate`).then((res) => {
			if (res) {
				this.setState({ expensesForPrint: res.data });
			}
		});
	};

	calculateGrandTotal = () => {
		let total = 0;
		this.state.expensesReviews.forEach((expense) => {
			total += Number(expense.total);
		});
		this.setState({ grandTotalExpenses: total });
	};

	getExpensesTotal = (tripindex) => {
		let sum = 0;
		const expensesReviews = [...this.state.expensesReviews];
		let expenses = expensesReviews[tripindex]["expenses"];

		expenses.forEach((expense) => {
			if (expense.expenseType !== "Driver Bonus") sum += Number(expense.amount);
		});
		expensesReviews[tripindex]["total"] = sum.toFixed(2);
		this.setState({ expensesReviews: expensesReviews }, this.calculateGrandTotal);
	};

	getExpenseValue = (e) => {
		let tripIndex = e.target.getAttribute("tripIndex");
		let expenseIndex = e.target.getAttribute("index");
		let expensesReviews = [...this.state.expensesReviews];
		let updatedExpenses = [...this.state.modifiedExpenses];
		let expenseObj = expensesReviews[tripIndex]["expenses"][expenseIndex];
		expenseObj.amount = e.target.value;
		if (!expenseObj.index) {
			let expenseObjIndex = updatedExpenses.push(expenseObj);
			expenseObj.index = expenseObjIndex - 1;
		} else {
			updatedExpenses[expenseObj.index] = expenseObj;
		}
		this.setState({ modifiedExpenses: updatedExpenses, expensesReviews: expensesReviews }, () => {
			this.getExpensesTotal(tripIndex);
		});
	};

	submitReviewExpense = async (e) => {
		e.preventDefault();
		const log = e.target.name;
		this.setState({ formLoading: true });
		try {
			// Submit all expenses to the backend
			const expenseResponse = await axios.post(`expense/review/?actionType=${e.target.name}&workOrder=${this.state.wo.id}`, this.state.modifiedExpenses);
			
			if (expenseResponse) {
				if(this.state.projectLegs.length > 0) {
					const odooLog = {
						woCode: this.state.wo.code,
						woLog: log
					}
					const { encryptedData, authTag, iv } = await encryptData(odooLog);
					const encryptedObject = {
						encrypted_data: encryptedData,
						tag: authTag,
						iv,
					}
					// update wo log status in odoo
					this.sendDataToOdoo('update_opwo', encryptedObject);
					// update expenses in odoo for each trip in wo 
					for (let trip of this.state.woTrips) {
						await this.processTripExpenses(trip);
					}
				}
				this.setState({ expensesReviews: [], modifiedExpenses: [] });
				this.setState({ formLoading: false });
				AlertNotification(i18n.t("submit"), "success");
				this.props.history.push({ pathname: "/listworkorders/" });
			}
		} catch (err) {
			if (err) {
				this.setState({ formLoading: false });
				console.error(err.toJSON());
			}
		}
	};

	getUpdatedTrips = (ordering = "") => {
		this.setState({ loading: true });
		axios
			.get(`trip/?serializer=workorder&workOrder=${this.state.wo.id}${ordering}`)
			.then((res) => {
				if (res) {
					let trips = res.data.map((trip, index) => {
						return { ...trip, index: index };
					});
					this.setState({ woTrips: trips, newTrips: [] }, () => {
						this.setState({ loading: false });
					});
				}
			})
			.catch((err) => {
				if (err) {
					AlertNotification(null, "error", err.response.status);
					console.error(err.response);
					this.setState({ loading: false });
				}
			});
	};

	viewPlan = () => {
		this.props.history.push({ pathname: "/viewplan/", planID: this.state.plan });
	};

	createRFR = () => {
		this.setState({ newRFR: { workOrder: this.state.wo.id } });
	};

	cancelRFR = () => {
		this.setState({ newRFR: null });
	};

	confirmArchiveWorkOrder = () => {
		return ArchivePopup({
			title: "Are you sure ?",
			cancelbtntxt: "No",
			confirmbtntxt: "Yes",
			text: this.state.wo.isArchived ? `This will unarchive Work Order ${this.state.wo.code}` : `This will archive Work Order ${this.state.wo.code}`,
			confirmedresult: () => this.archiveWO(),
		});
	};

	archiveWO = () => {
		let wo = this.state.wo;
		axios
			.put(`workorder/archiveWO/`, { id: wo.id })
			.then(async (response) => {
				if(response.data) {
					console.log("Archived");
					const odooData = {
						woCode: wo.code
					}
					const { encryptedData, authTag, iv } = await encryptData(odooData);
					const encryptedObject = {
						encrypted_data: encryptedData,
						tag: authTag,
						iv,
					}
					this.sendDataToOdoo(`delete_wo_trips`, encryptedObject);
				} else {
					console.log("Unarchived");
					axios
						.get(`trip?serializer=odoo&workOrder=${this.state.wo.id}`)
						.then((res) => {
							for (let trip of res.data) {
								this.sendTripsToOdoo(trip);
							}
						})
						.catch((error) => {
							console.error("Error fetching trips: ", error);
						});
				}
				this.setState({ wo: { ...wo, isArchived: response.data } });
			})
			.catch(() => {
				AlertNotification(i18n.t("errorMessage"), "error");
			});
	};

	sendTripsToOdoo = async (trip) => {
		const workOrder = trip.workOrder;
		const project = workOrder.project;
		
		const odooData = {
			tripID: workOrder.code + "-" + trip.number,
			woCode: workOrder.code,
			projectCode: project.code,
			projectType: project.projectType,
			serviceType: workOrder.serviceType,
			couponNumber: trip.couponNo,
			leg: trip.leg,
			projectLeg: trip.projectLeg,
			loadingDate: trip.loadingDate,
			quantity: trip.loadedQTY,
			tripKM: trip.kmDifference,
			asset: workOrder.asset,
			attachedAsset: workOrder.attachedAsset,
			driverName: workOrder.driver,
			company: workOrder.instance,
		};
		const { encryptedData, authTag, iv } = await encryptData(odooData);
		const encryptedObject = {
			encrypted_data: encryptedData,
			tag: authTag,
			iv,
		}
		const isSent = await this.sendDataToOdoo(`new_trip`, encryptedObject);
		
		// check if the trip is successfully sent to odoo to send its standard expenses
		if (isSent && odooData.projectLeg) {
			const projectLegData = await axios.get(`projectleg/?serializer=odoo&id=${trip.projectLeg_id}`);
			const standard = projectLegData.data[0];

			// list of specific types that should be sent to odoo
			const expenseTypes = [
			{ type: "Gratuity", value: standard.tips },
			{ type: "Govte. Tolls", value: standard.tolls },
			{ type: "Govte. Scale", value: standard.scales },
			{ type: "Driver Bonus", value: standard.driverBonus },
			{ type: "Driver Allowance", value: standard.driverAllowance },
			{ type: "Driver Asst. Allowance", value: standard.driverAsstAllowance },
			];
			
			// check if the value of the expense type is not null to send it to odoo
			expenseTypes.forEach(async expense => {
			if (expense.value) {
				const odooData = {
					tripID: workOrder.code + "-" + trip.number,
					leg: trip.projectLeg,
					expenseType: expense.type,
					amount: expense.value,
					company: workOrder.instance,
				};
				const { encryptedData, authTag, iv } = await encryptData(odooData);
				const encryptedObject = {
					encrypted_data: encryptedData,
					tag: authTag,
					iv,
				}
				this.sendDataToOdoo(`new_expense`, encryptedObject);
			}
			});
		}
	};

	closeWOModal = () => {
		if (this.state.closeWOModal) {
			this.setState({
				closeWOModal: false,
				editWO: false,
			});
			return;
		}
		let pass = true;
		let trips = [...this.state.woTrips];
		for (let trip of trips) {
			if (
				!Number(trip.startKM) ||
				!Number(trip.endKM) ||
				Number(trip.loadedQTY) < 0 ||
				Number(trip.deliveredQTY) < 0 ||
				!trip.loadingDate ||
				!trip.unLoadingDate ||
				!trip.owner
			) {
				pass = false;
			}
		}
		if (trips.length === 0) pass = true;
		if (!pass) {
			AlertNotification(i18n.t("fillTrips"), "error");
			return;
		}
		if (this.state.tripsUpdateBtnNotClicked && !this.state.wo.endDate) {
			AlertNotification(i18n.t("updateTripsFirst"), "error");
			return;
		}
		if (!this.state.closeWOModal) this.getWO(this.state.wo.id);
		this.setState({
			closeWOModal: !this.state.closeWOModal,
			closeWOData: {
				startDate: moment(this.state.wo.startDate, "DD-MM-YYYY hh:mm A").format("YYYY-MM-DDTHH:mm"),
				endDate: this.state.wo.endDate
					? moment(this.state.wo.endDate, "DD-MM-YYYY hh:mm A").format("YYYY-MM-DDTHH:mm")
					: moment().format("YYYY-MM-DDTHH:mm"),
				startKM: this.state.wo.startKM,
				endKM: this.state.wo.endKM
					? this.state.wo.endKM
					: this.state.woTrips.length !== 0
					? this.state.woTrips[this.state.woTrips.length - 1].endKM
					: this.state.wo.startKM,
			},
		});
	};

	invalidate = (target, value, invalid) => {
		this.setState({
			closeWOData: {
				...this.state.closeWOData,
				[target]: value,
				[`invalid${target.charAt(0).toUpperCase()}${target.slice(1, target.length)}`]: invalid,
			},
		});
	};

	getCloseWOValues = (e) => {
		let target = e.target.name,
			value = e.target.value;
		if (target === "startDate" && this.state.closeWOData.endDate) {
			let startDate = moment(value).format("YYYY-MM-DD HH:mm:ss");
			let endDate = moment(this.state.closeWOData.endDate).format("YYYY-MM-DD HH:mm:ss");
			if (moment(endDate).isBefore(startDate)) this.invalidate(target, null, true);
			else this.invalidate(target, value, false);
		} else if (target === "endDate" && this.state.closeWOData.startDate) {
			let endDate = moment(value).format("YYYY-MM-DD HH:mm:ss");
			let startDate = moment(this.state.closeWOData.startDate).format("YYYY-MM-DD HH:mm:ss");
			// Compare both date and time
			if (moment(endDate).isBefore(startDate)) {
				this.invalidate(target, null, true);
			} else {
				this.invalidate(target, value, false);
			}
		} else if (target === "endKM") {
			let actualDistance = value - parseInt(this.state.closeWOData.startKM),
				standardDistance = this.state.wo.distance["distance__sum"];
			if (!standardDistance) standardDistance = 0;
			if (!(actualDistance - standardDistance >= -10 && actualDistance - standardDistance <= 10) || actualDistance < 0) {
				this.setState({
					closeWOData: {
						...this.state.closeWOData,
						[target]: value,
						invalidEndKMExceedMin: actualDistance < 0 ? i18n.t("invalidEndKMExceedMin") + this.state.closeWOData.startKM : null,
						invalidEndKMDistance: !(actualDistance - standardDistance >= -10 && actualDistance - standardDistance <= 10)
							? i18n.t("invalidEndKMDistance") + standardDistance
							: null,
						[`invalid${target.charAt(0).toUpperCase()}${target.slice(1, target.length)}`]: true,
					},
				});
			} else {
				this.setState({
					closeWOData: {
						...this.state.closeWOData,
						[target]: value,
						invalidEndKMExceedMin: null,
						invalidEndKMDistance: null,
						[`invalid${target.charAt(0).toUpperCase()}${target.slice(1, target.length)}`]: false,
					},
				});
			}
		} else {
			this.setState({
				closeWOData: {
					...this.state.closeWOData,
					[target]: value,
					[`invalid${target.charAt(0).toUpperCase()}${target.slice(1, target.length)}`]: false,
				},
			});
		}
	};

	validateCloseWO = (e) => {
		let target = e.target.name;
		this.setState({
			closeWOData: {
				...this.state.closeWOData,
				[`invalid${target.charAt(0).toUpperCase()}${target.slice(1, target.length)}`]: true,
			},
		});
	};

	editWO = () => {
		this.setState({ editWO: true }, () => {
			this.getCompanies();
			this.closeWOModal();
		});
	};

	onConfirmEdit = () => {
		let pass = true;
		let closeWOData = { ...this.state.closeWOData };
		let trips = this.state.updatedTrips.map((trip) => {
			let updatedTrip = {
				id: trip.id,
				endKM: trip.endKM,
				startKM: trip.startKM,
				loadedQTY: trip.loadedQTY,
				deliveredQTY: trip.deliveredQTY,
				owner: trip.owner && (typeof trip.owner === "object" ? trip.owner.id : trip.owner),
				loadingDate: trip.loadingDate ? moment(trip.loadingDate).format("YYYY-MM-DDTHH:mm") : trip.loadingDate,
				unLoadingDate: trip.unLoadingDate ? moment(trip.unLoadingDate).format("YYYY-MM-DDTHH:mm") : trip.unLoadingDate,
			};
			if (typeof trip.leg === "number") updatedTrip["leg"] = trip.leg;
			if (typeof trip.projectLeg === "number") updatedTrip["projectLeg"] = trip.projectLeg;
			if (trip.invalidUnLoadingDate) pass = false;
			return updatedTrip;
		});
		if (!pass) return;
		this.setState({ formLoading: true });
		axios
			.put(`workorder/updateWorkOrder/`, {
				id: this.state.wo.id,
				closeWOData: closeWOData,
				trips: trips,
				event: this.state.eventName,
			})
			.then((res) => {
				if (res) {
					this.setState({ formLoading: false });
					AlertNotification(i18n.t("updated"), "success");
					this.getWO(this.state.wo.id);
				}
			})
			.catch((err) => {
				if (err) {
					console.error(err.response);
					this.setState({ formLoading: false });
					AlertNotification(null, "error", err.response.status);
				}
			});
	};

	onCancelEdit = () => {
		this.setState({ editWO: false, confirmEdit: false });
	};

	updatePermission = async () => {
		let hasSuperiorEdit = false;
		await authAxios.post(`checkperm`, { perm: "ops.delete_log" }).then((res) => {
			if (res.data) hasSuperiorEdit = !res.data.perm;
		});
		let editable = true,
			eventName = "";
		let logs = { ...this.state.logs };
		if (logs["initialSettelment"]) {
			if (logs["expenseReview"]) {
				if (logs["finalSettlement"]) {
					if (logs["editWorkOrderFinal"]) {
						editable = false;
					} else {
						eventName = "Edit WorkOrder Final";
					}
				} else {
					if (logs["editWorkOrderReview"]) {
						editable = false;
					} else {
						eventName = "Edit WorkOrder Review";
					}
				}
			} else {
				if (logs["editWorkOrderInitial"]) {
					editable = false;
				} else {
					eventName = "Edit WorkOrder Initial";
				}
			}
		}
		if (hasSuperiorEdit) {
			eventName = "Edit WorkOrder Super";
			editable = true;
		}
		this.setState({ editable: editable, eventName: eventName });
	};

	viewProject = () => {
		this.props.history.push({ pathname: "/viewproject/", projectID: this.state.wo.project.id });
	};

	getInstances = () => {
		axios.get(`profile/userinstances`).then((res) => {
			this.setState({ instances: res.data });
		})
	};

	componentDidMount = () => {
		this.getCompanies();
		let woID = this.props.history.location.workOrderID;
		if (!woID) woID = new URLSearchParams(this.props.location.search).get("id");
		this.getWO(woID);
		this.getInstances();
	};

	render() {
		const t = i18n.t;
		const headerFormatter = (column) => <div style={{ width: "150px", minWidth: "fit-content", padding: 2 }}>{column.text}</div>;
		const tripColumns = [
			{
				sort: true,
				align: "center",
				dataField: "number",
				headerAlign: "center",
				text: i18n.t("number"),
				hidden: true,
			},
			{
				align: "center",
				headerAlign: "center",
				dataField: "loadingDate",
				text: i18n.t("loadingDate"),
				headerFormatter: headerFormatter,
				style: (cell, trip) => {
					if (this.state.updatedTrips[trip.index] && this.state.updatedTrips[trip.index]["invalidLoadingDate"]) {
						return { borderColor: "white white red white" };
					}
				},
				formatter: (cell, trip, index) => {
					let loadingDate = moment(trip.loadingDate).format("YYYY-MM-DD");
					return (
						<Input
							fullWidth
							name={trip.index.toString()}
							id="loadingDate"
							type="date"
							defaultValue={loadingDate}
							onBlur={this.getTripDateValue}
							error={this.state.updatedTrips[trip.index] && this.state.updatedTrips[trip.index]["invalidLoadingDate"]}
						/>
					);
				},
			},
			{
				align: "center",
				headerAlign: "center",
				text: i18n.t("leg"),
				headerFormatter: headerFormatter,
				dataField: "leg",
				formatter: (cell, trip, index) => {
					if (trip.projectLeg) {
						return (
							<Selector
								id="projectLeg"
								size="small"
								width={350}
								isObjectOption
								optionAccessor="legName"
								onChange={(e, v) => this.getTripCellValue(e, v, trip.index)}
								name={trip.index.toString()}
								defaultValue={trip.projectLegObj ? trip.projectLegObj : { legName: trip.projectLeg }}
								error={this.state.updatedTrips[trip.index] && this.state.updatedTrips[trip.index]["invalidProjectLeg"]}
								options={this.state.projectLegs}
							/>
						);
					} else {
						return (
							<Selector
								id="leg"
								size="small"
								width={350}
								isObjectOption
								optionAccessor="name"
								onChange={(e, v) => this.getTripCellValue(e, v, trip.index)}
								name={trip.index.toString()}
								defaultValue={trip.legObj ? trip.legObj : { name: trip.leg }}
								error={this.state.updatedTrips[trip.index] && this.state.updatedTrips[trip.index]["invalidLeg"]}
								options={this.state.legs}
							/>
						);
					}
				},
			},
			{
				sort: false,
				align: "center",
				headerAlign: "center",
				dataField: "loadedQTY",
				text: i18n.t("loadedQTY"),
				headerFormatter: headerFormatter,
				formatter: (cell, trip, index) => {
					return (
						<Input
							fullWidth
							type="number"
							step={0.001}
							name={trip.index.toString()}
							id="loadedQTY"
							defaultValue={cell || 0}
							onBlur={this.getTripCellValue}
						/>
					);
				},
			},
			{
				align: "center",
				dataField: "owner.fullName",
				headerAlign: "center",
				text: i18n.t("owner"),
				formatter: (cell, trip, rowIndex) => {
					return (
						<Selector
							id="owner"
							size="small"
							width={250}
							isObjectOption
							optionAccessor="fullName"
							onChange={(e, v) => this.getTripCellValue(e, v, trip.index)}
							name={trip.index.toString()}
							defaultValue={trip.ownerObj ? trip.ownerObj : cell ? { fullName: cell } : { fullName: "" }}
							error={this.state.updatedTrips[trip.index] && this.state.updatedTrips[trip.index]["invalidOwner"]}
							options={this.state.companies}
						/>
					);
				},
			},
			{
				sort: false,
				align: "center",
				headerAlign: "center",
				dataField: "deliveredQTY",
				text: i18n.t("deliveredQTY"),
				headerFormatter: headerFormatter,
				formatter: (cell, trip, index) => {
					return (
						<Input
							fullWidth
							type="number"
							step={0.001}
							name={trip.index.toString()}
							id="deliveredQTY"
							defaultValue={cell || 0}
							onBlur={this.getTripCellValue}
						/>
					);
				},
			},
			{
				align: "center",
				headerAlign: "center",
				dataField: "unLoadingDate",
				text: i18n.t("unLoadingDate"),
				headerFormatter: headerFormatter,
				style: (cell, trip) => {
					if (this.state.updatedTrips[trip.index] && this.state.updatedTrips[trip.index]["invalidUnLoadingDate"]) {
						return { borderColor: "white white red white" };
					}
				},
				formatter: (cell, trip, index) => {
					let unLoadingDate = moment(trip.unLoadingDate).format("YYYY-MM-DD");

					return (
						<Input
							fullWidth
							name={trip.index.toString()}
							id="unLoadingDate"
							type="date"
							defaultValue={unLoadingDate}
							error={this.state.updatedTrips[trip.index] && this.state.updatedTrips[trip.index]["invalidUnLoadingDate"]}
							onBlur={this.getTripDateValue}
						/>
					);
				},
			},
			{
				sort: false,
				align: "center",
				headerAlign: "center",
				text: i18n.t("difference"),
				dataField: "difference",
				headerFormatter: headerFormatter,
				formatter: (cell, trip, index) => {
					let difference = trip["loadedQTY"] - trip["deliveredQTY"];
					if (isNaN(difference)) {
						difference = "-";
					}
					return (
						<Input
							fullWidth
							type="number"
							step={0.001}
							value={cell || difference}
							style={{ color: difference !== 0 && "red" }}
							disabled
						/>
					);
				},
			},
			{
				sort: false,
				align: "center",
				dataField: "startKM",
				headerAlign: "center",
				text: i18n.t("startKM"),
				headerFormatter: headerFormatter,
				formatter: (cell, trip, index) => {
					return (
						<Input
							type="number"
							fullWidth
							step={0.1}
							name={trip.index.toString()}
							id="startKM"
							defaultValue={cell}
							onBlur={this.getTripCellValue}
						/>
					);
				},
			},
			{
				sort: false,
				align: "center",
				dataField: "endKM",
				headerAlign: "center",
				text: i18n.t("endKM"),
				headerFormatter: headerFormatter,
				formatter: (cell, trip, index) => {
					return (
						<Input
							fullWidth
							type="number"
							step={0.1}
							name={trip.index.toString()}
							id="endKM"
							defaultValue={cell}
							onBlur={this.getTripCellValue}
						/>
					);
				},
			},
			{
				sort: false,
				align: "center",
				headerAlign: "center",
				text: i18n.t("distance"),
				dataField: "distance",
				headerFormatter: headerFormatter,
				formatter: (cell, trip, index) => {
					let distance = Number(trip["endKM"] - trip["startKM"]).toFixed(2);
					return (
						<Input
							fullWidth
							type="number"
							step={0.01}
							value={cell || distance}
							style={{ color: !(distance - trip["tripDistance"] >= -10 && distance - trip["tripDistance"] <= 10) && "red" }}
							disabled
						/>
					);
				},
			},
			{
				align: "center",
				dataField: "actions",
				headerAlign: "center",
				text: i18n.t("actions"),
				formatter: (cell, trip, index) => {
					return (
						<GetPermission perm="ops.delete_trip">
							<div code="perm">
								<DeleteIcon index={trip.index} ondelete={this.deleteTrip} hidden={this.state.wo.logs.length !== 0} />
							</div>
						</GetPermission>
					);
				},
			},
		];
		const wo = (
			<WorkOrderCard
				instances={this.state.instances}
				wo={this.state.wo}
				edit={this.editWO}
				plan={this.state.plan}
				logs={this.state.logs}
				viewPlan={this.viewPlan}
				close={this.closeWOModal}
				archive = {this.confirmArchiveWorkOrder}
				addTrip={this.addNewTrip}
				createRFR={this.createRFR}
				initExpense={this.initExpense}
				editable={this.state.editable}
				viewProject={this.viewProject}
				getExpenses={this.getExpenses}
				onCancelEdit={this.onCancelEdit}
				content={() => this.componentRef}
				onConfirmEdit={this.onConfirmEdit}
				confirmEdit={this.state.confirmEdit}
				expensesForPrint={this.state.expensesForPrint}
				getExpensesForPrint={this.getExpensesForPrint}
				mntcURL={process.env.NODE_ENV === "production" ? process.env.REACT_APP_MNTC_FE_URL : "http://localhost:3002/"}
			/>
		);
		return (
			this.state.wo && (
				<Fragment>
					<PageTitleAlt2 heading={t("viewWO")} icon="bi bi-hdd-network icon-gradient bg-happy-fisher" />
					<LoadingOverlay
						tag="div"
						styles={{
							overlay: (base) => ({
								...base,
								background: "#fff",
								opacity: 0.5,
							}),
						}}
						active={this.state.formLoading}
						spinner={<Loader active color="#30b1ff" type="line-spin-fade-loader" />}
					>
						<Row className="justify-content-center">
							<Col xs="12">{wo}</Col>
							<Col>
								<WOCloseModal
									wo={this.state.wo}
									onSubmit={this.closeWO}
									editWO={this.state.editWO}
									toggle={this.closeWOModal}
									woTrips={this.state.woTrips}
									isOpen={this.state.closeWOModal}
									getValues={this.getCloseWOValues}
									closeWOData={this.state.closeWOData}
									validateCloseWO={this.validateCloseWO}
								/>
							</Col>

							{this.state.newRFR && (
								<Col xs="12" className="my-3">
									<Card>
										<CardBody>
											<NewRFR
												assets={
													this.state.wo.attachedAsset ? [this.state.wo.asset, this.state.wo.attachedAsset] : [this.state.wo.asset]
												}
												wo={this.state.wo}
												cancelRFR={this.cancelRFR}
												getWO={this.getWO}
											/>
										</CardBody>
										<CardFooter className="d-flex justify-content-end">
											<Button color="dark" onClick={this.cancelRFR}>
												{t("cancel")}
											</Button>
										</CardFooter>
									</Card>
								</Col>
							)}
							<Col xs="12">
								<Form onInvalid={this.validateNewTrip} onSubmit={this.submitNewTrips}>
									{this.state.newTrips.length !== 0 && (
										<Row>
											<Col xs="12">
												<Card className="main-card mb-3">
													<CardBody>
														<Col xs="12">
															<div className="dropdown-menu-header m-0">
																<div className="dropdown-menu-header-inner m-0 rounded bg-primary">
																	<div className="menu-header-content">
																		<h5 className="menu-header-title">{t("trips")}</h5>
																	</div>
																</div>
															</div>
															<Row className="mt-3">
																{this.state.newTrips.map((trip, index) => {
																	return (
																		<Col lg="6" key={index}>
																			<TripForm
																				trip={trip}
																				index={index}
																				legs={this.state.legs}
																				onDelete={this.removeNewTrip}
																				getValue={this.getNewTripValue}
																				companies={this.state.companies}
																				woEndDate={this.state.wo.endDate}
																				projectLegs={this.state.projectLegs}
																				woStartDate={this.state.wo.startDate}
																				getSelectedValue={this.getNewTripValue}
																			/>
																		</Col>
																	);
																})}
															</Row>
														</Col>

														<Col className="d-flex justify-content-end">
															<GetPermission perm="ops.add_trip">
																<div code="perm">
																	<Button color="dark" className="ml-2" type="button" onClick={this.addNewTrip}>
																		{t("addTrip")}
																	</Button>
																</div>
															</GetPermission>
															<GetPermission perm="ops.add_trip">
																<div code="perm">
																	<Button color="info" className="ml-2" type="submit">
																		{t("submit")}
																	</Button>
																</div>
															</GetPermission>
														</Col>
													</CardBody>
												</Card>
											</Col>
										</Row>
									)}
								</Form>
							</Col>
							<Col xs="12">
								<div hidden={true}>
									<PrintableExpenseForm data={this.state.expensesForPrint} ref={(el) => (this.componentRef = el)} wo={wo} />
								</div>
								<Card className="main-card mb-3">
									<CardBody>
										{this.state.companies && (this.state.projectLegs.length !== 0 || this.state.legs.length !== 0) && (
											<ListNonPlannedWOTrips
												editable={this.state.editable}
												editWO={this.state.editWO}
												trips={this.state.woTrips}
												tripColumns={tripColumns}
												confirmEdit={this.state.confirmEdit}
												onUpdate={this.updateTrip}
												logs={this.state.wo.logs}
												onFill={this.onFill}
												loading={this.state.formLoading}
												logsState={this.state.logs}
											/>
										)}
									</CardBody>
								</Card>
							</Col>
							{this.state.initExpense && (
								<Col xs="12">
									<Form onInvalid={this.validateTripRecipet} onSubmit={this.postExpenses}>
										<Card className="main-card mb-3">
											<CardBody>
												<div className="dropdown-menu-header ">
													<div className="dropdown-menu-header-inner  rounded bg-primary ">
														<div className="menu-header-content ">
															<h5 className="menu-header-title">{t("expenses")}</h5>
														</div>
													</div>
												</div>
												<Row className="mt-3">
													{this.state.tripsExpenses.length !== 0
														? this.state.tripsExpenses.map((trip, index) => (
																<Col xs="12" key={index}>
																	<ExpenseForm
																		trip={trip}
																		tripIndex={index}
																		target="tripsExpenses"
																		expenses={this.expenses[index]}
																		setScrol={this.setScrolPosition}
																		getValue={this.getTripExpenseValue}
																		tripData={this.state.woTrips[index]}
																		postExpenses={this.state.postExpenses}
																		onFocus={this.setExtraInfoActiveIndex}
																		tripsReceipts={this.state.tripsReceipts}
																		expensesTypes={this.state.expensesTypes}
																		scrolPosition={this.state.scrolPosition}
																		extraInfoActiveIndex={this.state.extraInfoActiveIndex}
																		getLoadingReceiptAndCopounNos={this.getLoadingReceiptAndCopounNos}
																		projectExpenseTypes={this.state.projectExpenseTypes[trip.projectCode]}
																	/>
																</Col>
														  ))
														: this.state.woTrips.map((trip, index) => (
																<Col xs="12" className="mt-1" key={index}>
																	<ExpenseForm
																		trip={trip}
																		tripData={trip}
																		target="woTrips"
																		tripIndex={index}
																		expenses={this.expenses[index]}
																		setScrol={this.setScrolPosition}
																		getValue={this.getTripExpenseValue}
																		onFocus={this.setExtraInfoActiveIndex}
																		postExpenses={this.state.postExpenses}
																		tripsReceipts={this.state.tripsReceipts}
																		expensesTypes={this.state.expensesTypes}
																		scrolPosition={this.state.scrolPosition}
																		projectExpenseTypes={this.state.projectExpenseTypes}
																		extraInfoActiveIndex={this.state.extraInfoActiveIndex}
																		getLoadingReceiptAndCopounNos={this.getLoadingReceiptAndCopounNos}
																	/>
																</Col>
														  ))}
												</Row>
											</CardBody>
											<CardFooter>
												<Col>
													<GetPermission perm="ops.add_expense">
														<Button code="perm" type="submit" color="info" className="btn-right float-right">
															{t("submit")}
														</Button>
													</GetPermission>
												</Col>
											</CardFooter>
										</Card>
									</Form>
								</Col>
							)}
							{this.state.expensesReviews.length !== 0 && (
								<Col xs="12">
									<Card className="main-card mb-3">
										<CardBody>
											<div className="dropdown-menu-header ">
												<div className="dropdown-menu-header-inner  rounded bg-primary ">
													<div className="menu-header-content ">
														<h5 className="menu-header-title">{t("expensesReview")}</h5>
													</div>
												</div>
											</div>
											<Row className="mt-3 justify-content-center">
												{this.state.expensesReviews.map((expense, index) => (
													<Col xs="12" key={index} className="mt-2">
														<ReviewExpenseForm
															expense={expense}
															tripIndex={index}
															trips={this.state.woTrips}
															getValue={this.getExpenseValue}
															setScrol={this.setScrolPosition}
															onFocus={this.setExtraInfoActiveIndex}
															getExpensesTotal={this.getExpensesTotal}
															scrolPosition={this.state.scrolPosition}
															extraInfoActiveIndex={this.state.extraInfoActiveIndex}
														/>
													</Col>
												))}
											</Row>
										</CardBody>
										<CardFooter>
											<Col xs="6">{`${t("grandTotalExpenses")}: ${this.state.grandTotalExpenses}`}</Col>
											<Col xs="6">
												<GetPermission perm="ops.change_expense">
													<Button
														className="btn-right float-right"
														code="perm"
														type="button"
														onClick={this.submitReviewExpense}
														color="info"
														name={this.state.logs["expenseReview"] ? "Final Settlement" : "Expense Review"}
													>
														{this.state.logs["expenseReview"] ? t("submitFinalSettlement") : t("submitExpenseReview")}
													</Button>
												</GetPermission>
											</Col>
										</CardFooter>
									</Card>
								</Col>
							)}
						</Row>
					</LoadingOverlay>
				</Fragment>
			)
		);
	}
}

export default withRouter(ViewNonPlannedWO);
