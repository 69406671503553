import axios from "axios";
import i18n, { t } from "i18next";
import { withRouter } from "react-router-dom";
import React, { Component, Fragment } from "react";
import { Row, Col, Card, CardBody, Label, Spinner, Button } from "reactstrap";
import PageTitleAlt2 from "../../common/PageTitleAlt2";
import DateRangePicker from "../../common/date_range_picker";
import TableWithPaginationSearch from "../../common/table_with_pagination_search";
import Selector from "ops/common/selector";

class ViewTripList extends Component {
	constructor() {
		super();
		this.state = {
			data: [],
			loading: false,
			instances:[],
			selectedInstance: null,
			startDate: null,
			endDate: null,
		};
	}

	getInstances = () => {
		axios.get(`profile/userinstances`).then((res) => {
			this.setState({ instances: res.data });
		})
	}

	filterByInstance = (e, v) => {
		if (v) {
			this.setState({ selectedInstance: v.id });
		} else {
			this.setState({selectedInstance: null});
		}
	};

	getTrips = () => {
		const {startDate, endDate} = this.state;
		this.setState({ loading: true });
		const { instances, selectedInstance } = this.state;
		// Use selected instance ID if it exists, otherwise check if there's only one instance
		let instanceFilter = selectedInstance 
			? `&instance=${selectedInstance}`
			: instances.length === 1 ? `&instance=${instances[0].id}` : "";

		axios.get(`trip/gettripsdata/?startDate=${startDate}&endDate=${endDate}${instanceFilter}`).then((res) => {
			this.setState({
				data: res.data,
				loading: false,
			});
		});
	};

	getDates = (sdate, edate) => {
		this.setState({ startDate: sdate, endDate: edate });
	};

	componentDidMount() {
		this.getInstances();
	}

	render() {
		const columns = [
			this.state.instances.length > 1 && {
				sort: true,
				align: "center",
				headerAlign: "center",
				dataField: "instance",
				text: i18n.t("company"),
			},
			{
				sort: true,
				align: "center",
				dataField: "wo",
				text: i18n.t("wo"),
				headerAlign: "center",
			},
			{
				sort: true,
				align: "center",
				headerAlign: "center",
				dataField: "numOfTrips",
				text: i18n.t("numOfTrips"),
			},
			{
				sort: true,
				align: "center",
				dataField: "tripID",
				headerAlign: "center",
				text: i18n.t("tripID"),
				formatter: (cell, row) => {
					return `${row.wo}-${row.tripNumber}`;
				},
				csvFormatter: (cell, row) => {
					return `${row.wo}-${row.tripNumber}`;
				},
			},
			{
				sort: true,
				align: "center",
				headerAlign: "center",
				dataField: "loadingDate",
				text: i18n.t("loadingDate"),
			},
			{
				sort: true,
				align: "center",
				headerAlign: "center",
				dataField: "unLoadingDate",
				text: i18n.t("unLoadingDate"),
			},
			{
				sort: true,
				align: "center",
				dataField: "woStart",
				headerAlign: "center",
				text: i18n.t("woStart"),
			},
			{
				sort: true,
				align: "center",
				dataField: "woEnd",
				headerAlign: "center",
				text: i18n.t("woEnd"),
			},
			{
				sort: true,
				align: "center",
				dataField: "driver",
				headerAlign: "center",
				text: i18n.t("driver"),
			},
			{
				sort: true,
				align: "center",
				dataField: "asset",
				headerAlign: "center",
				text: i18n.t("asset"),
			},
			{
				sort: true,
				align: "center",
				dataField: "leg",
				text: i18n.t("leg"),
				headerAlign: "center",
			},
		];

		return (
			<Fragment>
				<PageTitleAlt2 heading={i18n.t("trips")} icon="bi bi-kanban icon-gradient bg-happy-fisher" />
				<Row>
					<Col xs="6" sm="6" md="6" lg="6" xl="6">
						<DateRangePicker startDate={t("loadingDateStart")} endDate={t("loadingDateEnd")} getdata={this.getDates} />
					</Col>
					{
						this.state.instances.length > 1 &&
						<Col xs="3">
							<Label>{i18n.t("company")}</Label>
							<Selector
								clearable
								id="instanceFilter"
								size="small"
								isObjectOption
								optionAccessor="name"
								options={this.state.instances}
								onChange={this.filterByInstance}
							/>
						</Col>
					}
				</Row>
				<Row className="mb-2">
					<Col xs="12">
						<Button color="info" disabled={!this.state.startDate || !this.state.endDate} onClick={this.getTrips}>
							{i18n.t("submit")}
						</Button>
					</Col>
				</Row>
				<Row>
				{
					this.state.loading ? (
					<Col xs="12" className="d-flex justify-content-center align-items-center">
						<div style={{ textAlign: "center" }}>
							<Spinner style={{ width: "3rem", height: "3rem" }} color="black" />
							<p style={{ display: "block", marginTop: "1rem" }}>Loading...</p>
						</div>
					</Col>
					) : (
					<Col xs="12" sm="12" md="12" lg="12" xl="12">
						<Card className="main-card mb-3" key="0">
							<CardBody>
								<TableWithPaginationSearch
									scrollable
									search
									exportable
									keyfield="tripID"
									columns={columns}
									filename="trips.csv"
									data={this.state.data}
									loading={this.state.loading}
								/>
							</CardBody>
						</Card>
					</Col>
				)}
				</Row>
			</Fragment>
		);
	}
}

export default withRouter(ViewTripList);
