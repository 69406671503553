import axios from "axios";
import i18n from "i18next";
import { odooAxios } from "axios_interceptors";
import moment from "moment";
import NewWOModal from "./new_wo_modal";
import AddIcon from "ops/common/add_icon";
import ViewIcon from "ops/common/view_icon";
import { withRouter } from "react-router-dom";
import DeleteIcon from "ops/common/delete_icon";
import DeletePopup from "ops/common/delete_popup";
import React, { Component, Fragment } from "react";
import GetPermission from "ops/common/get_permission";
import PageTitleAlt2 from "../../common/PageTitleAlt2";
import DateRangePicker from "../../common/date_range_picker";
import { AlertNotification } from "ops/common/alert_notification";
import { Row, Col, Card, CardBody, FormGroup, Input, Label, Spinner } from "reactstrap";
import TableWithPaginationSearch from "../../common/table_with_pagination_search";
import Selector from "ops/common/selector";
import { encryptData } from "ops/common/encryption_service";

class ViewProjectList extends Component {
	constructor() {
		super();
		this.state = {
			data: [],
			loading: false,
			allProjects: [],
			selectedProject: null,
			projectWOsModal: false,
			showCompletedProjects: false,
			instances:[],
			selectedInstance: null,
		};
	}

	getInstances = (callback) => {
		axios.get(`profile/userinstances`).then((res) => {
			this.setState({ instances: res.data }, () => {
				if (callback) callback(); // Execute callback after instances are set
			});
		})
	}

	filterByInstance = (e, v) => {
		if (v) {
			this.setState({ selectedInstance: v.id });
		} else {
			this.setState({selectedInstance: null});
		}
	};

	getProjects = (sDate, eDate, filterByStatus) => {
		if (!filterByStatus) filterByStatus = ["Stopped", "Completed", "In Progress", "Did Not Start"];
		let dateRange = `startDate__gte=${sDate}&startDate__lte=${eDate}&`;
		if (!sDate) dateRange = `startDate__lte=${eDate}&`;
		if (!eDate) dateRange = `startDate__gte=${sDate}&`;
		if (!sDate && !eDate) dateRange = "";

		const { instances, selectedInstance } = this.state;
		// Use selected instance ID if it exists, otherwise check if there's only one instance
		let instanceFilter = selectedInstance 
			? `&instance=${selectedInstance}`
			: instances.length === 1 ? `&instance=${instances[0].id}` : "";

		this.setState({ loading: true });
		axios.get(`project/?serializer=list&${dateRange}status__in=${filterByStatus}${instanceFilter}`).then((res) => {
			this.setState(
				{
					allProjects: res.data,
				},
				() => {
					this.getFilteredProjects();
					this.setState({ loading: false });
				}
			);
		});
	};

	getInitialProjects = () => {
		let endDate = moment()
			.add(1, "day")
			.format("YYYY-MM-DDT23:59:59");
		let startDate = moment()
			.subtract(30, "days")
			.format("YYYY-MM-DDT00:00:00");
		let filterByStatus = ["In Progress", "Did Not Start"];
		this.getProjects(startDate, endDate, filterByStatus);
	};

	confirmDeleteProject = (project) => {
		return DeletePopup({
			title: "Are you sure ?",
			cancelbtntxt: "No",
			confirmbtntxt: "Yes",
			text: `This will delete Project ${project.code}`,
			confirmedresult: () => this.deleteProject(project),
		});
	};

	// used to send any required data to odoo by passing the url and the data
	sendDataToOdoo = (odooURL, odooData) => {
		odooAxios
		  .post(odooURL, odooData, {}, {
			  headers: {
				'Content-Type': 'application/json',
			  }
		  })
		  .then((odooResponse) => {
				console.log("Data successfully sent to Odoo:", odooResponse.data.result);
		  })
		  .catch((odooError) => {
				console.error("Failed to send data to Odoo:", odooError);
		  });
	};

	deleteProject = (project) => {
		this.setState({ loading: true });
		axios
			.delete(`project/${project.id}`)
			.then(async (res) => {
				if (res) {
					// passing project ID to odoo to be deleted
					const odooData = {
						projectCode: project.code
					}
					const { encryptedData, authTag, iv } = await encryptData(odooData);
					const encryptedObject = {
						encrypted_data: encryptedData,
						tag: authTag,
						iv,
					}
					this.sendDataToOdoo(`delete_project`, encryptedObject);
					AlertNotification(i18n.t("deleted"), "success");
					let projects = [...this.state.data].filter((proj) => proj.id !== project.id);
					this.setState({ allProjects: projects }, () => {
						this.getFilteredProjects();
						this.setState({ loading: false });
					});
				}
			})
			.catch((err) => {
				console.error(err.response);
				this.setState({ loading: false });
				AlertNotification(i18n.t("errorMessage"), "error");
			});
	};

	handleCompletedProjectFilter = () => {
		this.setState({ showCompletedProjects: !this.state.showCompletedProjects }, () => {
			this.getFilteredProjects();
		});
	};

	getFilteredProjects = () => {
		let allProjects = [...this.state.allProjects];
		let data = this.state.showCompletedProjects ? allProjects : allProjects.filter((project) => project.status !== "Completed");
		this.setState({ data: data });
	};

	toggleProjectWOsModal = (project) => {
		this.setState({
			projectWOsModal: !this.state.projectWOsModal,
			selectedProject: project,
		});
	};

	componentDidMount() {
		// Commented if default filteration within this month date range needed
		// this.getInitialProjects();
		this.getInstances(() => {
			this.getProjects()
		});
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevState.selectedInstance !== this.state.selectedInstance) {
			this.getProjects();
		}
	}

	render() {
		const columns = [
			this.state.instances.length > 1 && {
				sort: true,
				align: "center",
				headerAlign: "center",
				dataField: "instance",
				text: i18n.t("company"),
			},
			{
				sort: true,
				align: "center",
				dataField: "code",
				text: i18n.t("code"),
				headerAlign: "center",
			},
			{
				sort: true,
				align: "center",
				dataField: "description",
				text: i18n.t("description"),
				headerAlign: "center",
			},
			{
				sort: true,
				hidden: false,
				align: "center",
				dataField: "status",
				headerAlign: "center",
				text: i18n.t("status"),
			},
			{
				sort: true,
				hidden: false,
				align: "center",
				headerAlign: "center",
				dataField: "startDate",
				text: i18n.t("startDate"),
			},
			{
				sort: true,
				hidden: false,
				align: "center",
				headerAlign: "center",
				dataField: "endDate",
				text: i18n.t("endDate"),
			},
			{
				sort: true,
				align: "center",
				text: i18n.t("commodity"),
				headerAlign: "center",
				dataField: "commodity",
				formatter: (cellContent, row) => {
					return row.commodity.map((c) => {
						return (
							<div key={c.id} className="mb-2 mr-2 badge badge-dark">
								{c.name}
							</div>
						);
					});
				},
			},
			{
				sort: true,
				hidden: true,
				align: "center",
				dataField: "company",
				headerAlign: "center",
				text: i18n.t("customer"),
			},
			{
				sort: true,
				align: "center",
				dataField: "totalQTY",
				headerAlign: "center",
				text: i18n.t("quantity"),
			},
			{
				sort: true,
				align: "center",
				dataField: "loadedQTY",
				headerAlign: "center",
				text: i18n.t("loadedQTY"),
			},
			{
				sort: true,
				align: "center",
				dataField: "deliveredQTY",
				headerAlign: "center",
				text: i18n.t("deliveredQTY"),
			},
			{
				sort: true,
				align: "center",
				headerAlign: "center",
				dataField: "projectType",
				text: i18n.t("projectType"),
			},
			{
				dataField: "",
				align: "center",
				headerAlign: "center",
				text: i18n.t("actions"),
				formatter: (cell, project) => {
					return (
						<>
							<GetPermission perm="ops.view_project">
								<ViewIcon code="perm" target="_blank" to={`/viewproject/?id=${project.id}`} />
							</GetPermission>
							<GetPermission perm="ops.delete_project">
								<div code="perm">
									<DeleteIcon ondelete={() => this.confirmDeleteProject(project)} hidden={!project.deletable} />
								</div>
							</GetPermission>
							{project.status !== "Completed" && !project.plan && (
								<GetPermission perm="ops.add_workorder">
									<AddIcon code="perm" tooltipText={i18n.t("addProjectWO")} onadd={() => this.toggleProjectWOsModal(project)} />
								</GetPermission>
							)}
						</>
					);
				},
			},
		];

		return (
			<Fragment>
				<PageTitleAlt2 heading={i18n.t("projects")} icon="bi bi-kanban icon-gradient bg-happy-fisher" />
				<Row className="mb-3">
					<Col xs="6" sm="6" md="6" lg="6" xl="6">
						<DateRangePicker getdata={this.getProjects} />
					</Col>
					{
						this.state.instances.length > 1 &&
						<Col xs="3">
							<Label>{i18n.t("company")}</Label>
							<Selector
								clearable
								id="instanceFilter"
								size="small"
								isObjectOption
								optionAccessor="name"
								options={this.state.instances}
								onChange={this.filterByInstance}
							/>
						</Col>
					}
				</Row>
				<Row className="my-3">
					<Col xs="12" sm="4" md="4" lg="4" xl="4">
						<FormGroup className="d-flex ml-4">
							<Input type="checkbox" name="completed" onChange={this.handleCompletedProjectFilter} checked={this.state.showCompletedProjects} />
							<Label className="text-uppercase">{i18n.t("showCompletedProjects")}</Label>
						</FormGroup>
					</Col>
				</Row>

				<Row>
					{this.state.selectedProject && (
						<Col xs="12" sm="12" md="12" ld="12" xl="12">
							<NewWOModal toggle={this.toggleProjectWOsModal} isOpen={this.state.projectWOsModal} project={this.state.selectedProject} />
						</Col>
					)}
					{
						this.state.loading ? (
						<Col xs="12" className="d-flex justify-content-center align-items-center">
							<div style={{ textAlign: "center" }}>
								<Spinner style={{ width: "3rem", height: "3rem" }} color="black" />
								<p style={{ display: "block", marginTop: "1rem" }}>Loading...</p>
							</div>
						</Col>
						) : (
						<Col xs="12" sm="12" md="12" lg="12" xl="12">
							<Card className="main-card mb-3" key="0">
								<CardBody>
									<TableWithPaginationSearch
										scrollable
										exportable
										search
										columns={columns}
										data={this.state.data}
										filename="projects.csv"
										rowEvents={this.rowEvents}
										loading={this.state.loading}
									/>
								</CardBody>
							</Card>
						</Col>
					)}
				</Row>
			</Fragment>
		);
	}
}

export default withRouter(ViewProjectList);
