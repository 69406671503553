import i18n from "i18n";
import React from "react";
import { Row, Col } from "reactstrap";
import CardZeroButton from "../../common/card_zero_button";
import TableWithPaginationSearch from "../../common/table_with_pagination_search";
import { PieChart, Pie, Cell } from "recharts";
import { Legend, Tooltip, ResponsiveContainer } from "recharts";

class DriverStats extends React.Component {
	constructor() {
		super();
		this.state = {
			driverData: [],
			selectedStatus: "",
		};
	}

	componentDidUpdate(prevProps) {
		// Check if the driver_stats prop has changed
		if (prevProps.driver_stats !== this.props.driver_stats) {
			this.setState({ driverData: this.props.driver_stats });
			this.filterBasedOnStatus(""); // Show all drivers by default
		}
	}

	filterBasedOnStatus = (status) => {
		if (status === "") {
			this.setState({ driverData: this.props.driver_stats });
			return;
		}
		const filteredData = this.props.driver_stats.filter(
			(driver) => driver.driverStatus === status
		);
		this.setState({ driverData: filteredData });
	}

	onSelectFilter = (event) => {
		const filterIndex = event.target.getAttribute("index");
		const selectedStatus = this.getFiltersOptions()[filterIndex].status;
		this.setState({ selectedStatus });
		this.filterBasedOnStatus(selectedStatus);
	};

	getFiltersOptions = () => {
		// Move translation logic here so it gets called during each render
		return [
			{ id: 1, name: i18n.t("drivers"), status: "" },
			{ id: 2, name: i18n.t("available"), status: "Available" },
			{ id: 3, name: i18n.t("unavailable"), status: "Unavailable" },
			{ id: 4, name: i18n.t("dispatched"), status: "Dispatched" },
			{ id: 5, name: i18n.t("hold"), status: "Hold" },
		];
	};

	render() {
		const COLORS = ['#8884d8', '#82ca9d', '#ffc658', '#d0ed57'];
		let t = i18n.t;
		let columns = [
			{
				sort: true,
				align: "center",
				headerAlign: "center",
				text: t("driverName"),
				dataField: "profile__user__first_name",
			},
			{
				sort: true,
				align: "center",
				text: t("status"),
				headerAlign: "center",
				dataField: "driverStatus",
			}
		];

		// Prepare data for the Pie Chart
		const pieData = [
			this.props.driverStatus[0] ? { name: this.props.driverStatus[0].driverStatus, value: this.props.driverStatus[0].count } : null,
			this.props.driverStatus[1] ? { name: this.props.driverStatus[1].driverStatus, value: this.props.driverStatus[1].count } : null,
			this.props.driverStatus[2] ? { name: this.props.driverStatus[2].driverStatus, value: this.props.driverStatus[2].count } : null,
			this.props.driverStatus[3] ? { name: this.props.driverStatus[3].driverStatus, value: this.props.driverStatus[3].count } : null,
		].filter(data => data !== null); // Filter out null entries

		const filtersOptions = this.getFiltersOptions(); // Get translated options

		return (
			<Row>
				<Col xs="12" sm="12" md="12" lg="12" xl="12">
					<CardZeroButton title={i18n.t("driverStatusPercentage")}>
						<ResponsiveContainer width="100%" height={400}>
							<PieChart>
								<Pie
									data={pieData}
									cx="50%"
									cy="50%"
									labelLine={false}
									outerRadius={150}
									fill="#8884d8"
									dataKey="value"
									nameKey="name"
								>
									{
										pieData.map((entry, index) => (
											<Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
										))
									}
								</Pie>
								<Tooltip />
								<Legend />
							</PieChart>
						</ResponsiveContainer>
					</CardZeroButton>
				</Col>
				<Col xs="12" sm="12" md="12" lg="12" xl="12" hidden={this.props.driver_stats.length > 0 ? false : true}>
					<CardZeroButton title={t("driversList")}>
						<TableWithPaginationSearch
							scrollable
							exportable
							keyfield="id"
							columns={columns}
							data={this.state.driverData}
							onSelectFilter={this.onSelectFilter}
							filtersOptions={filtersOptions} // Use translated options here
							filterTitle={filtersOptions[0].name}
						/>
					</CardZeroButton>
				</Col>
			</Row>
		);
	}
}

export default DriverStats;
