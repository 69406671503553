import i18n from "i18n";
import axios from "axios";
import moment from "moment";
import Loader from "react-loaders";
import TripForm from "./trip_form";
import Selector from "ops/common/selector";
import { withRouter } from "react-router-dom";
import React, { Component, Fragment } from "react";
import LoadingOverlay from "react-loading-overlay";
import PageTitleAlt2 from "ops/common/PageTitleAlt2";
import GetPermission from "ops/common/get_permission";
import { AlertNotification } from "ops/common/alert_notification";
import { Button, Card, CardBody, Col, Container, Form, FormGroup, Input, Label, Row } from "reactstrap";

class NewNonPlannedWO extends Component {
	constructor(props) {
		super(props);
		this.state = {
			legs: [],
			plans: [],
			trips: [],
			routes: [],
			assets: [],
			instances: [],
			plan: null,
			route: null,
			drivers: [],
			edit: false,
			projects: [],
			project: null,
			companies: [],
			plansRoutes: {},
			assisstants: [],
			projectCode: null,
			attachedAssets: [],
			formLoading: false,
			disableAsset: false,
			testDriveRequestID: null,
			disableAttachedAsset: false,
			newTripEndKMEditIndex: null,
			projectsCompatibleAssets: {},
			newTripStartKMEditIndex: null,
			isChecked: [false, false, true],
			selectedAsset: { id: 0, code: "" },
			selectedAttachedAsset: { id: 0, code: "" },
			serviceType: [{ id: -1, name: i18n.t("none") }],
			wo: { startDate: moment().format("YYYY-MM-DDTHH:mm"), startKM: "" },
			instance:null,
		};
	}

	getDrivers = () => {
		axios
			.get(`profile/?serializer=driver&user__is_active=true&license__driverStatus=Available&departments__name__in=${["Drivers"]}`)
			.then((res) => {
				this.setState({ drivers: res.data });
			});
	};

	getDriverAssisstants = () => {
		axios
			.get(
				`profile/?serializer=driver&user__is_active=true&license__driverStatus=Available&departments__name__in=${[
					"Driver Assistants",
				]}`
			)
			.then((res) => {
				this.setState({ assisstants: res.data });
			});
	};

	getSelectedDefaults = () => {
		let asset1 = null,
			asset2 = null,
			asset1ID = this.props.history.location.asset1,
			asset2ID = this.props.history.location.asset2,
			selectedAsset = this.state.selectedAsset,
			selectedAttachedAsset = this.state.selectedAttachedAsset,
			wo = this.state.wo,
			disableAsset = this.state.disableAsset,
			disableAttachedAsset = this.state.disableAttachedAsset,
			assets = [...this.state.assets],
			attachedAssets = [...this.state.attachedAssets];
		if (asset1ID) asset1 = assets.filter((asset) => Number(asset.id) === Number(asset1ID))[0];
		if (asset2ID) asset2 = attachedAssets.filter((asset) => Number(asset.id) === Number(asset2ID))[0];
		if (asset1) {
			selectedAsset = asset1;
			disableAsset = true;
			wo["asset"] = asset1.id;
			wo["startKM"] = asset1.lastGaugeReading;
		}
		if (asset2) {
			selectedAttachedAsset = asset2;
			disableAttachedAsset = true;
			wo["attachedAsset"] = asset2.id;
		}

		this.setState({
			wo: wo,
			selectedAsset: selectedAsset,
			selectedAttachedAsset: selectedAttachedAsset,
			disableAsset: disableAsset,
			disableAttachedAsset: disableAttachedAsset,
		});
	};

	getAssets = async (commodityTypes = []) => {
		let response;
		await axios.get(`asset/getAvailableAssets/?commodityTypes=${commodityTypes}`).then((res) => {
			if (commodityTypes.length === 0)
				this.setState({ assets: res.data["assets"], attachedAssets: res.data["attachedAssets"] }, () => {
					this.getSelectedDefaults();
				});
			else response = res.data;
		});
		return response;
	};

	getServiceType = () => {
		axios.get(`servicetype`).then((res) => {
			this.setState({ serviceType: this.state.serviceType.concat(res.data) });
		});
	};

	getInstances = () => {
		axios.get(`profile/userinstances`).then((res) => {
			this.setState({instances: res.data});
		})
	}

	getWOInitialData = () => {
		this.getAssets();
		this.getDrivers();
		this.getServiceType();
		this.getInstances();
		this.getDriverAssisstants();
		this.setState({ testDriveRequestID: this.props.history.location.tdrID });
	};

	getLegs = () => {
		axios.get(`leg`).then((res) => {
			this.setState({ legs: res.data });
		});
	};

	submitWO = (e) => {
		const t = i18n.t;
		e.preventDefault();
		let workOrder = { ...this.state.wo };
		if (this.state.isChecked[0]) {
			if (this.state.plan && !this.state.route)
				return AlertNotification(t("makeSureYouHaveSelectedARouteOrSelectAgain"), "error");
			else if (!this.state.plan) return AlertNotification(t("selectPlan"), "error");
			workOrder["serviceType"] = null;
			workOrder["plan"] = this.state.plan;
			workOrder["route"] = this.state.route;
		}
		if (this.state.isChecked[1]) {
			if (!this.state.project) return AlertNotification(t("selectProject"), "error");
			workOrder["project"] = this.state.project;
			workOrder["instance"] = this.state.instance.id;
		}
		workOrder["trips"] = this.state.trips;
		workOrder["testDriveRequest"] = this.state.testDriveRequestID;
		if (workOrder.serviceType === -1) workOrder.serviceType = null;

		/////////////////////////////////////////////////////////////////////////
		// This code related to trip sequance validation which commented untill implenting settings feature
		// for (let trip of this.state.trips) {
		// 	if (trip.invalidLeg) {
		// 		return;
		// 	}
		// }
		/////////////////////////////////////////////////////////////////////////

		let workOrderList = [];
		workOrderList.push(workOrder);
		this.setState({ formLoading: true });
		axios
			.post(`workorder/`, workOrderList)
			.then((res) => {
				this.setState({ formLoading: false });
				if (res) {
					if (this.state.edit)
						this.props.history.push({ pathname: `/viewworkorder/?id=${res.data[0].id}`, workOrderID: res.data[0].id });
				}
			})
			.catch((err) => {
				this.setState({ formLoading: false });
				if (err.response) {
					AlertNotification(null, "error", err.response.status);
					console.error(err.response);
				} else AlertNotification(null, "error");
			});
	};

	getValues = (e, v) => {
		let target;
		let value;
		if (v) {
			target = e.target.id.split("-")[0];
			value = v.id;
			if (target === "driver" || target === "assisstant") value = v.user;
		} else {
			target = e.target.id;
			value = e.target.value;
		}
		this.setState(
			{
				wo: {
					...this.state.wo,
					[target]: value,
					[`invalid${target.charAt(0).toUpperCase()}${target.slice(1, target.length)}`]: false,
				},
			},
			() => {
				if (target === "asset") {
					this.setState({ selectedAsset: v, wo: { ...this.state.wo, startKM: v.lastGaugeReading } });
				}
				if (target === "attachedAsset") {
					this.setState({ selectedAttachedAsset: v });
				}

				/////////////////////////////////////////////////////////////////////////////////////////
				// Work order start KM validation commented until required
				// if (target === "startKM" && this.state.selectedAsset && value < this.state.selectedAsset.lastGaugeReading) {
				// 	this.setState({
				// 		wo: {
				// 			...this.state.wo,
				// 			[`invalid${target.charAt(0).toUpperCase()}${target.slice(1, target.length)}`]: true,
				// 		},
				// 	});
				// }
			}
		);
	};

	calculateKM = (tripsState) => {
		this.setState({ formLoading: true });
		let trips = [...this.state.trips];
		trips.forEach((trip, index) => {
			if (index === 0) {
				trip.startKM = Number(this.state.wo.startKM).toFixed(2);
			} else {
				trip.startKM = Number(trips[index - 1].endKM).toFixed(2);
			}
			trip.endKM = (Number(trip.startKM) + Number(trip.tripDistance)).toFixed(2);
		});

		this.setState({
			[tripsState]: trips,
			formLoading: false,
		});
	};

	getCompanies = () => {
		axios
			.get("company/")
			.then((res) => {
				if (res) this.setState({ companies: res.data });
			})
			.catch((err) => {
				if (err) {
					AlertNotification(null, "error", err.response.status);
					console.error(err.response);
				}
			});
	};

	addTrip = () => {
		if (this.state.legs.length === 0) {
			this.getLegs();
		}
		if (this.state.companies.length === 0) {
			this.getCompanies();
		}
		let startKM = 0;
		if (this.state.trips.length === 0) startKM = this.state.wo.startKM;
		this.setState({
			trips: [
				...this.state.trips,
				{
					startKM: startKM,
					endKM: 0,
					loadedQTY: 0,
					deliveredQTY: 0,
					loadingDate: moment().format("YYYY-MM-DDTHH:mm"),
					unLoadingDate: moment().format("YYYY-MM-DDTHH:mm"),
				},
			],
		});
	};

	getTripValue = (e, v, index) => {
		let target;
		let value;
		let tripIndex;
		let updatedValidation = { ...this.state.validation };
		let trips = this.state.trips;
		if (v) {
			target = e.target.id.split("-")[0];
			tripIndex = index;
			value = v.id;
			trips[tripIndex][`${target}Object`] = v;
		} else {
			target = e.target.id;
			tripIndex = e.target.getAttribute("index");
			value = e.target.value;
		}
		trips[tripIndex][target] = value;
		trips[tripIndex][`invalid${target.charAt(0).toUpperCase()}${target.slice(1, target.length)}`] = false;

		/////////////////////////////////////////////////////////////////////////
		// This code related to trip sequance validation which commented untill implenting settings feature
		// if (target === "leg" && tripIndex !== 0) {
		// 	let prevTrip = trips[tripIndex - 1];
		// 	if (prevTrip.legObject.destination.toLowerCase() !== trips[tripIndex].legObject.source.toLowerCase()) {
		// 		trips[tripIndex][`invalid${target.charAt(0).toUpperCase()}${target.slice(1, target.length)}`] = true;
		// 	}
		// }
		/////////////////////////////////////////////////////////////////////////

		this.setState({
			...this.state,
			trips: trips,
			validation: updatedValidation,
			newTripStartKMEditIndex: target === "startKM" ? tripIndex : this.state.newTripStartKMEditIndex,
			newTripEndKMEditIndex: target === "endKM" ? tripIndex : this.state.newTripEndKMEditIndex,
		});
	};

	removeTrip = (e) => {
		let tripIndex = e.target.getAttribute("index");
		let trips = this.state.trips;
		trips.splice(tripIndex, 1);
		this.setState({ trips: trips });
	};

	validate = (e) => {
		let target = e.target.id.split("-")[0];
		if (target === "leg" || target === "owner") {
			let tripIndex = e.target.name;
			let trips = this.state.trips;
			trips[tripIndex][`invalid${target.charAt(0).toUpperCase()}${target.slice(1, target.length)}`] = true;
			this.setState({ trips: trips });
		} else {
			this.setState({
				wo: {
					...this.state.wo,
					[`invalid${target.charAt(0).toUpperCase()}${target.slice(1, target.length)}`]: true,
				},
			});
		}
	};

	getRadioInput = (e) => {
		let isChecked = [...this.state.isChecked];
		if (e.target.id === "plan") {
			isChecked[0] = true;
			isChecked[1] = false;
			isChecked[2] = false;
			this.getPlans();
		} else if (e.target.id === "project") {
			isChecked[0] = false;
			isChecked[1] = true;
			isChecked[2] = false;
			this.getProjects();
		} else if (e.target.id === "wo") {
			isChecked[0] = false;
			isChecked[1] = false;
			isChecked[2] = true;
		}
		this.setState({ isChecked: isChecked });
	};

	getProjects = () => {
		let url = "";
		if (this.state.instances.length === 1) {
			url = `&instance=${this.state.instances[0].id}`;
		}
		if (this.state.projects.length === 0)
			axios.get(`project/?serializer=new_wo&plan__isnull=true&status__in=In Progress,Did Not Start`+url).then((res) => {
				if (res) this.setState({ projects: res.data });
			});
	};

	getProjectValue = (e, v) => {
		if (!this.state.projectsCompatibleAssets[v.code]) this.getProjectCompatibleAssets(v);
		this.setState({ project: v.id, projectCode: v.code, instance: v.instance });
	};

	getProjectCompatibleAssets = async (project) => {
		let commodityTypes = project.commodity.map((commodity) => {
			return commodity.commodityType;
		});
		let projectsCompatibleAssets = { ...this.state.projectsCompatibleAssets };
		let assetsData = await this.getAssets(commodityTypes);
		projectsCompatibleAssets[project.code] = { assets: assetsData["assets"], attachedAssets: assetsData["attachedAssets"] };
		this.setState({ projectsCompatibleAssets: projectsCompatibleAssets });
	};

	getPlans = () => {
		if (this.state.plans.length === 0)
			axios.get(`plan/?serializer=list`).then((res) => {
				if (res) this.setState({ plans: res.data });
			});
	};

	getPlanValue = (e, v) => {
		if (!this.state.plansRoutes[v.id]) {
			this.getPlanRoutes(v.id);
		} else this.setState({ routes: this.state.plansRoutes[v.id] });
		this.setState({ plan: v.id, route: null });
	};

	getPlanRoutes = (planID) => {
		axios.get(`plan/${planID}/?serializer=list_routes`).then((res) => {
			if (res) this.setState({ plansRoutes: { ...this.state.plansRoutes, [planID]: res.data.routes }, routes: res.data.routes });
		});
	};

	getRouteValue = (e, v) => {
		this.setState({ route: v.id });
	};

	componentDidMount() {
		this.getWOInitialData();
	}

	render() {
		const t = i18n.t;
		return (
			<Fragment>
				<PageTitleAlt2 heading={t("newWO")} icon="bi bi-hdd-network icon-gradient bg-happy-fisher" />
				<LoadingOverlay
					tag="div"
					styles={{
						overlay: (base) => ({
							...base,
							background: "#fff",
							opacity: 0.5,
						}),
					}}
					active={this.state.formLoading}
					spinner={<Loader active color="#30b1ff" type="line-spin-fade-loader" />}>
					<Container>
						<FormGroup check inline className="mb-2">
							<Label check className="mr-2">
								<Input id="plan" type="radio" onChange={this.getRadioInput} checked={this.state.isChecked[0]} />
								{t("plan")}
							</Label>
							<Label check className="mr-2">
								<Input id="project" type="radio" onChange={this.getRadioInput} checked={this.state.isChecked[1]} />
								{t("project")}
							</Label>
							<Label check>
								<Input id="wo" type="radio" onChange={this.getRadioInput} checked={this.state.isChecked[2]} />
								{t("workOrder")}
							</Label>
						</FormGroup>
						<Form onInvalid={this.validate} onSubmit={this.submitWO}>
							<Row>
								{this.state.plans.length > 0 && (
									<Col xs="12" sm="12" md="12" lg="12" xl="12" hidden={!this.state.isChecked[0]}>
										<Card className="main-card mb-3" key="0">
											<CardBody>
												<Row className="d-flex justify-content-start">
													<Col xs="12" md="6">
														<Label>{t("plan")}</Label>
														<Selector
															id="plan"
															size="small"
															isObjectOption
															optionAccessor="code"
															options={this.state.plans}
															onChange={this.getPlanValue}
														/>
													</Col>
													<Col xs="12" md="6">
														<Label>{t("route")}</Label>
														<Selector
															id="route"
															size="small"
															isObjectOption
															optionAccessor="name"
															options={this.state.routes}
															onChange={this.getRouteValue}
														/>
													</Col>
												</Row>
											</CardBody>
										</Card>
									</Col>
								)}
								{
									this.state.projects.length > 0 && (
									<Col xs="12" sm="12" md="12" lg="12" xl="12" hidden={!this.state.isChecked[1]}>
										<Card className="main-card mb-2" key="0">
											<CardBody>
												<Row className="d-flex justify-content-start">
													<Col xs="12" xl={ this.state.instances.length === 1 ? "12" : "9"}>
														<Label>{t("project")}</Label>
														<Selector
															id="project"
															size="small"
															isObjectOption
															optionAccessor="code"
															options={this.state.projects}
															onChange={this.getProjectValue}
														/>
													</Col>
													<Col xs="12" xl="3" hidden={( this.state.instances.length === 1)}>
														<Label>{t("company")}</Label>
														<Input
															id="instance"
															bsSize="sm"
															type="text"
															value={this.state.instance?.name || ""}
															readOnly
														/>
													</Col>
												</Row>
											</CardBody>
										</Card>
									</Col>
								)}
								<Col xs="12">
									<Card className="main-card mb-3">
										<CardBody>
											<Row>
												<Col xs="12">
													<Row>
														<Col>
															<FormGroup>
																<Label>{t("startDate")}</Label>
																<Input
																	required
																	bsSize="sm"
																	id="startDate"
																	type="datetime-local"
																	onChange={this.getValues}
																	defaultValue={moment().format("YYYY-MM-DDTHH:mm")}
																/>
															</FormGroup>
														</Col>
														<Col>
															<FormGroup>
																<Label>{t("asset")}</Label>
																<Selector
																	required
																	id="asset"
																	size="small"
																	isObjectOption
																	optionAccessor="code"
																	onChange={this.getValues}
																	options={this.state.assets}
																	disabled={this.state.disableAsset}
																	error={this.state.wo["invalidAsset"]}
																	value={this.state.selectedAsset}
																/>
															</FormGroup>
														</Col>
														<Col>
															<FormGroup>
																<Label>{t("attachedAsset")}</Label>
																<Selector
																	size="small"
																	isObjectOption
																	id="attachedAsset"
																	optionAccessor="code"
																	onChange={this.getValues}
																	options={
																		this.state.isChecked[1]
																			? this.state.projectsCompatibleAssets[this.state.projectCode]
																				? this.state.projectsCompatibleAssets[this.state.projectCode].attachedAssets
																				: []
																			: this.state.attachedAssets
																	}
																	value={this.state.selectedAttachedAsset}
																	disabled={this.state.disableAttachedAsset}
																	error={this.state.wo["invalidAttachedAsset"]}
																/>
															</FormGroup>
														</Col>
													</Row>
													<Row>
														<Col>
															<FormGroup>
																<Label>{t("driver")}</Label>
																<Selector
																	required
																	id="driver"
																	size="small"
																	isObjectOption
																	optionAccessor="driverName"
																	options={this.state.drivers}
																	onChange={this.getValues}
																	error={this.state.wo["invalidDriver"]}
																/>
															</FormGroup>
														</Col>
														<Col>
															<FormGroup>
																<Label>{t("assistant")}</Label>
																<Selector
																	id="assisstant"
																	size="small"
																	isObjectOption
																	optionAccessor="driverName"
																	options={this.state.assisstants}
																	error={this.state.wo["invalidAssisstant"]}
																	onChange={this.getValues}
																/>
															</FormGroup>
														</Col>
														<Col hidden={this.state.isChecked[0]}>
															<FormGroup>
																<Label>{t("serviceType")}</Label>
																<Selector
																	required
																	size="small"
																	isObjectOption
																	id="serviceType"
																	optionAccessor="name"
																	onChange={this.getValues}
																	options={this.state.serviceType}
																	error={this.state.wo["invalidServiceType"]}
																/>
															</FormGroup>
														</Col>
														<Col>
															<FormGroup>
																<Label>{t("startKM")}</Label>
																<Input
																	bsSize="sm"
																	id="startKM"
																	type="number"
																	step={0.01}
																	onChange={this.getValues}
																	value={this.state.wo.startKM}
																	// Workorder startKM validation commented until required
																	// min={this.state.selectedAsset ? this.state.selectedAsset.lastGaugeReading : 0}
																	invalid={this.state.wo["invalidStartKM"] ? this.state.wo["invalidStartKM"] : false}
																/>
															</FormGroup>
														</Col>
													</Row>
													<Row>
														{/* Notes Field */}
														<Col 
															xs="12" 
															sm="12" 
															md={this.state.isChecked[1] || this.state.instances.length === 1 ? "12" : "9"} 
															lg={this.state.isChecked[1] || this.state.instances.length === 1 ? "12" : "9"} 
															xl={this.state.isChecked[1] || this.state.instances.length === 1 ? "12" : "9"}
														>
															<FormGroup>
															<Label>{t("notes")}</Label>
															<Input id="note" bsSize="sm" type="text" onChange={this.getValues} />
															</FormGroup>
														</Col>

														{/* Company Selector (conditional) */}
														{!this.state.isChecked[1] && (
															this.state.instances.length > 1 &&
															<Col xs="12" sm="12" md="3" lg="3" xl="3">
															<FormGroup>
																<Label>{t("company")}</Label>
																<Selector
																	required
																	id="instance"
																	size="small"
																	isObjectOption
																	optionAccessor="name"
																	onChange={this.getValues}
																	options={this.state.instances}
																	error={this.state.wo["invalidInstance"]}
																/>
															</FormGroup>
															</Col>
														)}
													</Row>

												</Col>
												<Col className="d-flex justify-content-end">
													<GetPermission perm="ops.add_trip">
														<div code="perm">
															<Button
																color="dark"
																type="button"
																hidden={true} // Not required by the client but left for future use
																className="ml-2"
																onClick={this.addTrip}>
																{t("addTrip")}
															</Button>
														</div>
													</GetPermission>
													<GetPermission perm="ops.add_workorder">
														<div code="perm">
															<Button
																id="edit"
																color="dark"
																className="ml-2"
																type="submit"
																onClick={() => {
																	this.setState({ edit: true });
																}}>
																{t("submitAndEdit")}
															</Button>
															<Button id="new" color="info" className="ml-2" type="submit">
																{t("submitAndNew")}
															</Button>
														</div>
													</GetPermission>
												</Col>
											</Row>
										</CardBody>
									</Card>
								</Col>
							</Row>
							{this.state.trips.length !== 0 && (
								<Row>
									<Col xs="12">
										<Card className="main-card mb-3">
											<CardBody>
												<Col xs="12">
													<div className="dropdown-menu-header m-0">
														<div className="dropdown-menu-header-inner m-0 rounded bg-primary">
															<div className="menu-header-content">
																<h5 className="menu-header-title">{t("trips")}</h5>
															</div>
														</div>
													</div>
													<Row className="mt-3">
														{this.state.trips.map((trip, index) => {
															return (
																<Col lg="6" key={index}>
																	<TripForm
																		trip={trip}
																		index={index}
																		legs={this.state.legs}
																		onDelete={this.removeTrip}
																		getValue={this.getTripValue}
																		companies={this.state.companies}
																		getSelectedValue={this.getTripValue}
																	/>
																</Col>
															);
														})}
													</Row>
												</Col>
												<Col className="d-flex justify-content-end">
													<GetPermission perm="ops.add_trip">
														<div code="perm">
															<Button color="dark" className="ml-2" type="button" onClick={this.addTrip}>
																{t("addTrip")}
															</Button>
														</div>
													</GetPermission>
													<GetPermission perm="ops.add_workorder">
														<div code="perm">
															<Button color="info" className="ml-2" type="submit">
																{t("submit")}
															</Button>
														</div>
													</GetPermission>
												</Col>
											</CardBody>
										</Card>
									</Col>
								</Row>
							)}
						</Form>
					</Container>
				</LoadingOverlay>
			</Fragment>
		);
	}
}

export default withRouter(NewNonPlannedWO);
